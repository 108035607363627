import { IonButton,IonList,IonBadge, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { pin, wifi, wine, warning, walk, call, mail, cloudUpload, camera, location, informationCircle, trashSharp, trash } from 'ionicons/icons';
import './uploadDetails.css';
import nophoto from '../assets/nophoto.png';
import { usePhoto } from '../app/hooks/usePhoto';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
    getApplicantById,
    selectSingleStatus as applicantStatus,
    selectApplicantById,
    updateApplicant,
    updateSingleStatus,
    selectModificationStatus,
    selectSignature,
    selectIntial
} from './applicant/reducerSlices/applicantSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../app/api/client';
import '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { useCamera } from '@ionic/react-hooks/camera';
import { selectLoggedInUser } from './accounts/reducerSlices/accountsSlice';
import EllipsisLoader from '../components/loaders/ellipsisLoader';
import SignatureCanvas from 'react-signature-canvas';
import SignatureContainer from '../components/containers/signatureContainer';
import SignatureInput from '../components/controls/signatureInput';
import { _getLocationText } from '../data/masterData';
import GridLoader from '../components/loaders/gridLoader';
import '../pages/tests/css/trainings.css';
import { TrainingResult, TrainingStatus } from '../app/common/constants';
import { Language } from '../app/common/constants';
import { Offline, Online, Detector } from "react-detect-offline";



const TrainingHome = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id, lang } = props.match.params;

    const user = useSelector(selectLoggedInUser);

    const applicant = useSelector((state) => selectApplicantById(state, id));
    const applicantRequestStatus = useSelector(applicantStatus);
    const applicantModificationRequestStatus = useSelector(selectModificationStatus);
    const signatureData = useSelector(selectSignature);
    const initialData = useSelector(selectIntial);

    const { getPhoto } = useCamera();
    const [language, setLanguage] = useState("");
    const [initial, setInitial] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [locationVal, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const [photoData, setPhotoData] = useState("");
    const [photoURL, setPhotoURL] = useState("");
    const [signature, setSignature] = useState("");
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    useEffect(() => {
        if (applicantRequestStatus === fetchStatus.IDLE) {
            dispatch(getApplicantById({
                client: tenantId,
                serviceType: "TENANTUSER",
                operation: "GETAPPLICANTFORMOBILE",
                payload: {
                    id: id,
                    data: ""
                }
            }));
        }
    }, [applicantRequestStatus, dispatch, id]);

    useEffect(() => {

        if (applicant) {
            console.log(applicant);
            let loc = _getLocationText(applicant.LocationId);
            setFirstName(applicant.FirstName);
            setLastName(applicant.LastName);

            setEmail(applicant.Email);
            setPhoneNumber(applicant.PhoneNumber);
            setLocation(loc);
            setStatus(applicant.Status);
            // setLanguage("English");
            if (applicant.PhotoPath && (typeof applicant.PhotoPath === 'string' || applicant.PhotoPath instanceof String) && applicant.PhotoPath !== "" && applicant.PhotoPath.startsWith("https://bajoapplicantstorage.blob.core.windows.net/")) {
                setPhotoURL(applicant.PhotoPath);
            }
            else {
                setPhotoURL("");
            }
            setInitial(initialData);
            setSignature(signatureData);
        }

    }, [id, applicant, dispatch, initialData, signatureData]);

    useEffect(() => {
        dispatch(updateSingleStatus());
    }, [id, dispatch]);

    let isModelValid = [photoURL, initial, language, signature].every(Boolean);

    const onsetLanguage = (strLang) => {
        setLanguage(strLang);
    }

    const onNextClicked = async (e) => {
        if (isModelValid) {
            try {
                await dispatch(
                    updateApplicant({
                        client: tenantId,
                        serviceType: "TENANTUSER",
                        operation: "POST",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                photo: {
                                    contentType: 'image/jpeg',
                                    photoData: photoData,
                                    length: 0
                                },
                                language: language,
                                initial: {
                                    contentType: 'image/jpeg',
                                    initialData: initial,
                                    length: 0
                                },
                                signature: {
                                    contentType: 'image/jpeg',
                                    signatureData: signature,
                                    length: 0
                                }
                            })
                        }
                    })
                );
                history.push(`/concentAcceptance/${id}`);
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
        }

    };

    const onBackClicked = (e) => {
        history.push(`/scanApplicant`);
    };

    const onStartTestClicked = (e) => {
        history.push(`/trainings/${id}`);
    };

    const onPhotoSelected = (e) => {
        takePhoto();
    };

    const _getBadgeColor = (training) => {
        if (training) {
            if (training.Result === TrainingResult.UNKNOWN) {
                return "medium";
            }
            else if (training.Result === TrainingResult.FAILED) {
                return "danger";
            }
            else if (training.Result === TrainingResult.PASSED) {
                return "success";
            }
            else {
                return "medium";
            }
        }
        return "medium";
    };

    const _getButtonText = (training) => {
        if (training) {
            if (training.Result === TrainingResult.UNKNOWN) {
                return "Attempt";
            }
            else if (training.Result === TrainingResult.FAILED) {
                return "Re-attempt";
            }
            else if (training.Result === TrainingResult.PASSED) {
                return "Pass";
            }
            else {
                return "Attempt";
            }
        }
        return "Attempt";
    };

    const _getButtonColor = (training) => {
        if (training) {
            if (training.Result === TrainingResult.UNKNOWN) {
                return "primary";
            }
            else if (training.Result === TrainingResult.FAILED) {
                return "danger";
            }
            else if (training.Result === TrainingResult.PASSED) {
                return "";
            }
            else {
                return "primary";
            }
        }
        return "primary";
    };

    const takePhoto = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;
        setPhotoData(newPhoto.data);
        setPhotoURL(newPhoto.webviewPath);
    };

    const savePicture = async (photo, fileName) => {
        let base64Data = await base64FromPath(photo.webPath);
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: base64Data
        };
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {applicantRequestStatus === fetchStatus.SUCCEEDED ? `${firstName} ${lastName}` : <EllipsisLoader />}
                                </IonCol>

                                <IonCol className="ion-text-center">
                                  
                                        <IonButton
                                            fill="solid"
                                            color="medium"
                                            onClick={(e) => onBackClicked(e)}>
                                            Back
                                        </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? email : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? phoneNumber : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? locationVal : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? status : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonItem lines="none" className="label-margin-bottom">
                        <IonLabel>Select preferred Language</IonLabel>
                    </IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                {applicantRequestStatus !== fetchStatus.LOADING ? <IonSelect className="language-select" value={language} placeholder="--- Select preferred Language ---"
                                    interface="action-sheet" onIonChange={(e) => onsetLanguage(e.target.value)}>
                                    <IonSelectOption value="English">English</IonSelectOption>
                                    <IonSelectOption value="Spanish">Spanish</IonSelectOption>
                                </IonSelect> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>
        
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                List Of Trainings
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonList>
                                        <IonItem>
                                            <IonLabel>
                                                <h2><strong>Safety Training</strong></h2>
                                                <IonBadge
                                                    className="mt-1"
                                                    color={applicant && applicant.Trainings ? _getBadgeColor(applicant.Trainings.Safety) : "medium"}>
                                                    {applicant && applicant.Trainings && applicant.Trainings.Safety ? applicant.Trainings.Safety.Result : "Not Attempted"}
                                                    {applicant && applicant.Trainings && applicant.Trainings.Safety ? " : " + applicant.Trainings.Safety.Score + " %" : ""}
                                                </IonBadge>
                                            </IonLabel>
                                            <IonButton
                                                fill="solid"
                                                color={applicant && applicant.Trainings ? _getButtonColor(applicant.Trainings.Safety) : "primary"}
                                                disabled={language === "" ? true : false}
                                                onClick={(e) => history.push(`/safetyTraining/${id}/${language}`)}>
                                                {applicant && applicant.Trainings ? _getButtonText(applicant.Trainings.Safety) : "Attempt"}
                                            </IonButton>
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel>
                                                <h2><strong>Sexual Harassment Training - Non Supervisor</strong></h2>
                                                <IonBadge
                                                    className="mt-1"
                                                    color={applicant && applicant.Trainings ? _getBadgeColor(applicant.Trainings.SexualHarassment) : "medium"}>
                                                    {applicant && applicant.Trainings && applicant.Trainings.SexualHarassment ? applicant.Trainings.SexualHarassment.Result : "Not Attempted"}
                                                    {applicant && applicant.Trainings && applicant.Trainings.SexualHarassment ? " : " + applicant.Trainings.SexualHarassment.Score + " %" : ""}
                                                </IonBadge>
                                            </IonLabel>
                                            <IonButton
                                                fill="solid"
                                                color={applicant && applicant.Trainings ? _getButtonColor(applicant.Trainings.SexualHarassment) : "primary"}
                                                disabled={language === "" ? true : false}
                                                onClick={(e) => history.push(`/sexualHarassTraining/${id}/${language}`)}>
                                                {applicant && applicant.Trainings ? _getButtonText(applicant.Trainings.SexualHarassment) : "Attempt"}
                                            </IonButton>
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>
                                                <h2><strong>Sexual Harassment Training - Supervisor</strong></h2>
                                                <IonBadge
                                                    className="mt-1"
                                                    color={applicant && applicant.Trainings ? _getBadgeColor(applicant.Trainings.SexualHarassment) : "medium"}>
                                                    {applicant && applicant.Trainings && applicant.Trainings.SexualHarassment ? applicant.Trainings.SexualHarassment.Result : "Not Attempted"}
                                                    {applicant && applicant.Trainings && applicant.Trainings.SexualHarassment ? " : " + applicant.Trainings.SexualHarassment.Score + " %" : ""}
                                                </IonBadge>
                                            </IonLabel>
                                            <IonButton
                                                fill="solid"
                                                color={applicant && applicant.Trainings ? _getButtonColor(applicant.Trainings.SexualHarassment) : "primary"}
                                                disabled={language === "" ? true : false}
                                                onClick={(e) => history.push(`/SexualHarassTrainingSupervisor/${id}/${language}`)}>
                                                {applicant && applicant.Trainings ? _getButtonText(applicant.Trainings.SexualHarassment) : "Attempt"}
                                            </IonButton>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default TrainingHome;
