export const vaccineAccomodationEN = {
    title: "Vaccine Accomodation",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "This form relates to your request for an accommodation/exemption from Hirebase requirement. COVID-19 is a highly infectious, and serious disease that can lead to hospitalization and sometimes even death. Anyone can get COVID-19 vaccinated. Requiring vaccination against COVID-19 demonstrates our commitment to protect the weakened immune systems, as well as our families. Please refer to Hirebase Vaccination Policy."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Individuals requesting an accommodation must complete this form per the instructions below:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "INSTRUCTIONS:"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "All employees must complete Part 1: Disclosure of Vaccination Status",
                "If you are seeking a medical accommodation, you must complete Part 2 and submit the form to your healthcare provider. The healthcare provider must complete Part 3 and return the form to you.",
                "If you are seeking religious accommodation, you must complete Part 4 and provide supporting documentation as requested.",
                "If you are seeking a personal preference accommodation, you must complete Part 5.",
                "You must then submit the completed request form to the Human Resources Department.",
                "Upon review of the completed form and documentation, you will be notified of the decision regarding your requested accommodation. "
            ]
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PART 1 – Disclosure of Vaccination Status (all employees must complete this section)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Employee Name: ###employeeName###"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Please complete this section to provide vaccination status. This information must and will be kept confidential by the Hirebase. Check only one box in this section:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "I want to be vaccinated. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have not yet received a vaccine for COVID-19 and wish to get information about becoming vaccinated. I understand that I will need to update this form once my vaccination is complete, and until then I will be required to comply with the conditions for employees who are not fully vaccinated. More information about vaccination is available online at:"
        },
        {
            tag: "a",
            class: "text-justify",
            data: "https://www.cdc.gov/vaccines/covid-19/index.html"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "I am in the process of being vaccinated."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have started the process of receiving a vaccine/vaccination series for COVID-19. Until two weeks after the final dose, I understand that I will be required to comply with the conditions for employees who are not fully vaccinated and that I will need to update this form once my vaccination is complete."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "The date when I expect to be fully vaccinated (2 weeks after the final dose) is:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "I am Fully Vaccinated. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have completed my COVID-19 vaccination process (2 weeks after the final dose). I may be required to provide a copy of my vaccination card for record purposes."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "I decline to be vaccinated and am eligible for an exemption. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I do not wish to be vaccinated and I meet one of the three criteria for vaccination exemption. I understand that I will be required to comply with the conditions for employees who are not fully vaccinated unless or until I am fully vaccinated."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PART 2 – Medical Accommodation Request"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Name: ###name###"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Date of Request: ###dateOfRequest###"
            data: "Date of Request:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Position/Job Title: ###jobTitle###"
            data: "Position/Job Title:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Client Site Name: ###clientSiteName###"
            data: "Client Site Name:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Please provide the qualifying medical condition that a medical provider considers a contraindication to the COVID-19 vaccine, consistent with CDC guidance (Use space below and additional sheet(s) as needed)."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "I verify that the above information is complete and accurate, and I understand that any intentional misrepresentation contained in this request may result in disciplinary action. I also understand that my request for an accommodation may not be granted if its not reasonable or if it creates an undue hardship or direct threat to the company."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PART 3 – Healthcare Provider Certification"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Please ensure your healthcare provider completes Part 3 of this form)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Please provide the following information:"
        },
        {
            tag: "p",
            class: "text-justify text-decoration-underline",
            data: "Note to Provider: Answer, fully and completely, all applicable parts. Please attach supporting documentation/medical documentation as appropriate."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Name of Patient: ###nameOfPatient###"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Patient should not be immunized for COVID-19 for the following reason(s): Please be specific as possible including the medical condition that is contraindication for the COVID-19 vaccine consistent with CDC guidance and the duration of the qualifying medical condition."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I certify that the patient has above contraindication and recommend that they not receive the COVID-19 vaccination because of the above contraindication."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Healthcare Provider’s Name (print):"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Doctor Specialty:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Street Address:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "City, State, Zip:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Phone Number:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Healthcare Provider’s Signature:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "For Human Resources Use Only"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Date Received: ###dateReceived###"
            data: "Date Received: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Medical Accommodation Approved: YES     NO "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Signature of Staff processing request: ###signatureOfStaff###"
            data: "Signature of Staff processing request: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Date of Request: ###dateOfRequest###"
            data: "Date of Request: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Position/Job Title: ###jobTitle###"
            data: "Position/Job Title: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Department: ###department###"
            data: "Department: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Conditions of approval (if any)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Reason Denied (if any)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PART 4 – Religious Accommodation Request"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Total Resources International prohibits discrimination based on religion and promotes diversity and inclusion for all as part of our commitment to equal employment opportunities. If your sincerely have religious beliefs or practices that conflicts with the COVID-19 vaccination requirement, please complete the following information, and return it to your designated Hirebase office location."
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Name: ###name###"
            data: "Name: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Date Requested: ###dateRequested###"
            data: "Date Requested: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Position / Job Title: ###jobTitle###"
            data: "Position / Job Title: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Department: ###Department###"
            data: "Department: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Please explain why you are seeking a religious accommodation (use space below and additional sheet(s) as needed). In addition, please provide any documentation of religious exemptions/accommodations that have been provided to you by any other employees in support of your request:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "In some cases, Hirebase will request documentation or other authority of your practice(s) or belief(s). Hirebase may need to discuss the nature of your religious belief(s), practice(s), and accommodation with your spiritual leader (if applicable) or religious scholars to address your request for an accommodation. If requested, can you provide documentation to support the need for an accommodation of your religious practice(s) or belief(s)? For example, you may be asked to provide written materials describing the religious belief or practice or a statement from a religious official who can speak on the belief or practice."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Yes"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "I employee of Hirebase, verify that the above information is complete and accurate, and I understand that any intentional misrepresentation contained in this request may result in disciplinary action. I also understand that my request for an accommodation may not be granted if it’s not reasonable or if it creates an undue hardship or direct threat to the company of Hirebase"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Employee Signature Date"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Date"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "SBS Representative"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "For HR Use Only"
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Date Received: ###dateReceived### "
            data: "Date Received: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Religious Accommodation Approved: YES NO ###religiousAccommodationApproved###"
            data: "Religious Accommodation Approved: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "HR or Payroll Signature: ###hrOrPayrollSignature###"
            data: "HR or Payroll Signature: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Reason for denial: ###reasonForDenial###"
            data: "Reason for denial: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PART 5 – Personal Preference Accommodation"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase, recognizes personal preferences as an accommodation for the COVID-19 vaccination requirement. If you are seeking an exemption due to a personal preference, please fill out the section below."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I am seeking a personal preference accommodation and do not wish to be vaccinated. I understand that I will be required to test weekly and must receive a negative test result."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "I verify that the above information is complete and accurate, and I understand that any intentional misrepresentation contained in this request may result in disciplinary action. I also understand that my request for an accommodation may not be granted if it’s not reasonable or if it creates an undue hardship or direct threat to Hirebase, Company. "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Signature:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Date:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "For Human Resources Use Only"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Date Received: ###dateReceived###"
            data: "Date Received: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold   ",
            // data: "Signature of SBS Representative: ###signatureOfSBSRepresentative###"
            data: "Signature of SBS Representative: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Conditions of approval (If any):"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Reasons Denied (if any):"
        }
    ]
}

export const vaccineAccomodationSP = {
    title: "Alojamiento De Vacunas",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Este formulario se relaciona con su solicitud de adaptación /exención del requisito de Hirebase COVID-19 es una enfermedad altamente infecciosa y grave que puede llevar a la hospitalización y, a veces, incluso a la muerte. Cualquier persona puede vacunarse contra el COVID-19. Requerir la vacunación contra COVID-19 demuestra nuestro compromiso de proteger los sistemas inmunológicos debilitados, así como a nuestras familias. Consulte la Política de vacunación de Hirebase"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Las personas que soliciten una adaptación deben completar este formulario según las instrucciones a continuación:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "INSTRUCCIONES:"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "Todos los empleados deben completar la Parte 1: Divulgación del estado de vacunación",
                "Si está buscando una adaptación médica, debe completar la Parte 2 y enviar el formulario a su proveedor de atención médica. El proveedor de atención médica debe completar la Parte 3 y devolverle el formulario.",
                "Si está buscando alojamiento religioso, debe completar la Parte 4 y proporcionar la documentación de respaldo según lo solicitado. ",
                "Si está buscando una adaptación de preferencia personal,  debe completar la Parte 5.",
                "A continuación, debe enviar el formulario de solicitud completo al Departamento de Recursos Humanos.",
                "Tras la revisión del formulario y la documentación completados, se le notificará la decisión con respecto a su alojamiento solicitado."
            ]
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PARTE 1 – Divulgación del estado de vacunación (todos los empleados deben completar esta sección)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Nombre del empleado: ###employeeName###"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Complete esta sección para proporcionar el estado de vacunación. Esta información debe y será mantenida confidencial por Hirebase.  Marque solo una casilla en esta sección:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "Quiero vacunarme.  "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Todavía no he recibido una vacuna contra el COVID-19 y deseo obtener información sobre cómo vacunarme. Entiendo que tendré que actualizar este formulario una vez que se complete mi vacunación, y hasta entonces se me pedirá que cumpla con las condiciones para los empleados que no están completamente vacunados. Más información sobre la vacunación está disponible en línea en:"
        },
        {
            tag: "a",
            class: "text-justify",
            data: "https://www.cdc.gov/vaccines/covid-19/index.html"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "Estoy en proceso de vacunarme."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "He comenzado el proceso de recibir una serie de vacunas / vacunas para COVID-19. Hasta dos semanas después de la dosis final, entiendo que se me pedirá que cumpla con las condiciones para los empleados que no estén completamente vacunados y que tendré que actualizar este formulario una vez que se complete mi vacunación."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "La fecha en que espero estar completamente vacunado (2 semanas después de la dosis final) es: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "Estoy completamente vacunado. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "He completado mi proceso de vacunación contra el COVID-19 (2 semanas después de la dosis final). Es posible que se me solicite que proporcione una copia de mi tarjeta de vacunación para fines de registro. "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold text-decoration-underline",
            data: "Me niego a vacunarme y soy elegible para una exención. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No deseo vacunarme y    cumplir con uno de los tres  criterios para la exención de vacunación. Entiendo que se me exigirá que cumpla con las condiciones para los empleados que no estén completamente vacunados a menos que o hasta que esté completamente vacunado."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PARTE 2 – Solicitud de alojamiento médico"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Nombre: ###name###"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Fecha de solicitud: ###dateOfRequest###"
            data: "Fecha de solicitud: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Cargo/Titulo: ###jobTitle###"
            data: "Cargo/Titulo: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Nombre del sitio del cliente: ###clientSiteName###"
            data: "Nombre del sitio del cliente: "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Proporcione la condición médica calificada que un proveedor médico considera una contraindicación para la vacuna COVID-19, de acuerdo con la guía de los CDC (use el espacio a continuación y las hojas adicionales según sea necesario)."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Verifico que la información anterior sea completa y precisa, y entiendo que cualquier tergiversación intencional contenida en esta solicitud puede resultar en una acción disciplinaria. También entiendo que mi solicitud de adaptación puede no ser concedida si no es razonable o si crea una dificultad excesiva o una amenaza directa para la empresa."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PARTE 3 – Certificación de proveedor de atención "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "médica (asegúrese de que su proveedor de atención médica complete la Parte 3 de este formulario)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Por favor de proporcionar la siguiente información:"
        },
        {
            tag: "p",
            class: "text-justify text-decoration-underline",
            data: "Nota para el proveedor: Responda, total y completamente, todas las partes aplicables. Por favor, adjunte documentación de respaldo/documentación médica según corresponda. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del paciente: ###nameOfPatient###"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "El paciente no debe ser inmunizado contra COVID-19 por las siguientes razones: Sea lo más específico posible, incluida la afección médica que es contraindicación para la vacuna COVID-19 de acuerdo con la guía de los CDC y la duración de la afección médica que califica."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Certifico que el paciente tiene una contraindicación anterior y recomiendo que no reciba la vacuna COVID-19 debido a la contraindicación anterior."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del proveedor de atención médica (imprimir):"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Especialidad Doctora:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dirección:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Ciudad, Estado, Zip:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Número de teléfono:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del proveedor de atención médica:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Solo para uso de recursos humanos"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Fecha de recepción: : ###dateReceived###"
            data: "Fecha de recepción: : "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Alojamiento médico aprobado: SÍ     NO"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Firma de la solicitud de tramitación del personal: ###signatureOfStaff###"
            data: "Firma de la solicitud de tramitación del personal: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Fecha de solicitud: ###dateOfRequest###"
            data: "Fecha de solicitud: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Cargo/Cargo: ###jobTitle###"
            data: "Cargo/Cargo: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Departamento: ###department###"
            data: "Departamento: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Condiciones de aprobación (si las hubiera)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Motivo denegado (si lo hubiera)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PARTE 4 – Solicitud de acomodación religiosa"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Total Resources International prohíbe la discriminación basada en la religión y promueve la diversidad y la inclusión para todos como parte de nuestro compromiso con la igualdad de oportunidades de empleo. Si sinceramente tiene creencias o prácticas religiosas que entran en conflicto con el requisito de vacunación contra el COVID-19, complete la siguiente información y devuélvela a la ubicación designada de la oficina de Hirebase. "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Nombre: ###name###"
            data: "Nombre: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Fecha solicitada: ###dateRequested###"
            data: "Fecha solicitada: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Cargo / Cargo: ###jobTitle###"
            data: "Cargo / Cargo: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Departamento: ###Department###"
            data: "Departamento: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Por favor, explique por qué está buscando una adaptación religiosa (use el espacio a continuación y las hojas adicionales según sea necesario). Además, proporcione cualquier documentación de exenciones / adaptaciones religiosas que le haya proporcionado cualquier otro empleado en apoyo de su solicitud:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "En algunos casos, Hirebase solicitará documentación u otra autoridad de su(s) práctica(s) ocreencia(s). Es posible que Hirebase deba discutir la naturaleza de su(s) creencia(s) religiosa(s), práctica(s) y adaptación con su líder espiritual (si corresponde) o eruditos religiosos para abordar su solicitud de adaptación. Si se solicita, ¿puede proporcionar documentación para respaldar la necesidad de una adaptación de su(s) práctica(s) religiosa(s) o creencia(s)? Por ejemplo, se le puede pedir que proporcione materiales escritos que describan la creencia o práctica religiosa o una declaración de un funcionario religioso que pueda hablar sobre la creencia o práctica. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Sí"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Yo empleado de Hirebase, verifico que la información anterior sea completa y precisa, y entiendo que cualquier tergiversación intencional contenida en esta solicitud puede resultar en una acción disciplinaria. También entiendo que mi solicitud de adaptación puede no ser concedida si no es razonable o si crea una dificultad excesiva o una amenaza directa a la compañía de Hirebase"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Firma del Empleado Fecha"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Firma del SBS Representante"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Solo para uso de recursos humanos "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Fecha de recepción: ###dateReceived### "
            data: "Fecha de recepción: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Alojamiento religioso aprobado: Sí No ###religiousAccommodationApproved###"
            data: "Alojamiento religioso aprobado: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Firma de RRHH o Nómina: ###hrOrPayrollSignature###"
            data: "Firma de RRHH o Nómina: "
        },
        {
            tag: "p",
            class: "text-justify",
            // data: "Motivo de la denegación: ###reasonForDenial###"
            data: "Motivo de la denegación: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "PARTE 5 – Acomodación de preferencia personal"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase, reconoce las preferencias personales como una adaptación para el requisito de vacunación COVID-19. Si está buscando una exención debido a una preferencia personal, complete la sección a continuación. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Estoy buscando una adaptación de preferencia personal y no deseo vacunarme. Entiendo que se me pedirá que me pruebe semanalmente y debo recibir un resultado negativo de la prueba."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Verifico que la información anterior sea completa y precisa, y entiendo que cualquier tergiversación intencional contenida en esta solicitud puede resultar en una acción disciplinaria. También entiendo que mi solicitud de adaptación puede no ser concedida si no es razonable o si crea una dificultad excesiva o una amenaza directa para Hirebase, Company. "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Firma:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Fecha:"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Solo para uso de recursos humanos"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            // data: "Fecha de recepción: ###dateReceived###"
            data: "Fecha de recepción: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold   ",
            // data: "Firma del Representante de sbs: ###signatureOfSBSRepresentative###"
            data: "Firma del Representante de sbs: "
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Condiciones de aprobación (si las hubiera):"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Razones denegadas (si las hubiera):"
        }
    ]
}