import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import {
  archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp,
  paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, logOutOutline, logOutSharp
} from 'ionicons/icons';
import './Menu.css';

import nophoto from '../assets/nophoto.png'
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectLoggedInUser, selectLogoutStatus } from '../pages/accounts/reducerSlices/accountsSlice';
import { accountService } from '../pages/accounts/services/accountService';
import { fetchStatus } from '../app/api/client';
import { USER_KEY } from '../app/common/constants';
import { useEffect, useState } from 'react';

// interface AppPage {
//   url: string;
//   iosIcon: string;
//   mdIcon: string;
//   title: string;
// }

// const appPages = [
//   {
//     title: 'Logout',
//     url: '/page/Inbox',
//     iosIcon: logOutOutline,
//     mdIcon: logOutSharp
//   }//,
// {
//   title: 'Outbox',
//   url: '/page/Outbox',
//   iosIcon: paperPlaneOutline,
//   mdIcon: paperPlaneSharp
// },
// {
//   title: 'Favorites',
//   url: '/page/Favorites',
//   iosIcon: heartOutline,
//   mdIcon: heartSharp
// },
// {
//   title: 'Archived',
//   url: '/page/Archived',
//   iosIcon: archiveOutline,
//   mdIcon: archiveSharp
// },
// {
//   title: 'Trash',
//   url: '/page/Trash',
//   iosIcon: trashOutline,
//   mdIcon: trashSharp
// },
// {
//   title: 'Spam',
//   url: '/page/Spam',
//   iosIcon: warningOutline,
//   mdIcon: warningSharp
// }
//];

// const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

const Menu = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(selectLoggedInUser);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logoutStatus = useSelector(selectLogoutStatus);

  useEffect(() => {
    setIsAuthenticated(user && user.id ? true : false);
  }, [user]);

  const onLogoutClicked = async (e) => {
    if (accountService.isAuthenticated()) {
      try {
        localStorage.removeItem(USER_KEY);
        //history.replace('/login');
        window.location.reload();
      }
      catch (err) {
        console.error('Failed to log out: ', err)
      }
    }
  };

  return (
    <>{isAuthenticated ?
      <IonMenu contentId="main" type="overlay" >
        <IonContent >
          <IonList id="inbox-list">
            <IonListHeader>
              <div className="person-detail-container">
                <IonImg src={(user ? user.photoPath : nophoto) || nophoto} className="person-photo" />
                <IonText>{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}</IonText>
                <IonNote>{user ? user.email : "na@na.com"}</IonNote>
              </div>

            </IonListHeader>

            <IonMenuToggle autoHide={false}>
              <IonItem
                lines="none" detail={false}
                onClick={(e) => onLogoutClicked(e)}>
                <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
                <IonLabel>Logout</IonLabel>
              </IonItem>
            </IonMenuToggle>

          </IonList>


        </IonContent >
      </IonMenu > : <></>}
    </>
  );
};

export default Menu;
