import { IonButton, IonButtons, IonCard, IonCardContent,IonBadge, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { call, mail, location, informationCircle } from 'ionicons/icons';
import './concentAcceptance.css';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInUser } from './accounts/reducerSlices/accountsSlice';
import {
    selectApplicantById,
    updateApplicant,
    selectSingleStatus as applicantStatus,
    getApplicantById,
    selectModificationStatus,
    selectSignature,
    selectIntial,
    updateModificationStatus
} from './applicant/reducerSlices/applicantSlice';
import { fetchStatus, tenantId } from '../app/api/client';
import { useHistory } from 'react-router';
import EllipsisLoader from '../components/loaders/ellipsisLoader';
import Disclaimer from '../components/concents/disclaimer';
import ACAWaiver from '../components/concents/acaWaiver';
import ProcurementDisclosure from '../components/concents/procurementDisclosure';
import DrugScreening from '../components/concents/drugScreening';
import AtWillEmployment from '../components/concents/atWillEmployment';
import MutualArbitration from '../components/concents/mutualArbitration';
import EmployeeHandbook from '../components/concents/employeeHandbook';
import GeneralSafety from '../components/concents/generalSafety';
import EmployeeSafety from '../components/concents/employeeSafety';
import { Language } from '../app/common/constants';
import { accountService } from './accounts/services/accountService';
import { _getLocationText } from '../data/masterData';
import GridLoader from '../components/loaders/gridLoader';
import PreWorkDisclaimer from '../components/concents/preWorkDisclaimer';
import ClassActionWaiver from '../components/concents/classActionWaiver';
import CompanyPolicies from '../components/concents/companyPolicies';
import NoticeToEmployee from '../components/concents/noticeToEmployee';
import CovidDiagnosis from '../components/concents/covidDiagnosis';
import CovidEmergencyTreatment from '../components/concents/covidEmergencyTreatment';
import { Offline, Online, Detector } from "react-detect-offline";
import VaccinationPolicy from '../components/concents/vaccinationPolicy';
import VaccineAccomodation from '../components/concents/vaccineAccomodation';


const ConcentAcceptance = (props) => {
    const { id, lang } = props.match.params;
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(selectLoggedInUser);
    const applicantModificationRequestStatus = useSelector(selectModificationStatus);
    const applicantRequestStatus = useSelector(applicantStatus);
    const signatureData = useSelector(selectSignature);
    const initialData = useSelector(selectIntial);

    const [showSubmitToast, setShowSubmitToast] = useState(false);

    const applicant = useSelector((state) => selectApplicantById(state, id));

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [ssn, setSSN] = useState("");
    const [dob, setDOB] = useState("");
    const [address, setAddress] = useState("");
    const [locationVal, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const [signature, setSignature] = useState("");
    const [initial, setInitial] = useState("");
    const [language, setLanguage] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [dcSigned, setDCSigned] = useState(false);
    const [acaSigned, setACASigned] = useState(false);
    const [prcSigned, setPRCSigned] = useState(false);
    const [drugSigned, setDrugSigned] = useState(false);
    const [atwSigned, setATWSigned] = useState(false);
    const [muaSigned, setMUASigned] = useState(false);
    const [ehbSigned, setEHBSigned] = useState(false);
    const [gnsSigned, setGNSSigned] = useState(false);
    const [emsSigned, setEMSSigned] = useState(false);
    const [pwdSigned, setPWDSigned] = useState(false);
    const [cawSigned, setCAWSigned] = useState(false);
    const [cmpSigned, setCMPSigned] = useState(false);
    const [nteSigned, setNTESigned] = useState(false);
    const [initialsP, setInitialsP] = useState([]);
    const [initialsSP, setInitialsSP] = useState([]);
    const [initials, setInitials] = useState(false);
    const [cdSigned, setCDSigned] = useState(false);
    const [cetSigned, setCETSigned] = useState(false);
    const [initialsCET, setInitialsCET] = useState([]);
    const [isSavePressed, setIsSavePressed] = useState("");

    const polling = {
        enabled: true,
        interval: 4000,
        timeout: 1000
      };

   // let _isModelValid  = true; 
    let _isModelValid = [dcSigned,
        acaSigned,
        prcSigned,
        drugSigned,
        atwSigned,
        muaSigned,
        ehbSigned,
        gnsSigned,
        emsSigned,
        pwdSigned,
        cawSigned,
        cmpSigned,
        nteSigned,
        cdSigned,
        initials,
        (initialsCET.length === 9),
        (initialsP.length === 18),
        (language === Language.ENGLISH ? (initialsSP.length === 13) : (initialsSP.length === 14))].every(Boolean);

    useEffect(() => {

        if (applicant) {

            let loc = _getLocationText(applicant.LocationId);
            setFirstName(applicant.FirstName);
            setLastName(applicant.LastName);
            setLocation(loc);
            setStatus(applicant.Status);
            setLanguage(lang);
            let address = "";
            setEmail(applicant.Email);
            setPhoneNumber(applicant.PhoneNumber);
            setDOB(applicant.DOB);
            if (applicant.Address) {
                address += applicant.Address;
            }
            if (applicant.City) {
                if (address)
                    address += ", " + applicant.City;
                else
                    address += applicant.City;
            }
            if (applicant.State) {
                if (address)
                    address += ", " + applicant.State;
                else
                    address += applicant.State;
            }
            if (applicant.Pincode) {
                if (address)
                    address += ", " + applicant.Pincode;
                else
                    address += applicant.Pincode;
            }
            setAddress(`${address ? address : "N/A"}`);
            setSSN(applicant.SSN);

            setInitial(initialData);
            setSignature(signatureData);
            if (applicant.reviewerLocation) {
                let _rAddress = applicant.reviewerLocation.address;
                let _rCity = applicant.reviewerLocation.city;
                let _rState = applicant.reviewerLocation.state;
                let _rZipCode = applicant.reviewerLocation.zipcode;
                setCompanyAddress(`${_rAddress}, ${_rCity}, ${_rState}, ${_rZipCode}`)
            }
        }

    }, [applicant, initialData, signatureData, id]);

    const onSubmitClicked = async (e) => {
        const user = accountService.getAuthenticatedUser();
        setShowSubmitToast(true);
          if (_isModelValid) {
            try {
                setIsSavePressed("True");
                await dispatch(
                    updateApplicant(
                        {
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "SAVEAPPLICANTCONCENTFORMOBILE",
                            payload: {
                                id: id,
                                data: JSON.stringify({
                                    reviewerId: user.id,
                                    applicantId: id,
                                    language: language
                                })
                            }
                        })
                );
               
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
         }
         else 
         {
             alert('Please sign all places');
         }

    };

    useEffect(() => {
        if (applicant && isSavePressed === "True") {
            if (applicantModificationRequestStatus == fetchStatus.SUCCEEDED) {
                dispatch(updateModificationStatus());
                setIsSavePressed("");
                history.push(`/vaccinationDetails/${id}/${language}`);
            }
            if (applicantModificationRequestStatus == fetchStatus.FAILED) {
                alert('Data upload failed, Please try again')
            }
        }
    }, [applicantModificationRequestStatus]);

    const onBackClicked = (e) => {
        history.push(`/uploadDetails/${id}`);
    };

    const onToastDismissed = () => {
        setShowSubmitToast(false);
        // dispatch(updateModificationStatus());
        // history.push(`/vaccinationDetails/${id}/${language}`);
       
    };

    const onPoliciesSigned = (v, i) => {
        setInitialsP([...initialsP, i]);
    }

    const onSafetyPoliciesSigned = (v, i) => {
        setInitialsSP([...initialsSP, i]);
    }

    const onCETInitialsSigned = (v, i) => {
        setInitialsCET([...initialsCET, i]);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {applicantModificationRequestStatus === fetchStatus.LOADING ? <div className="process-loader">
                    <GridLoader />
                </div> : <></>}
                <IonCard>
                    <IonCardHeader>

                        <IonRow>
                            <IonCol>
                                <IonCardTitle>{`${firstName} ${lastName}`}</IonCardTitle>
                            </IonCol>
                            <IonCol className="ion-text-center">
                                <IonButton
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{email}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{phoneNumber}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{locationVal}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{status}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
          
                   <Disclaimer
                    language={language}
                    name={firstName + " " + lastName}
                    signed={dcSigned}
                    signature={signature}
                    onSigned={(v) => setDCSigned(v)} />
               
             
                <ACAWaiver
                    language={language}
                    name={firstName + " " + lastName}
                    signed={acaSigned}
                    signature={signature}
                    reviewerSignature={user.signature}
                    onSigned={(v) => setACASigned(v)} />
                <ProcurementDisclosure
                    language={language}
                    name={firstName + " " + lastName}
                    dateOfBirth={dob}
                    email={email}
                    ssn={ssn}
                    address={address}
                    signed={prcSigned}
                    signature={signature}
                    onSigned={(v) => setPRCSigned(v)} />
                <DrugScreening
                    language={language}
                    name={firstName + " " + lastName}
                    signed={drugSigned}
                    signature={signature}
                    onSigned={(v) => setDrugSigned(v)} />
                <AtWillEmployment
                    language={language}
                    name={firstName + " " + lastName}
                    signed={atwSigned}
                    signature={signature}
                    onSigned={(v) => setATWSigned(v)} />
                <MutualArbitration
                    language={language}
                    name={firstName + " " + lastName}
                    signed={muaSigned}
                    signature={signature}
                    reviewerName={user.firstName + " " + user.lastName}
                    reviewerSignature={user.signature}
                    onSigned={(v) => setMUASigned(v)} />
                <EmployeeHandbook
                    language={language}
                    name={firstName + " " + lastName}
                    ssn={ssn}
                    signed={ehbSigned}
                    initialsP={initialsP}
                    initialsSP={initialsSP}
                    initial={initial}
                    signature={signature}
                    reviewerSignature={user.signature}
                    onPoliciesSigned={(v, i) => onPoliciesSigned(v, i)}
                    onSafetyPoliciesSigned={(v, i) => onSafetyPoliciesSigned(v, i)}
                    onSigned={(v) => setEHBSigned(v)} />
                <GeneralSafety
                    language={language}
                    name={firstName + " " + lastName}
                    signed={gnsSigned}
                    signature={signature}
                    onSigned={(v) => setGNSSigned(v)} />
                <EmployeeSafety
                    language={language}
                    name={firstName + " " + lastName}
                    signed={emsSigned}
                    signature={signature}
                    onSigned={(v) => setEMSSigned(v)} />
                <PreWorkDisclaimer
                    language={language}
                    name={firstName + " " + lastName}
                    signed={pwdSigned}
                    signature={signature}
                    reviewerSignature={user.signature}
                    onSigned={(v) => setPWDSigned(v)} />
                <ClassActionWaiver
                    language={language}
                    name={firstName + " " + lastName}
                    signed={cawSigned}
                    signature={signature}
                    initials={initials}
                    initial={initial}
                    onInitialsSigned={(v) => setInitials(v)}
                    onSigned={(v) => setCAWSigned(v)} />
                <CompanyPolicies
                    language={language}
                    name={firstName + " " + lastName}
                    signed={cmpSigned}
                    signature={signature}
                    onSigned={(v) => setCMPSigned(v)} />
              
                <CovidDiagnosis
                    language={language}
                    name={firstName + " " + lastName}
                    signed={cdSigned}
                    signature={signature}
                    onSigned={(v) => setCDSigned(v)} />
                <CovidEmergencyTreatment
                    language={language}
                    name={firstName + " " + lastName}
                    signed={cetSigned}
                    initial={initial}
                    signature={signature}
                    initialsCET={initialsCET}
                    onCETInitialsSigned={(v, i) => onCETInitialsSigned(v, i)}
                    reviewerSignature={user.signature}
                    onSigned={(v) => setCETSigned(v)} />
                      <NoticeToEmployee
                    language={language}
                    name={firstName + " " + lastName}
                    signed={nteSigned}
                    signature={signature}
                    companyAddress={companyAddress}
                    reviewerName={user.firstName + " " + user.lastName}
                    reviewerSignature={user.signature}
                    onSigned={(v) => setNTESigned(v)} />
               
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    disabled={!_isModelValid}
                                    title={!_isModelValid ? "Please accept and sign the concents" : "Click to submit"}
                                    onClick={() => onSubmitClicked()}>
                                    Next
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>

                            {/* <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) =>  history.push(`/vaccinationDetails/${id}/${language}`)}>
                                    Next
                                </IonButton> : <EllipsisLoader />}
                            </IonCol> */}

                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonToast
                    position="middle"
                    header="Saved"
                    color="dark"
                    isOpen={showSubmitToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="Concent Saving, Please wait"
                    duration={1000}
                />
            </IonContent>
        </IonPage >
    );
};

export default ConcentAcceptance;
