import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonBadge,IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchStatus, tenantId } from '../../../app/api/client';
import { USER_KEY } from '../../../app/common/constants';
import SignatureContainer from '../../../components/containers/signatureContainer';
import SignatureInput from '../../../components/controls/signatureInput';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import { selectLoggedInUser } from '../../accounts/reducerSlices/accountsSlice';
import { accountService } from '../../accounts/services/accountService';
import { saveReviewerSignature, selectReviewerSignatureStatus } from '../reducerSlices/reviewerSlice';
import { Offline, Online, Detector } from "react-detect-offline";

const SignatureDetails = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectLoggedInUser);

    const [signature, setSignature] = useState("");

    const reviewerSignatureStatus = useSelector(selectReviewerSignatureStatus)
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    let isModelValid = [signature].every(Boolean);

    useEffect(() => {
        if (reviewerSignatureStatus === fetchStatus.SUCCEEDED) {
            history.push(`/scanApplicant`);
        }
    })

    const onNextClicked = async (e) => {
        if (isModelValid) {
            try {
                await dispatch(
                    saveReviewerSignature({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEREVIEWERSIGNATURE",
                        payload: {
                            id: user.id,
                            data: JSON.stringify({
                                signature: {
                                    contentType: 'image/jpeg',
                                    signatureData: signature,
                                    length: 0
                                }
                            })
                        }
                    })
                );
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
        }
    };

    const onBackClicked = (e) => {
        if (accountService.isAuthenticated()) {
            try {
                localStorage.removeItem(USER_KEY);
                history.push('/login');
            }
            catch (err) {
                console.error('Failed to log out: ', err)
            }
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <SignatureContainer>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonLabel>Signature</IonLabel>
                                </IonItem>
                                <SignatureInput
                                    signature={signature}
                                    onChange={(e, v) => setSignature(v)}
                                    onClear={(v) => setSignature(v)} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </SignatureContainer>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">

                        <IonRow>
                            <IonCol className="ion-text-center">
                                {reviewerSignatureStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    disabled={!isModelValid}
                                    onClick={(e) => onNextClicked(e)}>
                                    Next
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {reviewerSignatureStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default SignatureDetails;