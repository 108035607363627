export const employeeHandBookEN = {
    title: "EMPLOYEE ACKNOWLEDGEMENT OF RECEIPT AND UNDERSTANDING OF EMPLOYEE POLICY HANDBOOK",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "I acknowledge that I have received a copy of the Hirebase Associate “Employee” Policy Handbook, which contains important information on the Company’s policies, procedures, safety, and training. I understand and agree that the policies described in the handbook are intended as a guide only and do not constitute a contract of employment. I specifically understand and agree that the employment  relationship between Hirebase and I, is at-will, temporary and can be terminated by Hirebase or me at any time, with or without cause or notice."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Furthermore, the Company has the right to modify or alter my position or impose any form of discipline it deems appropriate at any time. Nothing in this handbook is intended to modify the Company’s policy of at-will employment. The at-will employment relationship may not be modified except by a specific written agreement signed by me and the Company’s CEO or VP of Operations."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have read and completely understand the contents of Hirebase (SBS Company) employee orientation and safety handbook. I agree to abide by the policies and guidelines contained therein. I understand and acknowledge that:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that it is my responsibility to read it, familiarize myself with the policies and procedures and to comply with the policies and procedures described in the Handbook.  If I have any questions regarding any of the Handbook’s provisions, I will consult with my supervisor, manager or Human Resources.  I understand that I am responsible for complying with the policies and procedures that are applicable to me and if I do not follow the policies and procedures contained herein that I may be subject to disciplinary action, up to and including termination of employment."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I acknowledge that I have been provided with and understand the Company’s California Meal Period and Rest Break Policy. I agree to comply with this Policy."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I also understand that this Handbook is the most up-to-date version of the Company’s policies and procedures and replaces any prior written and oral communications about the subjects contained in it."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I acknowledge that my employment relationship with the Company is “at-will”, meaning that, regardless of anything contained in this Handbook and regardless of any custom or practice, either I or the Company may terminate my employment at any time, for any reason or no reason, with or without cause, and with or without procedural requirements.  I understand that no representatives of the Company, other than the President in a writing signed by him/her, may enter into any agreements, or make any representations, written or oral, to alter my at-will status or otherwise create any contractual obligation between me and the Company.  I further understand that no department head, supervisor or any other employee of Hirebase has the authority to waive, alter or refuse to enforce any provisions or policies set forth in this Handbook.  I acknowledge that Hirebase may modify or rescind any policies, practices, or benefits described in the Employee Handbook, other than the employment at-will policy, at any time, without prior notice to me."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Policies and guidelines may be amended, changed or deleted at any time by SBS. Employment with SBS is at-will, meaning that the employment relationship may be terminated at any time by either party, with or without cause, and without prior notice."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "This is the entire agreement between the Company and me regarding this subject. All prior or contemporaneous inconsistent agreements are superseded. If I do have a written employment agreement signed by an authorized company representative and this Handbook conflicts with the terms, I understand that the terms of my employment agreement will control."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I acknowledge that I have read and understood all policies within my temporary employment with Hirebase also known as SBS. My signature below signifies that I have received general safety training and completed a review of the SBS safety policies and procedures which include but are not limited to:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have specifically reviewed the following policies"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "At Will Employment",
                "Equal Employment Opportunity ",
                "Harassment Policy",
                "Union Free Policy ",
                "Available Policy",
                "Workforce Reduction",
                "Benefits",
                "Family Medical and Leave Act",
                "Sick Leave",
                "Substance Abuse",
                "Workers Compensation Policy",
                "Injury & Incident Reporting Procedures",
                "Benefits",
                "Workforce Reduction",
                "Rest & Meal Breaks",
                "Code of Conduct",
                "Disciplinary Action",
                "General Safety Rules"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have specifically reviewed the following safety policies"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "General Safety Policy",
                "Safety Violations Policy",
                "General Safety Rules - Safety Test",
                "Injury/Illness Reporting Procedures",
                "Bloodborne Pathogens Policy",
                "Chemical Spill Response Policy",
                "Emergency Action Plan",
                "Fire Safety Guidelines",
                "Earthquake Guidelines",
                "Electrical Safety Guidelines",
                "Lockout/Tag out Policy",
                "Slips, Trips and Falls Prevention",
                "Hazard Communication Program"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "You must read and understand all the components of this handbook. Before signing, if there are any areas you do not understand, please have Hirebase branch office staff or Human Resources Director explain them to you. By signing this handbook, I acknowledge that the handbook was explained to me and that I understand the handbook."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Name (Print)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Last 4 of Social Security #"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "employee initials"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "SBS Representative Signature"
        }
    ]
}


export const employeeHandBookSP = {
    title: "RECONOCIMIENTO DEL RECIBO DEL  EMPLEADO  Y COMPRENSIÓN DEL MANUAL DE POLÍTICAS DE LOS EMPLEADOS",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Reconozco que he recibido una copia del Manual de Políticas del 'Empleado' Asociado de Hirebase, que contiene información importante sobre las políticas, procedimientos, seguridad y capacitación de la Compañía. Entiendo y acepto que las políticas descritas en el manual se destinen únicamente a una guía y no constituyen un contrato de trabajo. Entiendo y acepto específicamente que la relación laboral entre Hirebase y yo es a voluntad y puede ser cancelada por la Compañía o por mí en cualquier momento, con o sin causa o aviso."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Además, la Compañía tiene el derecho de modificar o alterar mi posición o imponer cualquier forma de disciplina que considere apropiada en cualquier momento. Nada en este manual tiene por objeto modificar la política de empleo a voluntad de la Compañía. La relación laboral a voluntad no podrá ser modificada excepto por un acuerdo escrito específico firmado por mí y el CEO o vicepresidente de la Compañía.   "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "He leído y comprendo completamente el contenido del manual de orientación y seguridad para empleados de Hirebase (SBS Company). Estoy de acuerdo en cumplir con las políticas y pautas aquí contenidas. Entiendo y reconozco que:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que es mi responsabilidad leerlo, familiarizarme con las políticas y procedimientos y cumplir con las políticas y procedimientos descritos en el Manual. Si tengo alguna pregunta con respecto a alguna de las disposiciones del Manual, consultaré con mi supervisor, gerente o Recursos Humanos. Entiendo que soy responsable de cumplir con las políticas y procedimientos que me son aplicables y que si no sigo las políticas y procedimientos contenidos en este documento, puedo estar sujeto a medidas disciplinarias, hasta e incluyendo el despido."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Reconozco que se me ha proporcionado y comprendo la Política de Período de Comida y Descanso de California de la Compañía y entiendo que entra en vigencia de inmediato. Acepto cumplir con esta Política."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "También entiendo que este Manual es la versión más actualizada de las políticas y procedimientos de la Compañía y reemplaza cualquier comunicación escrita u oral anterior sobre los temas contenidos en él."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Reconozco que mi relación laboral con la Compañía es 'a voluntad', lo que significa que, independientemente de todo lo contenido en este Manual e independientemente de cualquier costumbre o práctica, yo o la Compañía podemos terminar mi empleo en cualquier momento, por cualquier motivo. o sin motivo, con o sin causa, y con o sin requisitos procesales. Entiendo que ningún representante de la Compañía, que no sea el Presidente en un escrito firmado por él / ella, puede celebrar ningún acuerdo o hacer declaraciones, escritas u orales, para alterar mi estado a voluntad o crear una obligación contractual. entre la Compañía y yo. Además, entiendo que ningún jefe de departamento, supervisor o cualquier otro empleado de Hirebase tiene la autoridad para renunciar, alterar o negarse a hacer cumplir las disposiciones o políticas establecidas en este Manual. Reconozco que Hirebase puede modificar o rescindir cualquier política, práctica o beneficio descrito en el Manual del empleado, que no sea la política de empleo a voluntad, en cualquier momento y sin previo aviso."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Las políticas y pautas pueden ser modificadas, cambiadas o eliminadas en cualquier momento por SBS. El empleo con SBS es a voluntad, lo que significa que cualquiera de las partes puede rescindir la relación laboral en cualquier momento, con o sin causa, y sin previo aviso."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Este es el acuerdo completo entre la Compañía y yo con respecto a este tema. Todos los acuerdos inconsistentes anteriores o contemporáneos son reemplazados. Si tengo un contrato de trabajo por escrito firmado por un representante autorizado de la compañía y este Manual entra en conflicto con los términos, entiendo que prevalecerán los términos de mi contrato de trabajo."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Reconozco que he leído y entendido todas las políticas dentro de mi empleo temporal con Hirebase también conocido como SBS. Mi firma a continuación significa que he recibido capacitación en seguridad general y que he completado una revisión de las políticas y procedimientos de seguridad de SBS que incluyen, entre otros:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "He revisado específicamente las siguientes políticas"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "Empleo a voluntad",
                "Igualdad de oportunidades de empleo ",
                "política de acoso",
                "Unión política gratuito ",
                "Disponible política",
                "Reducción de la mano de obra",
                "Beneficios",
                "Ley de Ausencia Familiar y Médica y",
                "Se va enfermo",
                "Abuso de sustancias",
                "Trabajadores política de Compensación",
                "Procedimientos Lesiones y notificación de incidentes",
                "Beneficios",
                "reducción de plantilla",
                "para el descanso y pausas para el almuerzo",
                "Código de Conducta",
                "Acción disciplinaria",
                "Reglas generales de seguridad"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "He revisado específicamente las siguientes políticas de seguridad"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "Política general de seguridad",
                "Política de violaciones de seguridad",
                "Reglas generales de seguridad - Prueba de seguridad",
                "Procedimientos de notificación de lesiones / enfermedades",
                "Política de patógenos transmitidos por la sangre Política de ",
                "respuesta a derrames químicos y",
                "plan de acción de emergencia",
                "Directrices de seguridad contra incendios",
                "Directrices del terremoto",
                "directrices de seguridad eléctrica",
                "de bloqueo / etiquetado de seguridad Política de",
                "Los resbalones, tropezones y Prevención de Caídas",
                "Programa de Comunicación de Riesgos	-Derecho a saber, Química Etiquetado,",
                "Comunicación de Riesgos del concurso"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Debe leer y comprender todos los componentes de este manual. Antes de firmar, si hay alguna área que no entiende, por favor pida a Hirebase que el personal de la sucursal de Hirebase o el Director de Recursos Humanos se los explique. Al firmar este manual, reconozco que el manual me fue explicado y que entiendo el manual."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del empleado (Letra de imprenta)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Últimos 4 números de Seguro Social"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "iniciales del empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del representante de SBS"
        }
    ]
}