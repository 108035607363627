import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { accountService } from '../../pages/accounts/services/accountService';

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                accountService.isAuthenticated() ?
                    (<Component {...props} />)
                    : (<Redirect to={{ pathname: '/login', state: { returnUrl: props.location } }} />)}
        />
    );
}

export default PrivateRoute;