import { configureStore } from '@reduxjs/toolkit';

import applicantsReducer from '../pages/applicant/reducerSlices/applicantSlice';

import accountsReducer from '../pages/accounts/reducerSlices/accountsSlice';
import reviewerReducer from '../pages/reviewer/reducerSlices/reviewerSlice';
import safetyTestReducer from '../pages/tests/reducerSlices/safetyTestSlice';

export default configureStore({
    reducer: {
        applicants: applicantsReducer,
        reviewer: reviewerReducer,
        accounts: accountsReducer,
        safetyTest: safetyTestReducer
    },
});