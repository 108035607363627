import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../app/api/client';
import { USER_KEY } from '../../../app/common/constants';

const initialState = {


    login: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    logout: {
        status: fetchStatus.IDLE,
        error: null
    },
    version: {
        status: fetchStatus.IDLE,
        error: null,
        data: ""
    }

}





export const login = createAsyncThunk('accounts/login', async (loginModel) => {
    const response = await BajoAPI.post('Identity/login', loginModel);
    return response.data;
});

export const logout = createAsyncThunk('accounts/logout', async (logoutModel) => {
    const response = await BajoAPI.post('Account/logout', logoutModel);
    return response.data;
});


export const GetVersion = createAsyncThunk('accounts/GetVersion', async (loginModel) => {
    const response = await BajoAPI.get('Identity/GetVersion');
    return response.data;
});


export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        updateLoginStatus: (state) => {
            state.login.status = fetchStatus.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.login.status = fetchStatus.LOADING;
        }).addCase(login.fulfilled, (state, action) => {
            state.login.user = action.payload;
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
            state.login.status = fetchStatus.SUCCEEDED;
        }).addCase(login.rejected, (state, action) => {
            state.login.status = fetchStatus.FAILED;
            state.login.error = action.error.message;
        }).addCase(logout.pending, (state, action) => {
            state.logout.status = fetchStatus.LOADING;
        }).addCase(logout.fulfilled, (state, action) => {
            state.login.user = undefined;
            state.logout.status = fetchStatus.SUCCEEDED;
        }).addCase(logout.rejected, (state, action) => {
            state.logout.status = fetchStatus.FAILED;
            state.logout.error = action.error.message;
        }).addCase(GetVersion.pending, (state, action) => {
            state.version.status = fetchStatus.LOADING;
        }).addCase(GetVersion.fulfilled, (state, action) => {
            state.version.data = action.payload.versionNumber;
            state.version.status = fetchStatus.SUCCEEDED;
        }).addCase(GetVersion.rejected, (state, action) => {
            state.version.status = fetchStatus.FAILED;
            state.version.error = action.error.message;
        });
    }
});

export const { updateLoginStatus } = accountsSlice.actions;

export default accountsSlice.reducer;

export const selectLoginStatus = state => state.accounts.login.status;

export const selectLoginError = state => state.accounts.login.error;

export const selectLoggedInUser = state => {
    let user = state.accounts.login.user;
    if (!user) {
        user = JSON.parse(localStorage.getItem(USER_KEY));
    }
    return user;
};

export const selectLogoutStatus = state => state.accounts.logout.status;

export const selectLogoutError = state => state.accounts.logout.error;

export const selectVersion = state => state.accounts.version.data;

export const selectVersionStatus = state => state.accounts.version.status;

export const selectVersionError = state => state.accounts.version.error;