export const covidDiagnosisEN = {
    title: "AUTHORIZATION",
    content: [
        {
            tag: "p",
            class: "font-weight-bold",
            data: "To Disclose COVID-19 Diagnosis or Exposure"
        },
        {
            tag: "p",
            data: <>
                I understand that the Americans with Disabilities Act, the Family and Medical Leave Act, the
                California Confidentiality of Medical Information Act, and other privacy laws prohibit my employer from
                disclosing my medical/health information. In the interest of the health of my co-workers and others
                with whom I may have had contact on my worksite, however, I authorize Hirebase’s <span className="cov-diag-highlight">Human
                    Resources Department and/or senior management</span> to disclose to employees at my worksite and to
                others, i.e., clients, visitors, customers, whom I may have encountered at my worksite, that I have
                tested positive for the COVID-19 virus or that I have been exposed to the virus by being in close
                contact with someone who is believed to be infected with the virus. Hirebase advised me that
                I am not required to do so and that there would be no adverse consequences to my employment if I
                chose not to do so. Further, Hirebase did not seek to coerce or pressure me to permit the
                disclosure.
            </>
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: <>
                In disclosing this information, Hirebase will take reasonable measures to keep my name
                and identity confidential to the extent possible, though I recognize that circumstances may require
                identifying me as the infected or exposed individual in order to properly warn others so they may take
                precautionary measures and help prevent further spread of the virus, and that there are times when it
                is not possible to inform others they may have been exposed to the virus without them learning that it
                was through contact with me.
            </>
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: <>
                This authorization expires on December 31, 2021 after which Hirebase will no
                longer be authorized to disclose this information. I have been advised that I have a right to receive a
                copy of this authorization.
            </>
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Signature of individual"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Date"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Printed name of the individual"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "SIGNING THIS AUTHORIZATION FORM IS VOLUNTARY"
        }
    ]
}

export const covidDiagnosisSP = {
    title: "Autorización",
    content: [
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Divulgar el diagnóstico o la exposición COVID-19 Coronavirus"
        },
        {
            tag: "p",
            data: <>
                Entiendo que la Ley de los Estadounidenses con Discapacidades, la Ley de Licencia Familiar y Médica, la Ley le prohibe Confidencialidad de la Información Médica de California y otras leyes de privacidad prohíben que mi empleador de mi información médica/de salud. Por el bien interés de la salud de mis compañeros de trabajo y de otras personas con las que pude haber tenido <span className="cov-diag-highlight">contacto en mi lugar de trabajo, le doy autorizacion al Departamento</span> de Recursos Humanos y/o a la alta dirección de Hirebase a revelar mi resultados al trabajo y a otros, es decir, clientes, visitantes, clientes, a quienes pude haber encontrado en mi sitio de trabajo, que he dado positivo para el virus COVID-19 o que he estado expuesto al virus al estar en estrecho contacto con alguien que se cree que está infectado con el virus. Hirebase me advirtió que no estoy obligado a hacerlo y que no habría consecuencias adversas para mi empleo si decido no hacerlo. Además, Hirebase no me hiso presión para permitir la divulgación.
            </>
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: <>
                Al divulgar esta información, Hirebase tomará medidas razonables para mantener mi nombre y identidad confidenciales en la medida de lo posible, aunque reconozco que las circunstancias pueden requerir identificarme como la persona infectada o expuesta con el fin de advertir adecuadamente a otros para que puedan tomar medidas de precaución y ayudar a prevenir una mayor propagación del virus, y que hay momentos en que no es posible informar a otros que pueden haber estado expuestos al virus sin ellos aprender que fue a través del contacto conmigo.
            </>
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: <>
                Esta autorización expira el 31 de diciembre de 2021 después de lo cual Hirebase ya no estará autorizada a divulgar esta información. Se me ha informado de que tengo derecho a recibir una copia de esta autorización.
            </>
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Firma del empleado"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Nombre del empleado"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "LA FIRMA DE ESTE FORMULARIO DE AUTORIZACIÓN ES VOLUNTARIA"
        }
    ]
}