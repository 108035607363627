import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { covidEmergencyTreatmentEN, covidEmergencyTreatmentSP } from './services/covidEmergencyTreatment';
import './css/concent.css';

function CovidEmergencyTreatment(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(covidEmergencyTreatmentEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(covidEmergencyTreatmentSP);
        }
        else {
            setConcent(covidEmergencyTreatmentEN);
        }
    }, [language]);

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    const onCETInitialsSigned = (value, index) => {
        props.onCETInitialsSigned && props.onCETInitialsSigned(value, index);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>
                    <span>{concent.title}</span>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[0].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[1].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>

                        {concent.content[2].data}

                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[3].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[4].data.replace("###name###", props.name)}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>

                        {concent.content[5].data}

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[6].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ol>
                            <li><p className="d-inline">{concent.content[7].data[0]}<ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                SignClasses="d-inline-block"
                                signed={props.initialsCET.includes(0)}
                                signature={props.initial}
                                onSigned={(value) => onCETInitialsSigned(value, 0)} /> (Initial)</p></li>
                            <li><p className="d-inline">{concent.content[7].data[1]}</p><ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                                signed={props.initialsCET.includes(1)}
                                signature={props.initial}
                                onSigned={(value) => onCETInitialsSigned(value, 1)} /> (Initial)</li>
                            <li><p className="d-inline">{concent.content[7].data[2]}</p><ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                                signed={props.initialsCET.includes(2)}
                                signature={props.initial}
                                onSigned={(value) => onCETInitialsSigned(value, 2)} /> (Initial)</li>
                            <li><p className="d-inline">{concent.content[7].data[3]}</p><ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                                signed={props.initialsCET.includes(3)}
                                signature={props.initial}
                                onSigned={(value) => onCETInitialsSigned(value, 3)} /> (Initial)</li>
                            <li><p className="d-inline">{concent.content[7].data[4]}</p><ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                                signed={props.initialsCET.includes(4)}
                                signature={props.initial}
                                onSigned={(value) => onCETInitialsSigned(value, 4)} /> (Initial)</li>
                            <li><p className="d-inline">{concent.content[7].data[5]}</p><ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                                signed={props.initialsCET.includes(5)}
                                signature={props.initial}
                                onSigned={(value) => onCETInitialsSigned(value, 5)} /> (Initial)</li>
                            <li><p className="d-inline">{concent.content[7].data[6].replace("###name###", props.name)}</p></li>
                        </ol>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[8].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[9].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="d-inline">
                            {concent.content[10].data}
                        </p>
                        <ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                            signed={props.initialsCET.includes(6)}
                            signature={props.initial}
                            onSigned={(value) => onCETInitialsSigned(value, 6)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="d-inline">
                            {concent.content[11].data}
                        </p>
                        <ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                            signed={props.initialsCET.includes(7)}
                            signature={props.initial}
                            onSigned={(value) => onCETInitialsSigned(value, 7)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="d-inline">
                            {concent.content[12].data}
                        </p>
                        <ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 SignClasses="d-inline-block"
                            signed={props.initialsCET.includes(8)}
                            signature={props.initial}
                            onSigned={(value) => onCETInitialsSigned(value, 8)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[13].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[14].data}
                        </p>
                        <ApplySign
                        buttonText = {language && language == "Spanish" ? "Firma" :  "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[15].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[16].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[17].data}
                        </p>
                        <IonImg src={props.reviewerSignature} className="person-signature" />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[18].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    );
}

export default CovidEmergencyTreatment;