import { IonButtons, IonContent, IonBadge, IonImg, IonPage, IonTitle, IonToolbar, IonText, IonRow, IonCol, IonButton, IonItem, IonLabel, IonInput, IonToast, IonAlert } from '@ionic/react';
import '../css/login.css';
import hirebaseLogo from '../../../assets/hirebase-logo.svg';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { Offline, Online, Detector } from "react-detect-offline";

import qrCode from '../../../assets/qrCode.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { GetVersion, selectLoginError, selectLoginStatus, selectVersion, selectVersionStatus, updateLoginStatus } from '../reducerSlices/accountsSlice';
import { React, useEffect, useState } from 'react';
import { fetchStatus, tenantId } from '../../../app/api/client';
import { accountService } from '../services/accountService';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import { isJSON } from '../../../app/utilities/utilityFunctions';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import GridLoader from '../../../components/loaders/gridLoader';

const Login = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loginStatus = useSelector(selectLoginStatus);
    const loginError = useSelector(selectLoginError);

    const version = useSelector(selectVersion);
    const versionStatus = useSelector(selectVersionStatus);

    const [appVersion, setAppVersion] = useState("1.0.11");
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [showNotFoundToast, setShowNotFoundToast] = useState(false);
    const [aumAlert, setAumAlert] = useState(false);

    const isModelValid = [email, password].every(Boolean);

    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };
   


    const onLoginClick = (e) => {
        accountService.login({client: tenantId, email: email, password: password, authenticationType: "TOKEN" });
    }

    const onInputChanged = (e) => {
        if (e.target.name === "email") {
            setemail(e.target.value);
        }
        else {
            setPassword(e.target.value);
        }
    };

    const onToastDismissed = (e) => {
        setShowToast(false);
    };

    const onToastNotFoundDismissed = (e) => {
        setShowNotFoundToast(false);
    };

    useEffect(() => {
        if (versionStatus === fetchStatus.SUCCEEDED) {
        if (version !== "") {
            if (appVersion !== version) {
                //InAppBrowser.create('');
                setAumAlert(true);
            }
        }
    }
    }, [version, appVersion]);

    useEffect(() => {
        if (versionStatus === fetchStatus.SUCCEEDED) {
            if (version !== "") {
                if (appVersion !== version) {
                    //InAppBrowser.create('');
                    setAumAlert(true);
                }
            }
        }
        else if (versionStatus === fetchStatus.IDLE) {
            dispatch(GetVersion());
        }
        
    }, [versionStatus, dispatch]);

    useEffect(() => {
        if (loginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateLoginStatus());
                let user = accountService.getAuthenticatedUser();
                if (user && user.signature) {
                    history.push("/scanApplicant");
                }
                else {
                    history.push("/reviewerSignature");
                }
            }
        }
    }, [loginStatus, dispatch, history]);
    useEffect(() => {
        if (loginStatus === fetchStatus.FAILED) {
            setShowToast(true);
        }
    }, [loginStatus]);

    const openScanner = async () => {
        const data = await BarcodeScanner.scan();
        if (data && data.text && isJSON(data.text)) {
            let dataObj = JSON.parse(data.text);
            accountService.login({ email: dataObj.email, password: dataObj.password, authenticationType: "BARCODE" });
        }
        else {
            setShowNotFoundToast(true);
        }
    };

    return (
        <IonPage>
            <IonContent>
                {versionStatus === fetchStatus.LOADING ? <div className="process-loader">
                    <GridLoader />
                </div> : <></>}
                <IonAlert
                    isOpen={aumAlert}
                    onDidDismiss={() => setAumAlert(false)}
                    cssClass=''
                    header={'New Updates'}
                    subHeader={`Current Version:${appVersion};     New Version :${version}`}
                    message={'Please update the application.'}
                    buttons={['Cancel', 'Ok']}
                />
                <div className="login-container">
                    <div className="login-controls">
                        <IonRow>
                            <IonCol>
                                <div className="image-container">
                                    <IonImg className="title-img" src={hirebaseLogo} ></IonImg></div>
                            </IonCol>
                        </IonRow>

                       
                        <IonRow>
                            <IonCol>
                                <IonCol>
                                    <div className="image-container">
                                        <IonImg className="qr-code-img" onClick={openScanner} src={qrCode} ></IonImg></div>
                                </IonCol>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        type="text"
                                        name="email"
                                        onIonInput={e => onInputChanged(e)}
                                        value={email}
                                        placeholder="Email"></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow >
                            <IonCol>
                                <IonItem>
                                    <IonInput
                                        type="password"
                                        name="password"
                                        value={password}
                                        onIonInput={e => onInputChanged(e)}
                                        placeholder="Password"></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10" className="ion-text-center">
                                {loginStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    disabled={!isModelValid}
                                    onClick={(e) => onLoginClick(e)}>
                                    Login
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                        
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="10" className="ion-text-center">
                                <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                                <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
                <IonToast
                    position="bottom"
                    header="Error"
                    color="danger"
                    isOpen={showToast}
                    onDidDismiss={(e) => onToastDismissed(e)}
                    message={loginError}
                    duration={2000}
                />
                <IonToast
                    position="bottom"
                    header="Error"
                    color="danger"
                    isOpen={showNotFoundToast}
                    onDidDismiss={(e) => onToastNotFoundDismissed(e)}
                    message={"Auth details not found"}
                    duration={2000}
                />
            </IonContent>

        </IonPage>
    );
};

export default Login;