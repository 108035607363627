import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { mutualArbitrationEN, mutualArbitrationSP } from './services/mutualArbitration';
import './css/concent.css';

function MutualArbitration(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(mutualArbitrationEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(mutualArbitrationSP);
        }
        else {
            setConcent(mutualArbitrationEN);
        }
    }, [language]);

    let items;
    items = concent.content[7].data.map((item, index) => (
        <li key={nanoid()}>{item}</li>
    ));

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{concent.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[0].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[1].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[2].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[3].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[4].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[5].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[6].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ol>
                            {items}
                        </ol>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[8].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[9].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[10].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[11].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[12].data}
                        </p>
                        <ApplySign
                        buttonText = {language && language == "Spanish" ? "Firma" :  "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[13].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="4">
                        <p className="concent-label">
                            {concent.content[14].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>

                    </IonCol>
                    <IonCol size="8">
                        <p className="concent-label">
                            {concent.content[15].data}
                        </p>
                        <p className="concent-label-value">
                            {props.reviewerName}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[16].data}
                        </p>
                        <IonImg src={props.reviewerSignature} className="person-signature" />

                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    );
}

export default MutualArbitration;