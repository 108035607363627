import { IonImg } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import SignButton from '../buttons/signButton';

import './css/applySign.css'

function ApplySign(props) {
    const [signed, setSigned] = useState(props.signed ? props.signed : false);

    useEffect(() => {
        setSigned(props.signed);
    }, [props.signed]);

    const onSignButtonClicked = (e) => {
        setSigned(true);
        props.onSigned && props.onSigned(true);
    };
    return (
        <>
            {!signed ? <SignButton
                text={props.buttonText ? props.buttonText : "Sign"}
                onSignButtonClicked={(e) => onSignButtonClicked(e)} /> :
                <IonImg src={props.signature} className={`person-signature ${props.SignClasses ? props.SignClasses : ""}`} />}
        </>
    );
}

export default ApplySign;