export const sections = {
    TEST_RESPONSE_DETAILS: 0
};

export const onValueChanged = (testResponse, e, section, value) => {
    let _testResponse = JSON.parse(JSON.stringify(testResponse));
    if (section === sections.TEST_RESPONSE_DETAILS) {
        return _saveTestResponseDetails(_testResponse, e, value);
    }
};

const _saveTestResponseDetails = (testResponse, e, value) => {
    const optionsParts = e.target.name.split('$$$');
    if (optionsParts && optionsParts.length === 2) {
        if (optionsParts[0] === "options") {
            const question = testResponse.Questions.find(q => q.id === optionsParts[1]);
            if (question) {
                question.Response = value;
            }
        }
    }
    return testResponse;
};