export const generalSafetyEN = {
    title: "GENERAL SAFETY GUIDELINES",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "The Safety Guidelines below are provided for your information and education. They are intended to provide you with basic safety information that will assist you in avoiding injury while performing your daily job functions:"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "All employees will follow these safe practice rules, render every possible aid to safe operations, and report all unsafe conditions and practices to the supervisor.",
                "All employees will be given frequent accident prevention instructions.",
                "No one will be allowed to work under the influence of alcohol and illegal drugs.",
                "No one will knowingly be permitted or required to work while his or her ability or alertness is impaired by fatigue, illness or other cause that might unnecessarily exposed the individual or others to injury.",
                "Employees should be alerted to see that all guards and other protective devices are in proper places and adjusted and will report deficiencies promptly to the supervisor.",
                "Horseplay, scuffling and other acts, which tend to endanger the safety or wellbeing of any employee are prohibited.",
                "Work will be well planned and supervised to prevent injuries when working with equipment and handling heavy materials.",
                "Workers will not handle or tamper with any electrical equipment, machinery or air or water lines in manner not within the scope of their duties, unless they have received instructions from their supervisor.",
                "Good housekeeping must always be practiced.",
                "All employees will participate in safety (toolbox meetings) conducted by their supervisors on a regular basis.",
                "All injuries will be reported promptly to the supervisor so that arrangements can be made for medical and or first aid treatment and any unsafe conditions can be corrected immediately. Supervisors will insist the employees to observe and obey every rule, Regulation and order necessary for the safe conduct of the work and will act as necessary to obtain compliance."
            ]
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "PROPER LIFTING TECHNIQUES"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "POSTURE:  Your back and neck have natural curves that should be kept flexible. Good posture maintains those curves and reduces stress on your muscles, ligaments and the shock-absorbing discs between the bones in your spine."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "PLAN: Lift mentally first, planning your route and the place you will put down the load. When the load is heavy or bulky, get help. Ask a co-worker or use equipment to ease the task"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "LIFTING:  Establish good footing as you approach the object you intend to lift. Bend your knees, not your back and get a good grip. Plan to hold the object close to your body. Tighten your stomach as you Lift. Lift smoothly with your legs, not your back, "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "MOVING: Stand straight as you move the object.  Don't twist your body while lifting; rather, turn your feet. Keep your balance.  If you have a problem, ask for help. Be sure of your footing and pathway."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand and have read the above regarding the Safety Policy and I understand that further details regarding said policy are included in the Employee Handbook."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        }
    ]
}


export const generalSafetySP = {
    title: "PAUTAS GENERALES DE SEGURIDAD",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Las pautas de seguridad a continuación se proporcionan para su información y educación. Están destinados a proporcionarle información básica de seguridad que le ayudará a evitar lesiones mientras realiza sus funciones diarias de trabajo:"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Todos los empleados seguirán estas reglas de práctica segura, prestarán todas las ayudas posibles a las operaciones seguras e informarán al supervisor de todas las condiciones y prácticas inseguras.",
                "A todos los empleados se les darán instrucciones frecuentes de prevención de accidentes.",
                "A nadie se le permitirá trabajar bajo la influencia del alcohol y las drogas ilegales.",
                "Nadie se le permitirá o exigirá a sabiendas trabajar mientras su capacidad o estado de alerta se vea afectado por fatiga, enfermedad u otra causa que pueda exponer innecesariamente a la persona u otras personas a una lesión.",
                "Los empleados deben ser alertados para ver que todos los guardias y otros dispositivos de protección están en lugares apropiados y ajustados y reportarán las deficiencias rápidamente al supervisor.",
                "Peleas amistosas, peleas y otros actos, que tienden a poner en peligro la seguridad o el bienestar de cualquier empleado están prohibidos.",
                "El trabajo estará bien planificado y supervisado para prevenir lesiones cuando se trabaja con equipos y se manipulan materiales pesados.",
                "Los trabajadores no manejarán ni manipularán ningún equipo eléctrico, maquinaria o líneas de aire o agua de manera que no estén dentro del alcance de sus funciones, a menos que hayan recibido instrucciones de su supervisor.",
                "Siempre se debe practicar una buena limpieza.",
                "Todos los empleados participarán en la seguridad (reuniones de caja de herramientas) realizadas por sus supervisores de forma regular.",
                "Todas las lesiones serán reportadas inmediatamente al supervisor para que se puedan hacer       arreglos para el tratamiento médico y o de primeros auxilios y cualquier condición insegura pueda ser corregida inmediatamente. Los supervisores insistirán en que los empleados observen y obedezcan todas las reglas, regulaciones y órdenes necesarias para el desarrollo seguro del trabajo y actuarán según sea necesario para obtener el cumplimiento."
            ]
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "TÉCNICAS DE ELEVACIÓN ADECUADAS"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "LA POSTURA:  La espalda y el cuello tienen curvas naturales que deben mantenerse flexibles. Una buena postura mantiene esas curvas y reduce el estrés en los músculos, los ligamentos y los discos que absorben los impactos entre los huesos de la columna vertebral."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "PLAN: Levante mentalmente primero, la planificación de su ruta y el lugar que va a poner la carga. Cuando la carga sea pesada o voluminosa, obtenga ayuda. Pregunte a un compañero de trabajo o use equipo para facilitar la tarea."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "EL LEVANTAMIENTO:  Establezca una buena base a medida que se acerca al objeto que desea levantar. Dobla las rodillas, no la espalda y agarra un buen agarre. Planee sostener el objeto cerca de su cuerpo. Apriete el estómago mientras levanta. Levanta suavemente con las piernas, no con la espalda."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "EN MOVIMIENTO: Aclare recto mientras mueve el objeto. No tuerzas tu cuerpo mientras levantas; más bien, girar los pies. Mantén el equilibrio. Si tienes un problema, pide ayuda. Asegúrese de que el pie y el camino."
        },
        {
            tag: "p",
            class: "text-justify",
            data: ""
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        }
    ]
}