export const mutualArbitrationEN = {
    title: "MUTUAL ARBITRATION AGREEMENT",
    content: [
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Employment Arbitration Program"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "In the unlikely event there is any legal controversy, claim or dispute arising out of or relating to employment or its termination that cannot be resolved through the problem-solving procedure, then the employee and the Company may agree to submit all such legal disputes to impartial arbitration rather than a court or jury trial.  The employee will have agreed to such resolution at the time of employment.  Agreement to arbitrate is not a condition of employment."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase has adopted an impartial arbitration program as an efficient, private means of resolving workplace disputes involving legally protected rights.  This means that an employee and the Company will resolve any employment disputes covered by the employment arbitration agreement through binding arbitration and not by a court or jury trial. (Please refer to the Mutual Agreement to Arbitrate Claims that was included in the new hire packet or request a copy from the Human Resources Department.) To facilitate an early resolution of a dispute, both the employee and the Company may use mediation before initiating arbitration or prior to the start of the arbitration hearing."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Arbitration applies to any action or relief that an employee might seek personally through the courts for a workplace dispute.  However, an employee is still free to consult the U.S. Equal Employment Opportunity Commission or any other federal or state government regulatory agency regarding his or her workplace complaint."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Although Hirebase its customers, clients, officers, employees, agents, (hereinafter “Hirebase”, “Company”, “Employer” hope that employment disputes with its employees will not occur. Hirebase believes that if or when these disputes do arise, it is in the mutual interest of the employee, employer and all concerned to handle them promptly, privately, and with minimum disturbance to the operations of Hirebase’s businesses and the lives of its employees. Accordingly, Hirebase has instituted a binding Arbitration procedure for the Company and for employees to handle all employment related disputes that may arise, as explained below."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Choosing to arbitrate will limit any substantive rights that an employee or that Company would have had in litigation, if the dispute were decided there, and will not impose any added costs or fees on an employee that would not have applied in litigation."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "The parties shall engage in individual arbitration if EEOC serve a right to sue notice and parties reserve the right to appeal an adverse labor commissioner ruling."
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Date and Signatures: This Arbitration Agreement (“Agreement”) is made between the employee whose signature is below (hereinafter “Employee”) and Hirebase, its customers, clients, employees, and agents (collectively referred to as “parties”) on the date signed below. Employee acknowledges that signing this Agreement is voluntary.",
                "Private non-judicial (ADR) alternative dispute resolution:  Employee acknowledges in the interests of himself/herself, employer and concerned parties, to avoid unnecessary, costly, time consuming and public litigation, that Hirebase utilizes alternative dispute resolution which involves binding Arbitration to resolve all disputes which may arise out of or relate to the employment relationship between Employee and the Company.",
                "Binding Arbitration:  Employee has the option to enter into the Arbitration Agreement.  It is not a condition of employment or continued employment with Hirebase.   Should Employee and Hirebase agree to exclusively engage in mandatory binding Arbitration for any and all disputes, claims, or controversies (“claims”) they may have against each other, including their current and former agents, owners, officers, directors, or employees, arising from the employment relationship between Employee and Employer or the termination thereof a separate agreement will be signed and acknowledged by both parties.",
                "Claims covered: The claims covered by this Agreement include, but are not limited to claims of employment discrimination and harassment under Title VII of the Civil Rights Act, as amended; the California Fair Employment & Housing Act; Equal Pay Act; Wage Theft Prevention Act; the Age Discrimination in Employment Act, as amended; the Americans with Disabilities Act; 42 U.S.C. § 1981; the Employment Retirement Income Security Act; the California Labor Code, including any claims brought by the Employee related to wages; meal and rest breaks; wage disclosure notices; employment notices of any kind; breach of employment contract or the implied covenant of good faith and fair dealing; wrongful discharge; or tortious conduct (whether intentional or negligent) including defamation, misrepresentation, fraud, and infliction of emotional distress.",
                "Waiver of Litigation:  The parties understand and agree that they are waiving their right to bring such claims to court, including the right to a jury trial. This Agreement, however, does not apply to claims for workers’ compensation benefits to remedy work-related injuries, unemployment insurance claims, or state and federal disability insurance claims.",
                "Administrative Agencies:  Employee and Company understand and agree that claims arising under any law that permits resort to an administrative agency notwithstanding an agreement to Arbitrate those claims may be brought before that agency as permitted by that law, including without limitation claims or charges brought before the National Labor Relations Board, the Equal Employment Opportunity Commission, the United States Department of Labor, the California Workers’ Compensation Appeals Board, and the California Employment Development Department. Employee and the Company also understand and agree that after exhaustion of administrative remedies through the Department of Fair Employment and Housing and/or the Equal Employment Opportunity Commission or other administrative agency under a statute that requires exhaustion of administrative agency before seeking relief, the parties must pursue any such claims through this binding arbitration procedure.",
                "Waiver of Class Action: Employee agrees to waive their right to bring any wage and hour, discrimination, Labor Code violation, and other types of lawsuits as a representative or class action against the Company.",
                "The parties agree to submit the claims or dispute to binding Arbitration. The binding Arbitration shall be conducted by a single neutral Arbitrator at either A.A.A. in Los Angeles, CA or J.D.R. in Costa Mesa, CA. American Arbitration Association (A.A.A.) Los Angeles, CA  213-362-1900  www.ADR.org/employment Judicial Dispute Resolution (J.D.R.) Costa Mesa, CA   800-404-4537 www.JDR4ADR.com/rules-employment",
                "Selection of the Arbitrator:   The parties agree to contact the ADR service above and separately request a minimum of three (3) names of neutral Arbitrators from the employment panels. If the parties are unsuccessful in reaching an accord with either the panel or the arbitrator, then those selected names from the parties will be submitted to the Orange County Superior Court or other California Superior Court for determination by the assigned judge or commissioner, but will not grant jurisdiction to the court or waive the Agreement for Arbitration.",
                "Rules for the Arbitration:  If the parties select J.D.R., the parties agree to follow the FAA. The parties may modify their rules by stipulation reached between the parties and the Arbitrator at the Arbitration Management Conference (AMC) which shall be calendared within 30 days upon selection of a J.D.R. neutral. If the parties select A.A.A., the parties agree to follow and be bound by “The National Rules for the Resolution of Employment Disputes” issued by the A.A.A. found on the A.A.A. website [www.adr.org] by calling 213-362-1900.The Arbitration will allow for the following: 1) all forms of relief available under court of law; 2) neutral arbitrator selected by both parties; 3) more than minimal discovery; 4) a written award; and 5) no additional costs to employee beyond those that would have been incurred by bringing the claim to court 6) that the employee and employer are waiving right to have any employment or post-employment dispute heard by judge and jury.",
                "Pre-hearing Motions:  Either party may file pre-hearing motions directed at the legal sufficiency of a claim or defense equivalent to a demurrer or summary judgment prior to the time of Arbitration.",
                "Award of Arbitrator:  The Arbitrator will issue a detailed written decision and award resolving the dispute and which shall decide all issues setting the legal principle(s) supporting each part of the award.",
                "Finality of Award:  The parties understand and agree the decision or award shall be final and binding upon all parties to the Arbitration. No appeal for subsequent Arbitration is permitted or interpreted by this Agreement. The award may be entered as a judgment or order in any court of competent jurisdiction. The parties agree that any relief or recovery to which they are entitled arising out of the employment relationship or cessation thereof, shall be limited to that of the award of the Arbitrator.",
                "Fees of the Arbitrator:  Hirebase will pay the arbitrator’s fee and expenses for the arbitration hearing, i.e., hearing time, pre-Arbitration motion hearing or discovery dispute, review, research of the Arbitration briefs, post-hearing evidence review, award preparation, etc. The fees of the arbitrator will not be used as offset against the parties in any award.  However, the parties understand and agree that each party will bear their own attorney’s fees, legal or preparation costs, and other expenses associated with the arbitration.",
                "Demand for Arbitration:  The demand for Arbitration shall be made by either party within the time limit established by the applicable statute of limitations for the asserted claims or within one year of the conduct that forms the basis of the claim, if no statutory limitation is applicable. Failure to demand arbitration within the prescribed time period shall result in waiving of said claims.",
                "No contract of employment: Neither the terms nor the conditions described in this Agreement are intended to create a contract of employment for a specific duration of time or to limit the circumstances under which the parties’ employment relationship may be terminated. Since employment with the Employer is voluntary entered, Employee is free to resign at any time. Similarly, the Employer may terminate the employment relationship without cause or notice at any time.",
                "The laws of the Federal Arbitration Act:  This Agreement and the Arbitration shall be governed by and interpreted by the laws of the Federal Arbitration Act. The terms of this Agreement shall not be orally modified but can be modified only by a written document signed by the President of Hirebase and the Employee.",
                "Invalid or unenforceable Terms:  If any term or portion of this Agreement shall, for any reason, be held by a court of competent jurisdiction to be invalid or unenforceable or to be contrary to public policy or any law, then the remainder of this Agreement shall not be affected by such invalidity or unenforceability but shall remain in full force and effect, as if the invalid or unenforceable term or portion had not existed within this Agreement.",
                "Delegation Clause: If there is any issue about whether the arbitration agreement covers a particular dispute or is otherwise enforceable, the arbitrator, and not a court, will decide the issue. No retaliation:  No retaliation shall be taken against any Employee for any Demand for Arbitration. No Employee shall be subject to retaliation if he or she exercises his or her right to assert claims under this Agreement and any retaliatory act or omission is directly contrary to Company policy. If any Employee believes that he or she has been retaliated against by anyone at the Company, the Employee should immediately report this to the Human Resource Department or the Legal Department. Also, Employee has been advised of his/her right to consult with his/her own counsel regarding this Agreement."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that this is optional, and we are waiving all rights to a trial or hearing before a court or jury of all disputes and claims subject to arbitration under this Agreement.  Nothing in this agreement alters the “at will” nature of employment with the Company."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Knowing and Voluntary Agreement"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "We agree that we have read this Agreement carefully and understand that by signing it, I understand that this is optional and we are waiving all rights to a trial or hearing before a court or jury of any and all disputes and claims subject to arbitration under this Agreement.  Nothing in this agreement alters the “at-will” nature of employment with the Company."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dated"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase, Inc, Representative Title"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "SBS, Inc. Signature"
        }
    ]
}


export const mutualArbitrationSP = {
    title: "ACUERDO DE ARBITRAJE MUTUO",
    content: [
        {
            tag: "p",
            class: "font-weight-bold",
            data: ""
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Aunque Hirebase. y SBS & Associates, sus clientes, clientes, funcionarios, empleados, agentes, (en adelante 'Hirebase.', 'Company', 'Employer') esperan que no se produzcan disputas laborales con sus empleados. Hirebase. cree que si o cuando surgen estas disputas, es en interés mutuo del empleado, empleador y todos los interesados manejarlas de manera pronta, privada y con mínima perturbación a las operaciones de Hirebase, los negocios de Inc. y la vida de sus empleados. En consecuencia, Hirebase. ha establecido un procedimiento de arbitraje obligatorio para que todos los empleados puedan manejar todas las disputas relacionadas con el empleo que puedan surgir, como se explica a continuación."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: ""
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: ""
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: ""
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: ""
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: ""
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Fecha y Firmas: Este Acuerdo de Arbitraje ('Acuerdo') se hace entre el empleado cuya firma es a continuación (en adelante 'Empleado') y Hirebase, sus clientes, clientes, empleados y agentes (denominados colectivamente 'partes') en la fecha firmada a continuación. El Empleado reconoce que la firma de este Acuerdo es voluntaria.",
                "Resolución de disputas alternativas privadas no judiciales (ADR): El empleado reconoce en interés de sí mismo, empleador y partes interesadas, para evitar litigios innecesarios, costosos, lentos y públicos, que Hirebase utiliza la resolución alternativa de disputas que implica un arbitraje vinculante para resolver todas las disputas que puedan surgir o relacionarse con la relación laboral entre el Empleado y la Compañía.",
                "Arbitraje vinculante obligatorio: En consideración al empleo o al empleo continuado con Hirebase, el empleado y Hirebase acuerdan participar exclusivamente en un arbitraje obligatorio vinculante para todas y cada una de las disputas, reclamos o controversias ('reclamaciones') que puedan tener entre sí, incluidos sus agentes actuales y anteriores, propietarios, funcionarios, directores o empleados, que surjan de la relación laboral entre el Empleado y el Empleador o la terminación de los mismos.",
                "Reclamaciones cubiertas: Las reclamaciones cubiertas por este Acuerdo incluyen, pero no se limitan a las reclamaciones de discriminación laboral y acoso bajo el Título VII de la Ley de Derechos Civiles, en su forma enmendada; la Ley de Empleo Justo y Vivienda de California; Ley de Igualdad Salarial; Ley de Prevención del Robo de Salarios; la Ley de Discriminación por Edad en el Empleo, modificada; la Ley de Estadounidenses con Discapacidades; 42 U.S.C. n.o 1981; la Ley de Seguridad de ingresos para la jubilación del empleo; el Código Laboral de California, incluyendo cualquier reclamo presentado por el Empleado relacionado con los salarios; descansos para la comida y el descanso; avisos de divulgación salarial; avisos de empleo de cualquier tipo; incumplimiento del contrato de trabajo o el pacto implícito de buena fe y trato justo; descarga injusta; o conducta tortuosa (ya sea intencional o negligente) incluyendo difamación, tergiversación, fraude e inflicción de angustia emocional.",
                "Renuncia a litigio: Las partes entienden y acuerdan que están renunciando a su derecho a llevar dichas reclamaciones a los tribunales, incluido el derecho a un juicio con jurado. Este Acuerdo, sin embargo, no se aplica a las reclamaciones por beneficios de compensación de trabajadores para remediar lesiones relacionadas con el trabajo, reclamos de seguro de desempleo o reclamos de seguro de discapacidad estatales y federales.",
                "Agencias Administrativas: Empleado y Compañía entienden y acuerdan que las reclamaciones que surjan bajo cualquier ley que permita recurrir a una agencia administrativa a pesar de un acuerdo para arbitrar esas reclamaciones pueden ser presentadas ante esa agencia según lo permita esa ley, incluyendo, entre otros, las reclamaciones o cargos presentados ante la Junta Nacional de Relaciones Laborales, la Comisión de Igualdad de Oportunidades en el Empleo, el Departamento de Trabajo de los Estados Unidos, la Junta de Apelaciones de Compensación de Trabajadores de California y el Departamento de Desarrollo del Empleo de California. El Empleado y la Compañía también entienden y acuerdan que después del agotamiento de los recursos administrativos a través del Departamento de Empleo Justo y Vivienda y/o la Comisión de Igualdad de Oportunidades en el Empleo u otro organismo administrativo bajo un estatuto que requiera el agotamiento del organismo administrativo antes de solicitar la reparación, las partes deben perseguir tales reclamaciones a través de este procedimiento de arbitraje vinculante.",
                "Renuncia a la Demanda Colectiva: El Empleado acepta renunciar a su derecho a presentar cualquier salario y hora, discriminación, violación del Código Laboral y otros tipos de demandas como representante o demanda colectiva contra la Compañía.",
                "Las partes acuerdan someter las reclamaciones o disputas a arbitraje vinculante. El Arbitraje vinculante será llevado a cabo por un solo árbitro neutral en A.A.A. en Los Angeles, CA o J.D.R. en Costa Mesa, CA. Asociación Americana de Arbitraje (A.A.A.) Los Angeles, CA 213-362-1900 www.ADR.org Resolución de DisputasJudiciales (J.D.R.) Costa Mesa, CA 800-404-4537 www.JDR4ADR.com",
                "Selección del árbitro:   Las partes acuerdan ponerse en contacto con el servicio ADR anterior y solicitar por separado un mínimo de tres (3) nombres de árbitros a los grupos de trabajo. Si las partes no logran llegar a un acuerdo con el panel o el árbitro, esos nombres seleccionados de las partes se presentarán al Tribunal Superior del Condado de Orange u otro Tribunal Superior de California para su determinación por el juez o comisionado asignado, pero no otorgará jurisdicción al tribunal ni renunciará al Acuerdo de Arbitraje.",
                "Reglas para el Arbitraje:  Si las partes seleccionan J.D.R., las partes acuerdan seguir las Reglas de Evidencia de CA y las Reglas de Procedimiento Civil de la CA. Las partes podrán modificar sus reglas mediante estipulación alcanzada entre las partes y el árbitro en la Conferencia de Gestión de Arbitraje (AMC) que se calendariorá dentro de los 30 días siguientes a la selección de un J.D.R. neutral. Si las partes seleccionan A.A.A., las partes acuerdan seguir y estar obligadas por 'Las Reglas Nacionales para la Resolución de Controversias Laborales' emitidas por la A.A.A. que se encuentran en el sitio web de A.A.A. [www.adr.org] llamando al 213-362-1900.",
                "Mociones previas a la audiencia:  Cualquiera de las partes puede presentar mociones previas a la audiencia dirigidas a la suficiencia legal de una reclamación o defensa equivalente a un desmiente o sentencia sumaria antes de la hora del arbitraje.",
                "Laudo de Árbitro:  El árbitro emitirá una decisión detallada por escrito y la adjudicación de la resolución de la disputa y que decidirá todas las cuestiones que establecen los principios legales que apoyan cada parte del laudo.",
                "Finalidad del premio:  Las partes entienden y acuerdan que la decisión o laudo será definitiva y vinculante para todas las partes en el Arbitraje. Este Acuerdo no permite ni interpreta ninguna apelación para el arbitraje posterior. El laudo podrá ser dictado como sentencia u orden en cualquier tribunal de jurisdicción competente. Las partes acuerdan que cualquier alivio o recuperación a la que tengan derecho que surja de la relación laboral o cese de la misma, se limitará a la del laudo del árbitro.",
                "Honorarios del Árbitro:  Hirebase pagará los honorarios y gastos del árbitro por la audiencia de arbitraje, es decir, tiempo de audiencia, audiencia de moción previa al arbitraje o disputa de descubrimiento, revisión, investigación de los informes de arbitraje, revisión de pruebas posteriores a la audiencia, preparación del laudo, etc. Los honorarios del árbitro no se utilizarán como compensación contra las partes en ningún laudo.  in embargo, las partes entienden y acuerdan que cada parte asumirá sus propios honorarios de abogado, costos legales o de preparación, y otros gastos asociados con el arbitraje.",
                "Demanda de Arbitraje: La demanda de Arbitraje será hecha por cualquiera de las partes dentro del plazo establecido por la ley aplicable de limitaciones para las reclamaciones afirmadas o dentro de un año de la conducta que constituye la base de la reclamación, si no se aplica ninguna limitación legal. La falta de arbitraje dentro del plazo prescrito dará lugar a la renuncia a dichas reclamaciones.",
                "Ningún contrato de trabajo: Ni los términos ni las condiciones descritas en este Acuerdo tienen por objeto crear un contrato de trabajo por un período de tiempo específico o limitar las circunstancias en las que la relación laboral de las partes puede ser rescindida. Dado que el empleo con el empleador es voluntario celebrado, el empleado es libre de renunciar en cualquier momento. Del mismo modo, el empleador puede poner fin a la relación laboral sin causa o notificación en cualquier momento.",
                "Las leyes del Estado de California: Este Acuerdo y el Arbitraje se regirán e interpretarán por las leyes del Estado de California. Los términos de este Acuerdo no se modificarán oralmente, sino que solo podrán ser modificados por un documento escrito firmado por el Presidente de Hirebase y el Empleado.",
                "Términos inválidos o inaplicables: Si cualquier término o parte de este Acuerdo, por cualquier razón, será considerado por un tribunal de jurisdicción competente como inválido o inaplicable o contrario a la política pública o a cualquier ley, entonces el resto de este Acuerdo no se verá afectado por dicha invalidez o inaplicabilidad, pero permanecerá en pleno vigor y efecto, como si el término o parte inválida o inaplicable no hubiera existido dentro de este Acuerdo.",
                "Cláusula de delegación: Si hay alguna cuestión sobre si el acuerdo de arbitraje cubre una disputa en particular o es ejecutable de otro modo, el árbitro, y no un tribunal, decidirá la cuestión."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Sin represalias: No se tomarán represalias contra ningún Empleado por ninguna Demanda de Arbitraje. Ningún Empleado estará sujeto a represalias si ejerce su derecho a hacer valer reclamaciones en virtud de este Acuerdo y cualquier acto u omisión de represalia es directamente contrario a la política de la Empresa. Si algún Empleado cree que ha sido represaliado por alguien de la Compañía, el Empleado debe reportar inmediatamente esto al Departamento de Recursos Humanos o al Departamento Legal. Además, el Empleado ha sido informado de su derecho a consultar con su propio abogado con respecto a este Acuerdo."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Conocimiento y Acuerdo Voluntario"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Estamos de acuerdo en que hemos leído este Acuerdo cuidadosamente y entendemos que al firmarlo, entiendo que esto es opcional y estamos renunciando a todos los derechos a un juicio o audiencia ante un tribunal o jurado de todas y cada una de las disputas y reclamos sujetos a arbitraje bajo este Acuerdo. Nada en este acuerdo altera la naturaleza 'a voluntad' del empleo en la Compañía."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fechada"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Titulo del Representativo de Hirebase"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma SBS, Inc."
        }
    ]
}