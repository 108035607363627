import { IonButton, IonButtons, IonCard, IonCardContent, IonBadge, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { call, mail, location, informationCircle } from 'ionicons/icons';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInUser } from './accounts/reducerSlices/accountsSlice';
import GridLoader from '../components/loaders/gridLoader';
import VaccinationPolicy from '../components/concents/vaccinationPolicy';
import VaccineAccomodation from '../components/concents/vaccineAccomodation';
import { Offline, Online, Detector } from "react-detect-offline";

import { fetchStatus, tenantId } from '../app/api/client';
import EllipsisLoader from '../components/loaders/ellipsisLoader';
import { accountService } from './accounts/services/accountService';
import { _getLocationText } from '../data/masterData';
import { useHistory } from 'react-router';
import {
    selectApplicantById,
    updateApplicant,
    selectSingleStatus as applicantStatus,
    getApplicantById,
    selectModificationStatus,
    selectSignature,
    selectIntial,
    updateModificationStatus
} from './applicant/reducerSlices/applicantSlice';


const VaccinationDetails = (props) => {
    const { id, lang } = props.match.params;
    const dispatch = useDispatch();
    const history = useHistory();

    const signatureData = useSelector(selectSignature);
    const initialData = useSelector(selectIntial);

    const [showSubmitToast, setShowSubmitToast] = useState(false);

    const user = useSelector(selectLoggedInUser);
    const applicantModificationRequestStatus = useSelector(selectModificationStatus);

    const applicant = useSelector((state) => selectApplicantById(state, id));

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [ssn, setSSN] = useState("");
    const [dob, setDOB] = useState("");
    const [signature, setSignature] = useState("");
    const [initial, setInitial] = useState("");
    const [locationVal, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const [language, setLanguage] = useState("");
    const [vpSigned, setVPSigned] = useState(false);
    const [isSavePressed, setIsSavePressed] = useState("");
    const polling = {
        enabled: true,
        interval: 4000,
        timeout: 1000
      };

    let _isModelValid = [
        vpSigned
    ].every(Boolean);

    useEffect(() => {
        if (applicant) {
            let loc = _getLocationText(applicant.LocationId);
            setFirstName(applicant.FirstName);
            setLastName(applicant.LastName);
            setLanguage(lang);
            setEmail(applicant.Email);
            setPhoneNumber(applicant.PhoneNumber);
            setDOB(applicant.DOB);
            setInitial(initialData);
            setSignature(signatureData);
            setLocation(loc);
            setStatus(applicant.Status);
        }

    }, [applicant, initialData, signatureData]);

    const onBackClicked = (e) => {
        history.push(`/concentAcceptance/${id}/${language}`);
    };

    const onSubmitClicked = async (e) => {
        const user = accountService.getAuthenticatedUser();
        setShowSubmitToast(true);
          if (_isModelValid) {
            try {
                setIsSavePressed("True");
                await dispatch(
                    updateApplicant(
                        {
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "SAVEAPPLICANTCACCINATIONDETAILS",
                            payload: {
                                id: id,
                                data: JSON.stringify({
                                    reviewerId: user.id,
                                    applicantId: id,
                                    language: language
                                })
                            }
                        })
                );
               
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
         }
         else 
         {
             alert('Please sign all places');
         }

    };

    useEffect(() => {
        if (applicant && isSavePressed === "True") {
            if (applicantModificationRequestStatus === fetchStatus.SUCCEEDED) {
                dispatch(updateModificationStatus());
                setIsSavePressed("");
                history.push(`/vaccineAccomodationDetails/${id}/${language}`);
            }
            if (applicantModificationRequestStatus === fetchStatus.FAILED) {
                alert('Data upload failed, Please try again')
            }
        }
    }, [applicantModificationRequestStatus]);

    const onToastDismissed = () => {
        setShowSubmitToast(false);
        // dispatch(updateModificationStatus());
        // history.push(`/vaccineAccomodationDetails/${id}/${language}`);
       
    };

    // useEffect(() => {
    //     if(applicant && isSavePressed === "True")
    //     {
    //         if (applicantModificationRequestStatus == fetchStatus.SUCCEEDED) {
    //             //history.push(`/workExperience/${id}/${language}`);
    //             dispatch(updateModificationStatus());
    //             setIsSavePressed("");
    //             history.push(`/concentAcceptance/${id}/${language}`);
               
    //         }
    //         if (applicantModificationRequestStatus == fetchStatus.FAILED) {
    //             alert('Data upload failed, Please try again')
             
    //         }
    //     }   

    // }, [applicantModificationRequestStatus]);

    

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
            {applicantModificationRequestStatus === fetchStatus.LOADING ? <div className="process-loader">
                    <GridLoader />
                </div> : <></>}
                <IonCard>
                    <IonCardHeader>

                        <IonRow>
                            <IonCol>
                                <IonCardTitle>{`${firstName} ${lastName}`}</IonCardTitle>
                            </IonCol>
                            <IonCol className="ion-text-center">
                                <IonButton
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{email}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{phoneNumber}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{locationVal}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{status}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <VaccinationPolicy
                    language={language}
                    name={firstName + " " + lastName}
                    signed={vpSigned}
                    signature={signature}
                    onSigned={(v) => setVPSigned(v)} />
               
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    disabled={!_isModelValid}
                                    title={!_isModelValid ? "Please accept and sign the concents" : "Click to submit"}
                                    onClick={() => onSubmitClicked()}>
                                    Next
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonToast
                    position="middle"
                    header="Saved"
                    color="dark"
                    isOpen={showSubmitToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="Vaccination Policy Saving, Please wait"
                    duration={1000}
                />
            </IonContent>
        </IonPage>
    )
}

export default VaccinationDetails;