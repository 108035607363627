import { IonButton, IonButtons, IonCard, IonCardContent, IonBadge, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { call, mail, location, informationCircle } from 'ionicons/icons';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInUser } from './accounts/reducerSlices/accountsSlice';

import VaccinationPolicy from '../components/concents/vaccinationPolicy';
import VaccineAccomodation from '../components/concents/vaccineAccomodation';


import { fetchStatus, tenantId } from '../app/api/client';
import EllipsisLoader from '../components/loaders/ellipsisLoader';
import { accountService } from './accounts/services/accountService';
import { _getLocationText } from '../data/masterData';
import GridLoader from '../components/loaders/gridLoader';
import { Offline, Online, Detector } from "react-detect-offline";

import { useHistory } from 'react-router';
import {
    selectApplicantById,
    updateApplicant,
    selectSingleStatus as applicantStatus,
    getApplicantById,
    selectModificationStatus,
    selectSignature,
    selectIntial,
    updateModificationStatus
} from './applicant/reducerSlices/applicantSlice';


const VaccinationDetails = (props) => {
    const { id, lang } = props.match.params;
    const dispatch = useDispatch();
    const history = useHistory();
    const polling = {
        enabled: true,
        interval: 4000,
        timeout: 1000
      };
    const signatureData = useSelector(selectSignature);
    const initialData = useSelector(selectIntial);

    const [showSubmitToast, setShowSubmitToast] = useState(false);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [locationVal, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const user = useSelector(selectLoggedInUser);
    const applicantModificationRequestStatus = useSelector(selectModificationStatus);
    const applicant = useSelector((state) => selectApplicantById(state, id));

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [signature, setSignature] = useState("");
    const [initial, setInitial] = useState("");

    const [language, setLanguage] = useState("");
    // const [vaSigned, setVASigned] = useState(false);
    const [vaSigned, setVASigned] = useState([]);
    const [initialsVA, setInitialsVA] = useState(false);

    // Option State
    const [checkedIWantToBeVaccinated, setCheckedIWantToBeVaccinated] = useState(false);
    const [checkedIAmInTheProcess, setCheckedIAmInTheProcess] = useState(false);
    const [checkedIAmFullyVaccinated, setCheckedIAmFullyVaccinated] = useState(false);
    const [checkedIDeclineToBeVaccinated, setCheckedIDeclineToBeVaccinated] = useState(false);
    const [checkedOption1, setCheckedOption1] = useState(false);
    const [checkedOption2, setCheckedOption2] = useState(false);
    const [checkedOption3, setCheckedOption3] = useState(false);
    const [receivedDateValue, setReceivedDateValue] = useState("");
    const [checkedIAmSeeking, setCheckedIAmSeeking] = useState(false);
    const [vaccinatedDateValue, setVaccinatedDateValue] = useState("");

    const [checkedPractice, setCheckedPractice] = useState(false);

    const [photoData, setPhotoData] = useState("");
    const [photoURL, setPhotoURL] = useState("");
    
    const [photoDataBeingVaccinated, setPhotoDataBeingVaccinated] = useState("");
    const [photoURLBeingVaccinated, setPhotoURLBeingVaccinated] = useState("");
    
    const [photoDataFullyVaccinated, setPhotoDataFullyVaccinated] = useState("");
    const [photoURLFullyVaccinated, setPhotoURLFullyVaccinated] = useState("");

    const [photoDataDoctorNote, setPhotoDataDoctorNote] = useState("");
    const [photoURLDoctorNote, setPhotoURLDoctorNote] = useState("");
    const [isSavePressed, setIsSavePressed] = useState("");

    let _isModelValid = [
        // checkedIWantToBeVaccinated,
        // checkedIAmInTheProcess,
        // checkedIAmFullyVaccinated,
        // checkedIDeclineToBeVaccinated,
        // checkedOption1,
        // checkedOption2,
        // checkedOption3,
        // receivedDateValue,
        // vaccinatedDateValue
        // checkedIAmSeeking
    ];

    useEffect(() => {
        if (applicant) {
            setFirstName(applicant.FirstName);
            setLastName(applicant.LastName);
            setLanguage(lang);
            setInitial(initialData);
            setSignature(signatureData);
            let loc = _getLocationText(applicant.LocationId);
            setEmail(applicant.Email);
            setPhoneNumber(applicant.PhoneNumber);
       
            setLocation(loc);
            setStatus(applicant.Status);
        }

    }, [applicant, initialData, signatureData]);

    const onBackClicked = (e) => {
        history.push(`/vaccinationDetails/${id}/${language}`);
    };

    // const onVAInitialsSigned = (v, i) => {
    //     setInitialsVA([...initialsVA, i]);
    // }

    const onVASigned = (v, i) => {
        setVASigned([...vaSigned, i]);
    }

    const onSubmitClicked = async (e) => {

        const user = accountService.getAuthenticatedUser();
        setShowSubmitToast(true);
        if (_isModelValid) {
            try {
                setIsSavePressed("True");
                await dispatch(
                    updateApplicant(
                        {
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "SAVEAPPLICANTCACCINATION",
                            payload: {
                                id: id,
                                data: JSON.stringify({
                                    reviewerId: user.id,
                                    applicantId: id,
                                    language: language,
                                    iWantToBeVaccinated: checkedIWantToBeVaccinated,
                                    iAmInTheProcess: checkedIAmInTheProcess,
                                    iAmFullyVaccinated: checkedIAmFullyVaccinated,
                                    iDeclineToBeVaccinated: checkedIDeclineToBeVaccinated,
                                    option1: checkedOption1,
                                    option2: checkedOption2,
                                    option3: checkedOption3,
                                    receivedDateValue: receivedDateValue,
                                    vaccinatedDateValue: vaccinatedDateValue,
                                    iAmSeeking: checkedIAmSeeking,
                                    checkedPractice:  checkedPractice,
                                    photoBeingVaccinated: {
                                        contentType: 'image/jpeg',
                                        photoDataBeingVaccinated: photoDataBeingVaccinated,
                                        length: 0
                                    },
                                    photoFullyVaccinated: {
                                        contentType: 'image/jpeg',
                                        photoDataFullyVaccinated: photoDataFullyVaccinated,
                                        length: 0
                                    },
                                    photoDoctorNote: {
                                        contentType: 'image/jpeg',
                                        photoDataDoctorNote: photoDataDoctorNote,
                                        length: 0
                                    },
                                })
                            }
                        })
                );

            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
        }
        else {
            alert('Please sign all places');
        }

    }

    useEffect(() => {
        if (applicant && isSavePressed === "True") {
            if (applicantModificationRequestStatus === fetchStatus.SUCCEEDED) {
                dispatch(updateModificationStatus());
                setIsSavePressed("");
                history.push(`/workExperience/${id}/${language}`);
            }
            if (applicantModificationRequestStatus === fetchStatus.FAILED) {
                alert('Data upload failed, Please try again')
            }
        }
    }, [applicantModificationRequestStatus]);

    const onToastDismissed = () => {
        setShowSubmitToast(false);
        // dispatch(updateModificationStatus());
        // history.push(`/workExperience/${id}/${language}`);
    };

    return (
        <IonPage>
               <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
            {applicantModificationRequestStatus === fetchStatus.LOADING ? <div className="process-loader">
                    <GridLoader />
                </div> : <></>}
                <IonCard>
                    <IonCardHeader>

                        <IonRow>
                            <IonCol>
                                <IonCardTitle>{`${firstName} ${lastName}`}</IonCardTitle>
                            </IonCol>
                            <IonCol className="ion-text-center">
                                <IonButton
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{email}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{phoneNumber}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{locationVal}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{status}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <VaccineAccomodation
                    language={language}
                    name={firstName + " " + lastName}
                    signed={vaSigned}
                    initial={initial}
                    signature={signature}
                    initialsVA={initialsVA}
                    // onVAInitialsSigned={(v, i) => onVAInitialsSigned(v, i)}
                    onVASigned={(v, i) => onVASigned(v, i)}
                    reviewerSignature={user.signature}
                    onSigned={(v) => setVASigned(v)}

                    checkedIWantToBeVaccinated={checkedIWantToBeVaccinated}
                    onCheckedIWantToBeVaccinated={(v) => setCheckedIWantToBeVaccinated(v)}
                    checkedIAmInTheProcess={checkedIAmInTheProcess}
                    onCheckedIAmInTheProcess={(v) => setCheckedIAmInTheProcess(v)}
                    checkedIAmFullyVaccinated={checkedIAmFullyVaccinated}
                    onCheckedIAmFullyVaccinated={(v) => setCheckedIAmFullyVaccinated(v)}
                    checkedIDeclineToBeVaccinated={checkedIDeclineToBeVaccinated}
                    onCheckedIDeclineToBeVaccinated={(v) => setCheckedIDeclineToBeVaccinated(v)}

                    checkedOption1={checkedOption1}
                    onCheckedOption1={(v) => setCheckedOption1(v)}
                    checkedOption2={checkedOption2}
                    onCheckedOption2={(v) => setCheckedOption2(v)}
                    checkedOption3={checkedOption3}
                    onCheckedOption3={(v) => setCheckedOption3(v)}

                    checkedPractice={checkedPractice}
                    onCheckedPractice={(v)=> setCheckedPractice(v)}

                    checkedIAmSeeking={checkedIAmSeeking}
                    onCheckedIAmSeeking={(v) => setCheckedIAmSeeking(v)}

                    receivedDateValue={receivedDateValue}
                    onReceivedDateValue={(v) => setReceivedDateValue(v)}

                    vaccinatedDateValue={vaccinatedDateValue}
                    onVaccinatedDateValue={(v) => setVaccinatedDateValue(v)}

                    photoDataBeingVaccinated={photoDataBeingVaccinated}
                    onPhotoDataBeingVaccinated={(v)=> setPhotoDataBeingVaccinated(v)}

                    photoURLBeingVaccinated={photoURLBeingVaccinated}
                    onPhotoURLBeingVaccinated={(v)=> setPhotoURLBeingVaccinated(v)}

                    photoDataFullyVaccinated={photoDataFullyVaccinated}
                    onPhotoDataFullyVaccinated={(v)=>setPhotoDataFullyVaccinated(v)}

                    photoURLFullyVaccinated={photoURLFullyVaccinated}
                    onPhotoURLFullyVaccinated={(v)=>setPhotoURLFullyVaccinated(v)}

                    photoDataDoctorNote={photoDataDoctorNote}
                    onPhotoDataDoctorNote={(v)=>setPhotoDataDoctorNote(v)}

                    photoURLDoctorNote={photoURLDoctorNote}
                    onPhotoURLDoctorNote={(v)=>setPhotoURLDoctorNote(v)}
                    
                />

                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    disabled={!_isModelValid}
                                    title={!_isModelValid ? "Please accept and sign the concents" : "Click to submit"}
                                    onClick={() => onSubmitClicked()}>
                                    Next
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonToast
                    position="middle"
                    header="Saved"
                    color="dark"
                    isOpen={showSubmitToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="Concent Saving, Please wait"
                    duration={1000}
                />
            </IonContent>
        </IonPage>
    )
}

export default VaccinationDetails;