export const atWillEmploymentEN = {
    title: "ACKNOWLEDGEMENT OF AT-WILL EMPLOYMENT & TEMPORARY NATURE OF ASSIGNMENT",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "I ###name### understand that my employment with Hirebase is at will. This means my employment is for an indefinite period of time and it is subject to termination by myself or Hirebase, with or without cause, with or without notice, and at any time.  I understand that nothing in this policy or any other policy of Hirebase shall be interpreted to be in conflict with or to eliminate or modify in any way, the At-Will Employment status of Hirebase employees."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No one other than your SBS Supervisor, President or the Chairman of the Board of Directors for SBS has the authority to alter this agreement, to enter into an agreement for employment for a specified period of time, or to make any agreement contrary to this policy. Any such agreement must be in writing and must be signed by both the Executive Director and you, the employee."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that no facts or circumstances arising out of my employment, including acceptance or employment, length or employment or any SBS	 policy, procedure or practice whether written or verbal including wage increases, promotions, performance reviews, representations, oral or implied agreements can alter the employment-at-will relationship."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "By my signature below, I acknowledge my understanding that my employment with Hirebase is at-will, and that nothing in this handbook is intended to constitute a contract of employment, express or implied."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Furthermore, I, ###name###, understand and acknowledge that my employment with Hirebase. is and will always be on a TEMPORARY ASSIGNMENT basis. Temporary Assignment means that the assignment does not have a set or defined duration and may terminate at any time with or without advanced notice. Not unless, the customer decides to employ you. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I have read and understood the foregoing and have affixed my signature below confirming the same."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        }
    ]
}


export const atWillEmploymentSP = {
    title: "RECONOCIMIENTO DE LA NATURALEZA DE ASIGNACIÓN DE EMPLEO Y NATURALEZA TEMPORAL DE LA ASIGNACIÓN",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Yo ###name### entiendo que mi empleo con Hirebase es a voluntad. Esto significa que mi empleo es por un período de tiempo indefinido y está sujeto a terminación por mí mismo o Hirebase, con o sin causa, con o sin previo aviso, y en cualquier momento.  Entiendo que nada en esta política o cualquier otra política de Hirebase se interpretará que está en conflicto con o para eliminar o modificar de ninguna manera, el estatus de empleo a voluntad de los empleados de Hirebase."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Mi estatus laboral a voluntad como empleado de Hirebase puede ser modificado sólo en un acuerdo de empleo por escrito conmigo, que también está firmado por el Presidente, o el CEO, de Hirebase."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Con mi firma a continuación, reconozco mi entendimiento de que mi empleo con Hirebase es a voluntad, y que nada en este manual pretende constituir un contrato de trabajo, expreso o implícito."
        },
        {
            tag: "p",
            class: "text-justify",
            data: ""
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Además, yo, ###name###, entiendo y reconosco que mi empleo con Hirebase. es y siempre será sobre una base DE ASIGNACION TEMPORAL. Asignación temporal significa que la asignación no tiene una duración establecida o definida y puede terminar en cualquier momento con o sin previo aviso. No a menos que el cliente decida emplearte."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "He leído y entendido lo anterior y he colocado mi firma a continuación confirmando lo mismo. "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        }
    ]
}