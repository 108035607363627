export const locationsData = [
    {
        text: "COMPTON",
        value: "63A33046-0AAE-4778-9781-81D2056027C5"
    },
    {
        text: "ONTARIO",
        value: "75699A06-519B-44F5-9321-1EF277908515"
    },
    {
        text: "BUENA PARK",
        value: "838EB11A-4A9E-4C97-8C38-F369B869CB21"
    },
    {
        text: "VAN NUYS",
        value: "93FEBF82-ECF0-4F08-9453-579B05186536"
    },
    {
        text: "CITY OF INDUSTRY",
        value: "9AD45711-0810-43CA-B3D0-3EF9DF446CC2"
    },
    {
        text: "CORONA",
        value: "AE145F43-CAF9-4ED6-AF4B-13B25EEF361B"
    },
    {
        text: "BELL",
        value: "B1F891D5-DF5A-4A6A-9D7B-5C755B36F02A"
    },
    {
        text: "TORRANCE",
        value: "D164BF6C-421C-4B16-91D4-44EB3F94EEC0"
    },
    {
        text: "BELLFLOWER",
        value: "EF4F92BD-875E-471E-B513-FF86C84B49A8"
    },
    {
        text: "NORWALK",
        value: "FB9EFC9E-1613-4DAB-B548-49535230E1F1"
    }
];


export const _getLocationText = (id) => {
    const loc = locationsData.find(l => l.value === id);
    if (loc && loc.text) {
        return loc.text;
    }
    return "";
};