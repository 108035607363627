export const drugScreeningEN = {
    title: "CONSENT AND RELEASE FOR EMPLOYMENT DRUG SCREENING",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "As a condition to my employment at Hirebase, I agree to submit to a urine drug test, also known as a urinalysis. The purpose of this urinalysis is to determine the use of controlled substances in my body."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that the results of this urinalysis, if confirmed positive, may remove me from consideration for employment at Hirebase. A positive test indicates the presence of marijuana, cocaine, opiates, amphetamines, and/or phencyclidine."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "For the sole purpose of this urinalysis, I authorize my Employer’s Authorized Agents to collect samples of my urine, and to use these samples or to forward these samples to a testing laboratory chosen by Hirebase for analysis. I also authorize these results to be reviewed by a Medical Review Officer (MRO)."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I further understand that if I am taking prescription drugs approved by a medical physician, I am encouraged to furnish said prescription to an agent of the testing laboratory prior to the collection of my urine sample. I have carefully read the foregoing, and I fully understand its contents. I agree that my signing of this Consent and Release for Employment Drug Screening is voluntary, and that I have not been coerced into signing this document."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        }
    ]
}


export const drugScreeningSP = {
    title: "CONSENTIMIENTO Y LIBERACIÓN PARA EXAMEN DE DROGAS DE EMPLEO",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Como condición para mi empleo en Hirebase, acepto someterme a una prueba de drogas de orina, también conocida como análisis de orina. El propósito de este análisis de orina es determinar el uso de sustancias controladas en mi cuerpo."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que los resultados de este análisis de orina, si se confirma positivo, pueden sacarme de la consideración para el empleo en Hirebase. Una prueba positiva indica la presencia de marihuana, cocaína, opiáceos, anfetaminas y/o fenciclidina."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Con el único propósito de este análisis de orina, autorizo a los Agentes Autorizados de mi Empleador a recoger muestras de mi orina, y a usar estas muestras o reenviar estas muestras a un laboratorio de pruebas elegido por Hirebase para su análisis. También autorizo que estos resultados sean revisados por un Oficial de Revisión Médica (MRO)."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Además entiendo que si estoy tomando medicamentos recetados aprobados por un médico, me siento alentado a proporcionar dicha receta a un agente del laboratorio de pruebas antes de la recolección de mi muestra de orina. He leído atentamente lo anterior, y entiendo perfectamente su contenido. Estoy de acuerdo en que mi firma de este Consentimiento y Liberación para la Detección de Drogas en el Empleo es voluntaria, y que no he sido forzado a firmar este documento."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        }
    ]
}