import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { trash, trashSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function SignatureInput(props) {

    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(200);
    const [signature, setSignature] = useState(props.signature);
    const [color, setColor] = useState("blue");
    const [edit, setEdit] = useState(false);

    const signatureCanvas = useRef({});

    const canvasContainerRef = useRef({});

    useEffect(() => {
        setWidth(canvasContainerRef.current.offsetWidth);
        setHeight(canvasContainerRef.current.offsetHeight);
    });

    useEffect(() => {
        setSignature(props.signature);
        if (props.signature && !edit) {
            signatureCanvas.current.clear();
            signatureCanvas.current.fromDataURL(props.signature);
        }
    }, [props.signature]);

    // useEffect(() => {
    //     signatureCanvas.current.clear();
    //     signatureCanvas.current.fromDataURL(signature);
    // }, [signature]);

    const clearCanvas = () => {
        signatureCanvas.current.clear();
        setSignature("");
        props.onClear && props.onClear("");
    };

    const onChange = (e) => {
        setEdit(true);
        setSignature(signatureCanvas.current.toDataURL());
        props.onChange && props.onChange(e, signatureCanvas.current.toDataURL());
    };

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <div className="signature-canvas-container" ref={canvasContainerRef}>
                            <SignatureCanvas
                                ref={signatureCanvas}
                                canvasProps={{ width: width, height: height, className: 'signature-canvas' }}
                                penColor={color}
                                onEnd={(e) => onChange(e)} />
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonGrid>
                <IonRow className="ion-align-items-center">
                    <IonCol>
                        <IonSelect placeholder={props.placeholder ? props.placeholder : "Select Pen Color"} className="color-picker"
                            interface="action-sheet" onIonChange={(e) => setColor(e.target.value)}>
                            <IonSelectOption value="red">Red</IonSelectOption>
                            <IonSelectOption value="blue">Blue</IonSelectOption>
                            <IonSelectOption value="black">Black</IonSelectOption>
                        </IonSelect>
                    </IonCol>
                    <IonCol>
                        <IonButton color="danger" onClick={() => clearCanvas()}>
                            <IonIcon slot="start" src={trash} md={trashSharp}></IonIcon> Clear
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
}

export default SignatureInput;