export const employeeSafetyEN = {
    title: "EMPLOYEE SAFETY ORIENTATION SIGN-OFF SHEET",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Each employee must understand that working in a safe manner is an employment requirement and is expected. Short cuts, unsafe acts practices will not be tolerated."
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "ALL injuries (or events which may have caused an injury) must be reported immediately to the job supervisor. Work related injuries must be treated by an industrial injury clinic selected by Hirebase.",
                "I accept and will implement the Company Injury and Illness Prevention Program (IIPP).",
                "I have reviewed and understand and will observe all Company’s Safety rules. I will also observe the rules established by Company’s clients.",
                "I will observe OSHA safety rules and procedures.",
                "Before beginning each work assignment day, I will review all required personal protective equipment.",
                "As # 5 above, I will review and wear appropriate clothing (ankle length pants) and footwear (ankle high lace boots with thick non‐skid soles) and when required, steel toed shoes.",
                "Appropriate accident reporting procedures have been discussed.",
                "Any person under the influence of alcohol or drugs will not be allowed on the job. He or she may or will be terminated. Refusal to take an alcohol or drug test may result in termination.",
                "I will immediately report any unsafe conditions to the job supervisor.",
                "I understand the written IIPP is ready for my review if I would like to do so."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that safety is important, and I accept the Company Injury and Illness Prevention Program (IIPP). I have reviewed the copies of the above‐mentioned information and understand that this information is immediately available to me if I need a copy. I also understand that it is my responsibility to fully review and adhere to these policies. I also understand that if I have any questions these may be discussed with my supervisor."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand and have read the above regarding the Safety Orientation Policy and I understand that further details regarding said policy are included in the Employee Handbook."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee’s Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        }
    ]
}


export const employeeSafetySP = {
    title: "HOJA DE FIRMA DE ORIENTACIÓN DE SEGURIDAD DE LOS EMPLEADOS",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Cada empleado debe entender que trabajar de manera segura es un requisito de empleo y se espera. No se tolerarán atajos, actos inseguros no serán tolerados."
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "TODAS las lesiones (o eventos que puedan haber causado una lesión) deben ser reportadas inmediatamente al supervisor de empleo. Las lesiones relacionadas con el trabajo deben ser tratadas por una clínica de lesiones industriales seleccionada por Hirebase.",
                "Acepto e implementaré el Programa de Prevención de Lesiones y Enfermedades de la Compañía (IIPP, por sus siglas en inglés).",
                "Acepto e implementaré el Programa de Prevención de Lesiones y Enfermedades de la Compañía (IIPP, por sus siglas en inglés).",
                "Observaré las normas y procedimientos de seguridad de la OSHA.",
                "Antes de comenzar cada día de asignación de trabajo, revisaré todo el equipo de protección  personal requerido.",
                "Como 5 arriba, revisaré y usaré ropa apropiada (pantalones de longitud de tobillo) y calzado (botas de encaje alto en el tobillo con suelas gruesas antideslizantes) y cuando sea necesario, zapatos de punta de acero.",
                "Se han repasado los procedimientos adecuados de notificación de accidentes.",
                "Cualquier persona bajo la influencia de alcohol o drogas no será permitida en el trabajo. Puede o terminará. La negativa a tomar una prueba de alcohol o drogas puede resultar en la terminación.",
                "Reportaré inmediatamente cualquier condición insegura al supervisor de empleo.",
                "Entiendo que el IIPP escrito está listo para mi revisión si me gustaría hacerlo."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que la seguridad es importante, y acepto el Programa de Prevención de Lesiones y Enfermedades de la Compañía (IIPP, por sus siglas en inglés). He revisado las copias de la información antes mencionada y entiendo que esta información está inmediatamente disponible para mí si necesito una copia. También entiendo que es mi responsabilidad revisar y adherirme plenamente a estas políticas. También entiendo que si tengo alguna pregunta estos pueden ser discutidos con mi supervisor"
        },
        {
            tag: "p",
            class: "text-justify",
            data: ""
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        }
    ]
}