import { IonButton, IonIcon } from '@ionic/react';
import { shieldCheckmark } from 'ionicons/icons';
import React from 'react';

function SignButton(props) {
    return (
        <IonButton
            // expand="block"
            fill="solid"
            color="danger"
            onClick={(e) => props.onSignButtonClicked && props.onSignButtonClicked(e)}>
            <IonIcon slot="start" icon={shieldCheckmark}></IonIcon>
            {props.text ? props.text : "Sign"}
        </IonButton>
    );
}

export default SignButton;