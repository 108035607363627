export const covidEmergencyTreatmentEN = {
    title: "COVID-19 Emergency Treatment Employee Consent Form",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "As the coronavirus (COVID-19) continues to spread, Hirebase wants to ensure that you are aware of what steps we are taking to protect both you as an employee as well as our patients."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "In order to prevent spread of COVID-19, please ensure that you follow the guidance listed below:"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: <ul>
                <li>Cover your mouth and nose with a tissue or your sleeve (not your hands) when you cough or sneeze;</li>
                <li>Throw all used tissues in the trash right away and wash your hands immediately after handling used tissues;</li>
                <li>Avoid touching your eyes, nose or mouth;</li>
                <li>Avoid close contact with people who are sick; and</li>
                <li>Clean and disinfect frequently touched objects and surfaces, such as your keyboard or mouse, using a regular household cleaning spray or wipe.</li>
            </ul>
        },
        {
            tag: "p",
            class: "text-justify",
            data: "In addition, Hirebase will continue to follow all federal, state and local regulations including, but not limited to, the CDC and OSHA to protect employees during this time. We have provided facial masks, hand sanitizer, antibacterial cleansers at convenient locations throughout the facility; signs are posted to encourage Social Distancing; masks are required at all work locations."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I ###name### (Employee Name) understand that the symptoms listed below are representative of COVID-19:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: <ul>
                <li>Fever</li>
                <li>Dry Cough</li>
                <li>Shortness of Breath</li>
                <li>Temperature</li>
                <li>Persistent pain or pressure in the chest</li>
                <li>Bluish lips or face</li>
            </ul>
        },
        {
            tag: "p",
            class: "text-justify",
            data: <>
                I understand that all travelers arriving from a country or region with <a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/map-and-travel-notices.html" target="_blank" rel="noreferrer">widespread ongoing transmission, as
                    outlined by the CDC</a>, should get Covid-19 tested and stay home for 14 days to practice social distancing
                and monitor their health after their arrival.
            </>
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "I confirm that I do not display or currently have any of the symptoms that are representative of COVID- 19, which are outlined above:",
                "I confirm that if I display any of these symptoms, I will be sent home immediately.",
                "I confirm that my employer has the right to screen me for symptoms prior to every shift to protect patients and other employees from the spread of COVID-19.",
                <>I confirm that I have not traveled to any of the countries or regions with widespread ongoing transmission (<a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/map-and-travel-notices.html" target="_blank" rel="noreferrer">Level 3 Travel Health Notice</a>) in the past 14 days.</>,
                "I confirm that I have not traveled out of the United States of America in the past 14 days ",
                "I confirm, to the best of my knowledge, that I have not had close contact with an individual diagnosed with COVID-19 in the past 14 days.",
                "I ###name###, (the employee), consent to providing emergency treatment to patients in need during the COVID-19 outbreak.",
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that based on what is currently known about COVID-19, the spread is thought to occur mostly from person-to-person via respiratory droplets among close contacts. I understand that close contact can occur from being within approximately 6 feet of someone with COVID-19 for a prolonged period of time or by having direct contact with infectious secretions from someone with COVID-19."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that due to the unknowns of this virus, the number of employees and customers and vendors that come and go in this company and the nature of the work performed here, that I may have an increased risk of contracting the virus by working."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that I need to do my part and maintain Social Distancing, which is a minimum of 6 feet in between myself and any other employee, customer, vendor, or other individual."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that I need to wear a face mask covering my nose and mouth at all times while working at Hirebase."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I understand that Hirebase is doing everything possible to stop the spread and Hirebase needs my help as an employee to minimize my risks. Should I fail to follow the above guidelines, I understand I am taking the very serious risk of catching Covid-19."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employer Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        }
    ]
}


export const covidEmergencyTreatmentSP = {
    title: "COVID-19 Formulario de Consentimiento de Empleado para Tratamiento de Emergencia",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "A medida que el coronavirus (COVID-19) continúa propagándose, Hirebase quiere asegurarse de que esté al tanto de los pasos que estamos tomando para protegerlo a usted como empleado y a nuestros pacientes."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Para evitar la propagación de COVID-19, asegúrese de seguir las instrucciones que se detallan a continuación:"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: <ul>
                <li>Cúbrase la boca y la nariz con un pañuelo desechable o la manga (no las manos) cuando tosa o estornude;</li>
                <li>Tire todos los pañuelos usados a la basura de inmediato y lávese las manos inmediatamente después de manipular pañuelos usados;</li>
                <li>Evite tocarse los ojos, la nariz o la boca;</li>
                <li>Evite el contacto cercano con personas enfermas; y</li>
                <li>Limpie y desinfecte objetos y superficies que se tocan con frecuencia, como el teclado o el ratón de computadora, con un spray o toallita de limpieza doméstica.</li>
            </ul>
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Además, Hirebase seguirá cumpliendo con todas las regulaciones federales, estatales y locales, incluidos, entre otros, los CDC y OSHA para proteger a los empleados durante este tiempo.Hemos proporcionado máscaras faciales, desinfectante para manos, limpiadores antibacterianos en lugares convenientes en todas las instalaciones; se colocan letreros para alentar el distanciamiento social; Se requieren máscaras en todos los lugares de trabajo."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Yo ###name###(Nombre del empleado) entiendo que los síntomas enumerados a continuación son representativos de COVID- 19:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: <ul>
                <li>Fiebre</li>
                <li>Tos seca</li>
                <li>Falta de aliento</li>
                <li>Temperatura</li>
                <li>Dolor o presión persistentes en el pecho</li>
                <li>Labios o cara azulada</li>
            </ul>
        },
        {
            tag: "p",
            class: "text-justify",
            data: <>
                Entiendo que todos los viajeros que lleguen de un país o región con una <a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/map-and-travel-notices.html" target="_blank" rel="noreferrer">transmisión en curso generalizada, como lo describen los CDC</a>, deben hacerse la prueba de Covid-19 y quedarse en casa durante 14 días para practicar el distanciamiento social y controlar su salud después de su llegada.
            </>
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Confirmo que no visualizo o que actualmente no tengo ninguno de los síntomas que son representativos de COVID-19, que se describen arriba:",
                "Confirmo que, si muestro alguno de estos síntomas, me enviarán a casa de inmediato:",
                "Confirmo que mi empleador tiene el derecho de detectarme los síntomas antes de cada turno para proteger a los pacientes y otros empleados de la propagación de COVID-19:",
                <>Confirmo que no he viajado a ninguno de los países o regiones con transmisión generalizada en curso (<a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/map-and-travel-notices.html" target="_blank" rel="noreferrer">Aviso de salud de viaje de nivel 3</a>) en los últimos 14 días:</>,
                "Confirmo que no he viajado fuera de los Estados Unidos de América en los últimos 14 días:",
                "Confirmo, que yo sepa, que no he tenido contacto cercano con una persona diagnosticada con COVID-19 en los últimos 14 días:",
                "Yo ###name###, (el empleado), doy mi consentimiento para proporcionar tratamiento de emergencia a pacientes necesitados durante el brote de COVID-19.",
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que, según lo que se sabe actualmente sobre COVID - 19, se cree que la propagación ocurre principalmente de persona a persona a través de gotitas respiratorias entre contactos cercanos.Entiendo que el contacto cercano puede ocurrir por estar dentro de aproximadamente 6 pies de alguien con COVID - 19 por un período prolongado de tiempo o por tener contacto directo con secreciones infecciosas de alguien con COVID - 19."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que, debido a las incógnitas de este virus, la cantidad de empleados y clientes y proveedores que entran y salen de esta empresa y la naturaleza del trabajo realizado aquí, puedo tener un mayor riesgo de contraer el virus al trabajar."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que necesito hacer mi parte y mantener el distanciamiento social, que es un mínimo de 6 pies entre mí y cualquier otro empleado, cliente, vendedor u otra persona."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que necesito usar una máscara facial que cubra mi nariz y boca en todo momento mientras trabajo en Hirebase."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Entiendo que Hirebase está haciendo todo lo posible para detener la propagación y Hirebase necesita mi ayuda como empleado para minimizar mis riesgos. Si no sigo las pautas anteriores, entiendo que estoy asumiendo el riesgo muy grave de contraer Covid-19."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre de Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase:"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del empleador"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        }
    ]
}