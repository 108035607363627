export const companyPoliciesEN = {
    title: "Reminder of Company Policies",
    content: [
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Punctuality-Employees must be on time to their assigned shift",
                "Attendance/Job Abandonment-Employees must be present for their entire scheduled shift. In the event any employee leaves their job post without advising their supervisor Hirebase will consider this as job abandonment.",
                "Time Clock/Time Keeping-Employees are solely responsible for keeping track of their time punching four (4) times daily.",
                "Uniform/PPE-Employees must wear proper work attire and wear all PPE assigned at the workplace. Due to Covid-19 wearing your face mask is part of the uniform. All employees must wear face masks properly.",
                "No Call No Shows-Employees must advise their employer (Hirebase) a day before or at minimum 2 hours before their shift that they will not be showing up for work if an event is to occur and explanation is required."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "If employees do not report any absences and or do not comply with all company policies of job location and those in place at Hirebase it may result with the following"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Documentation for Verbal Warning",
                "Documentation of Written Warning",
                "Documentation of Final Warning and/or suspension/termination"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "We appreciate your hard work and dedication."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "If you have any feedback or anything you would like to report please visit the office nearest you."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Please stay safe and healthy! "
        },
        {
            tag: "p",
            class: "",
            data: "Employee Name"
        },
        {
            tag: "p",
            class: "",
            data: "Employee Signature"
        },
        {
            tag: "p",
            class: "",
            data: "Date"
        }
    ]
}


export const companyPoliciesSP = {
    title: "Recordatorio de Polizas de la Compania",
    content: [
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Puntualidad- Deben llegar a tiempo a su turno asignado",
                "Asistencia de Trabajo-Deben estar presentes durante todo su turno asignado. En el evento que qualquier empleado/empleada abandono su puesto de trabajo sin avisar a su supervisor, Hirebase lo considerara como abandono de trabajo.",
                "Reloj /Mantenimiento de Tiempo-Todo empleado/a es responsable de llevar un registro de su tiempo marcando cuatro (4) veces al día sus entradas y salidas de trabajo",
                "Uniforme/PPE- Deben llevar el uniforme de trabajo adecuado y usar todos los accesorios de proteccion asignados en el lugar de trabajo. Debido a Covid-19 usar su máscara facial es parte del uniforme. Todos los empleados deben usar su mascara edecuadamente.",
                "Falta de Trabajo Sin Aviso-Deben informar a su empleador (Hirebase) un día antes mínimo 2 horas antes de su turno que no se presentarán para trabajar con explicacion"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Si los empleados/empleadas no reportan ninguna ausencia y no cumplen con todas las polízas de la Compania donde fueron asignado/a y las de Hirebase podra resultar con lo siguiente"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Documentación para la advertencia verbal",
                "Documentación de la advertencia escrita",
                "Documentación de advertencia final y/o suspensión/terminación"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Agradecemos sus esfuerzos y dedicación."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Si tiene algún comentario o cualquier cosa que le gustaría informar de qualquier situacion por favor visite la oficina más cercana a usted."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Por favor, manténgase seguros/seguras y saludable! "
        },
        {
            tag: "p",
            class: "",
            data: "Nombre Completo"
        },
        {
            tag: "p",
            class: "",
            data: "Firma"
        },
        {
            tag: "p",
            class: "",
            data: "Fecha"
        }
    ]
}