import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../app/api/client';
import { getAxiosRequestConfig } from '../../../app/common/common';
import { isJSON } from '../../../app/utilities/utilityFunctions';

const initialState = {
    safetyTestDetail: {
        data: undefined,
        status: fetchStatus.IDLE,
        error: null
    },
    safetyTestResult: {
        data: undefined,
        status: fetchStatus.IDLE,
        error: null
    },
};

export const getAllQuestions = createAsyncThunk('safetyTest/getAllQuestions', async (safetyTestModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', safetyTestModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let questions;
    if (isJSON(data)) {
        questions = JSON.parse(data);
    }
    return questions;
});

export const saveDocument = createAsyncThunk('reviewers/saveDocument', async (safetyTestModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, safetyTestModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let reviewer = undefined;
        if (data && isJSON(data)) {
            reviewer = JSON.parse(data);
        }
        return {
            reviewer: reviewer,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveSafetyTestResponses = createAsyncThunk('safetyTest/saveSafetyTestResponses', async (safetyTestModel, { rejectWithValue }) => {
    try {
            const response = await BajoAPI.post(`Gateway`, safetyTestModel, getAxiosRequestConfig());
            const data = response.data ? response.data.data : undefined;
            let applicant = undefined;

            return {
                data: data,
                success: response.data.success
            };
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
});

export const safetyTestSlice = createSlice({
    name: 'safetyTest',
    initialState,
    reducers: {
        updateSafetyTestDetail: (state, action) => {
            if (action.payload) {
                state.safetyTestDetail.data = Object.assign({}, action.payload);
            }
        },
        updateSafetyTestResultStatus: (state) => {
           
            state.safetyTestResult.status =fetchStatus.IDLE;
            state.safetyTestResult.data = "";
          
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQuestions.pending, (state, action) => {
            state.safetyTestDetail.status = fetchStatus.LOADING;
        }).addCase(getAllQuestions.fulfilled, (state, action) => {
            state.safetyTestDetail.data = action.payload;
            state.safetyTestDetail.status = fetchStatus.SUCCEEDED;
        }).addCase(getAllQuestions.rejected, (state, action) => {
            state.safetyTestDetail.status = fetchStatus.FAILED;
            state.safetyTestDetail.error = action.error.message;
        }).addCase(saveSafetyTestResponses.pending, (state, action) => {
            state.safetyTestResult.status = fetchStatus.LOADING;
        }).addCase(saveSafetyTestResponses.fulfilled, (state, action) => {
            state.safetyTestResult.data = action.payload.data;
            state.safetyTestResult.status = fetchStatus.SUCCEEDED;
        }).addCase(saveSafetyTestResponses.rejected, (state, action) => {
            state.safetyTestResult.status = fetchStatus.FAILED;
            state.safetyTestResult.error = action.error.message;
        });
    }
});

export const { updateSafetyTestDetail, updateSafetyTestResultStatus } = safetyTestSlice.actions;

export default safetyTestSlice.reducer

export const selectSafetyTestDetail = state => state.safetyTest.safetyTestDetail.data;

export const selectSafetyTestDetailStatus = state => state.safetyTest.safetyTestDetail.status;

export const selectSafetyTestDetailError = state => state.safetyTest.safetyTestDetail.error;


export const selectSafetyTestResult = state => state.safetyTest.safetyTestResult.data;

export const selectSafetyTestResultStatus = state => state.safetyTest.safetyTestResult.status;

export const selectSafetyTestResultError = state => state.safetyTest.safetyTestResult.error;