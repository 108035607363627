import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { noticeToEmployeeEN, noticeToEmployeeSP } from './services/noticeToEmployee';
import './css/concent.css';
import './css/noticeToEmployee.css';

function NoticeToEmployee(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(noticeToEmployeeEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(noticeToEmployeeSP);
        }
        else {
            setConcent(noticeToEmployeeEN);
        }
    }, [language]);

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>
                    <span>{concent.title}</span>
                    <span className="laber-code-section">{concent.content[0].data}</span>
                </IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonRow>
                    <IonCol>
                        <p className="sub-title">
                            {concent.content[1].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[2].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>
                    </IonCol>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[3].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="sub-title">
                            {concent.content[4].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[5].data}
                        </p>
                        <p>
                            Hirebase
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[6].data}
                        </p>
                        <p>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i>{concent.content[7].data}</span>
                            <span><i class="fa fa-square-o mr-2" aria-hidden="true"></i>{concent.content[8].data}</span>
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[9].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[10].data}
                        </p>
                        <p>
                            {props.companyAddress}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[11].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[12].data}
                        </p>
                        <p>
                            {props.employerPhone}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[13].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[14].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[15].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[16].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[17].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="sub-title">
                            {concent.content[18].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[19].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[20].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[21].data}
                        </p>
                        <p>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[22].data}</span>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[23].data}</span>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[24].data}</span>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[25].data}</span>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[26].data}</span>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[27].data}</span>
                            <span><i class="fa fa-square-o mr-2" aria-hidden="true"></i> {concent.content[28].data}</span>
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[29].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[30].data}
                        </p>
                        <p>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i>{concent.content[31].data}</span>
                            <span><i class="fa fa-square-o mr-2" aria-hidden="true"></i>{concent.content[32].data}</span>
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[33].data}
                        </p>
                        <p>
                            <span className="mr-2"><i class="fa fa-square-o mr-2" aria-hidden="true"></i>{concent.content[34].data}</span>
                            <span><i class="fa fa-square-o mr-2" aria-hidden="true"></i>{concent.content[35].data}</span>
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[36].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[37].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[38].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="sub-title">
                            {concent.content[39].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[40].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[41].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[42].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[43].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[44].data}
                        </p>
                        <p className="underline">

                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="sub-title">
                            {concent.content[45].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[46].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ol class="lower-alpha-list-style">
                            <li>
                                {concent.content[47].data[0]}
                            </li>
                            <li>{concent.content[47].data[1]}</li>
                            <li>
                                <div>
                                    <span>{concent.content[47].data[2]}</span>
                                    <ol>
                                        <li>{concent.content[48].data[0]}</li>
                                        <li>{concent.content[48].data[1]}</li>
                                        <li>{concent.content[48].data[2]}</li>
                                        <li>{concent.content[48].data[3]}</li>
                                    </ol>
                                </div>
                            </li>
                        </ol>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>{concent.content[49].data}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ol>
                            <li>{concent.content[50].data[0]}</li>
                            <li>{concent.content[50].data[1]}</li>
                            <li>{concent.content[50].data[2]}</li>
                            <li>{concent.content[50].data[3]}</li>
                        </ol>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p class="sub-title">{concent.content[51].data}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p class="concent-label">{concent.content[52].data}</p>

                        <p class="concent-label-value">{props.reviewerName}</p>
                    </IonCol>

                    <IonCol>
                        <p class="concent-label">{concent.content[53].data}</p>

                        <p class="concent-label-value">{props.name}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p class="concent-label">{concent.content[54].data}</p>

                        <IonImg src={props.reviewerSignature} className="person-signature" />
                    </IonCol>

                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[55].data}
                        </p>
                        <ApplySign
                            buttonText={language && language == "Spanish" ? "Firma" : "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p class="concent-label">{concent.content[56].data}</p>

                        <p class="concent-label-value"> {(new Date()).toDateString()}</p>
                    </IonCol>

                    <IonCol>
                        <p class="concent-label">{concent.content[57].data}</p>

                        <p class="concent-label-value"> {(new Date()).toDateString()}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>{concent.content[58].data}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>{concent.content[59].data}</p>

                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    );
}

export default NoticeToEmployee;