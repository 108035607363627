import { IonButton, IonCheckbox,IonRadioGroup,IonList,  IonListHeader, IonRadio, IonToggle , IonToast, IonButtons, IonCard, IonCardContent, IonCardHeader, IonDatetime, IonCardSubtitle, IonBadge, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { pin, wifi, wine, warning, walk, call, mail, cloudUpload, camera, location, informationCircle, trashSharp, trash } from 'ionicons/icons';
import './uploadDetails.css';
import nophoto from '../assets/nophoto.png';
import { usePhoto } from '../app/hooks/usePhoto';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
    getApplicantById,
    selectSingleStatus as applicantStatus,
    selectApplicantById,
    updateApplicant,
    updateSingleStatus,
    updateModificationStatus,
    selectModificationStatus,
    selectSignature,
    selectIntial
} from './applicant/reducerSlices/applicantSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../app/api/client';
import '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Language } from '../app/common/constants';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { useCamera } from '@ionic/react-hooks/camera';
import { selectLoggedInUser } from './accounts/reducerSlices/accountsSlice';
import EllipsisLoader from '../components/loaders/ellipsisLoader';
import SignatureCanvas from 'react-signature-canvas';
import SignatureContainer from '../components/containers/signatureContainer';
import SignatureInput from '../components/controls/signatureInput';
import { _getLocationText } from '../data/masterData';
import GridLoader from '../components/loaders/gridLoader';
import { Offline, Online, Detector } from "react-detect-offline";



const WorkExperience = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id, lang } = props.match.params;
  
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    const user = useSelector(selectLoggedInUser);
    const [showSubmitToast, setShowSubmitToast] = useState(false);

    const applicant = useSelector((state) => selectApplicantById(state, id));
    const applicantRequestStatus = useSelector(applicantStatus);
    const applicantModificationRequestStatus = useSelector(selectModificationStatus);
    const signatureData = useSelector(selectSignature);
    const initialData = useSelector(selectIntial);

    const { getPhoto } = useCamera();
    const [language, setLanguage] = useState("");
    const [initial, setInitial] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [locationVal, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const [photoURL, setPhotoURL] = useState("");
    const [signature, setSignature] = useState("");

    const [chkGenState, setChkGenState] = useState(false);
    const [chkExpType, setChkExpType] = useState(false);
    const [genValue, setGenValue] = useState("");
    const [chkShippingState, setChkShippingState] = useState(false);
    const [shippingValue, setShippingValue] = useState("");
    const [chkPackagingState, setChkPackagingState] = useState(false);
    const [packagingValue, setPackagingValue] = useState("");
    const [chkIndustrialState, setChkIndustrialState] = useState(false);
    const [industrialValue, setIndustrialValue] = useState("");
    const [chkRFState, setChkRFState] = useState(false);
    const [RFValue, setRFValue] = useState("");
    const [chkAssemblyState, setChkAssemblyState] = useState(false);
    const [assemblyValue, setAssemblyValue] = useState("");
    const [chkFoodState, setChkFoodState] = useState(false);
    const [foodValue, setFoodValue] = useState("");
    const [chkMachineState, setChkMachineState] = useState(false);
    const [machineValue, setMachineValue] = useState("");
    const [machineOperated, setMachineOperatedValue] = useState("");
    const [chkSupervisorState, setChkSupervisorState] = useState(false);
    const [supervisorValue, setSupervisorValue] = useState("");
    const [chkSecurityState, setChkSecurityState] = useState(false);
    const [securityValue, setSecurityValue] = useState("");
    const [chkForkliftState, setChkForkliftState] = useState(false);
    const [forkliftValue, setForkliftValue] = useState("");
    const [chkMaintenanceState, setChkMaintenanceState] = useState(false);
    const [maintenanceValue, setMaintenanceValue] = useState("");
    const [chkElectricianState, setChkElectricianState] = useState(false);
    const [electricianValue, setElectricianValue] = useState("");
    const [chkSanitationState, setChkSanitationState] = useState(false);
    const [sanitationValue, setSanitationValue] = useState("");
    const [chkQualityState, setChkQualityState] = useState(false);
    const [qualityValue, setQualityValue] = useState("");
    const [chkComputerState, setChkComputerState] = useState(false);
    const [computerValue, setComputerValue] = useState("");
    
    const [forkliftType, setForkliftType] = useState([]);
    const [securityType , setSecurityType] = useState([]);
    const [computerType, setComputerType] = useState([]);

    const [LastWorkPlaceValue , setLastWorkPlaceValue ] = useState("");
    const [KnowAboutUsValue , setKnowAboutUsValue ] = useState("");
    const [chkHOMIState , setchkHOMIState ] = useState(false);
    const [MedicalInsuranceTypeValue , setMedicalInsuranceTypeValue ] = useState("");
    const [chkASAPOState , setchkASAPOState ] = useState(false);
    const [ASAPOAvailableDateValue , setASAPOAvailableDateValue ] = useState("");
    const [chkAFOTState , setchkAFOTState ] = useState(false);
    const [chkWCOCState , setchkWCOCState ] = useState(false);
    const [chkFPENVState , setchkFPENVState ] = useState(false);
    const [chkSLPOState , setchkSLPOState ] = useState(false);
    const [chkCWIHState , setchkCWIHState ] = useState(false);
    const [chkREPMState , setchkREPMState ] = useState(false);
    const [chkCWCTState , setchkCWCTState ] = useState(false);
    const [chkHVLFState , setchkHVLFState ] = useState(false);
    const [chkUNWIState , setchkUNWIState ] = useState(false);
    const [chkCAMinimmumState , setchkCAMinimmumState ] = useState(false);
    const [chkTemptoHireState , setchkTemptoHireState ] = useState(false);
    const [chkAlcoholProhibitionState , setchkAlcoholProhibitionState ] = useState(false);
    const [chkHarassmentBullyingState , setchkHarassmentBullyingState ] = useState(false);
    const [chkDirectDepositState , setchkDirectDepositState ] = useState(false);
    const [chkClarifiedEmployerState , setchkClarifiedEmployerState ] = useState(false);
    const [chkWONCState , setchkWONCState ] = useState(false);
    const [chkWOTCUState , setchkWOTCUState ] = useState(false);
    const [chkWPSDState , setchkWPSDState ] = useState(false);
    const [chkOSTBState , setchkOSTBState ] = useState(false);
    const [chkOSRBState , setchkOSRBState ] = useState(false);
    const [chkWOUPState , setchkWOUPState ] = useState(false);
    const [chkMLPNState , setchkMLPNState ] = useState(false);
    const [chkODASState , setchkODASState ] = useState(false);
    const [chkCYREState , setchkCYREState ] = useState(false);
    const [chkCYWEState , setchkCYWEState ] = useState(false);
    const [chkCYWSState , setchkCYWSState ] = useState(false);
    const [chkCYRSState , setchkCYRSState ] = useState(false);
    const [chkBSASState , setchkBSASState ] = useState(false);
    const [chkAYCVState , setchkAYCVState ] = useState(false);
    const [chkCWRWState , setchkCWRWState ] = useState(false);
    const [IfNotWorkWeekendValue , setIfNotWorkWeekendValue ] = useState("");


    useEffect(() => {
        if (applicantRequestStatus === fetchStatus.IDLE) {
            dispatch(getApplicantById({
                client: tenantId,
                serviceType: "TENANTUSER",
                operation: "GETAPPLICANTFORMOBILE",
                payload: {
                    id: id,
                    data: ""
                }
            }));
        }
    }, [applicantRequestStatus, dispatch, id]);

    useEffect(() => {

        if (applicant) {
            console.log(applicant);
            let loc = _getLocationText(applicant.LocationId);
            setFirstName(applicant.FirstName);
            setLastName(applicant.LastName);

            setEmail(applicant.Email);
            setPhoneNumber(applicant.PhoneNumber);
            setLocation(loc);
            setStatus(applicant.Status);
            // setLanguage(applicant.Language);
            setLanguage(lang);
            setChkGenState(false);
            setChkExpType(false);
            setChkShippingState(false);
            setChkPackagingState(false);
            setChkIndustrialState(false);
            setChkRFState(false);
            setChkAssemblyState(false);
            setChkFoodState(false);
            setChkMachineState(false);
            setChkSupervisorState(false);
            setChkSecurityState(false);
            setChkForkliftState(false);
            setChkMaintenanceState(false);
            setChkElectricianState(false);
            setChkSanitationState(false);
            setChkQualityState(false);
            setChkComputerState(false);
            setchkHOMIState(false);
            setchkASAPOState(false);
            setchkAFOTState(false);
            setchkWCOCState(false);
            setchkFPENVState(false);
            setchkSLPOState(false);
            setchkCWIHState(false);
            setchkREPMState(false);
            setchkCWCTState(false);
            setchkHVLFState(false);
            setchkUNWIState(false);
            setchkCAMinimmumState(false);
            setchkTemptoHireState(false);
            setchkAlcoholProhibitionState(false);
            setchkHarassmentBullyingState(false);
            setchkDirectDepositState(false);
            setchkClarifiedEmployerState (false);
            setchkWONCState(false);
            setchkWOTCUState(false);
            setchkWPSDState(false);
            setchkOSTBState(false);
            setchkOSRBState(false);
            setchkWOUPState(false);
            setchkMLPNState(false);
            setchkODASState(false);
            setchkCYREState(false);
            setchkCYWEState(false);
            setchkCYWSState(false);
            setchkCYRSState(false);
            setchkBSASState(false);
            setchkAYCVState(false);
            setchkCWRWState(false);

        }

    }, [id, applicant, dispatch, initialData, signatureData]);

    useEffect(() => {
        dispatch(updateSingleStatus());
    }, [id, dispatch]);

    let isModelValid = true;

    const onNextClicked = async (e) => {
       
        if (isModelValid ) {
            try {
                await dispatch(
                    updateApplicant({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEAPPLICANTQUESTIONFORMOBILE",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                ReviewerId: user.id,
                                ApplicantId: id,
                                GeneralLabor : chkGenState,
                                GLExperience : chkExpType ? Math.round(genValue*12) : genValue,
                                ShipRec: chkShippingState,
                                SRExperience : chkExpType ? Math.round(shippingValue*12) : shippingValue,
                                PacLab : chkPackagingState,
                                PLExperience : chkExpType ? Math.round(packagingValue*12) : packagingValue,
                                IndSew : chkIndustrialState,
                                ISWExperience : chkExpType ? Math.round(industrialValue*12) : industrialValue,
                                RFScan : chkRFState,
                                RFSExperience : chkExpType ? Math.round(RFValue*12) : RFValue,
                                AssemblyLine : chkAssemblyState,
                                ALExperience : chkExpType ? Math.round(assemblyValue*12) : assemblyValue, 
                                FoodProd : chkFoodState,
                                FPExperience : chkExpType ? Math.round(foodValue*12) : foodValue,
                                MacOpe : chkMachineState, 
                                MOExperience : chkExpType ? Math.round(machineValue*12) : machineValue, 
                                MachineOperated : machineOperated,
                                ForkOpe : chkForkliftState, 
                                FOExperience : chkExpType ? Math.round(forkliftValue*12) : forkliftValue, 
                                ForkOpeType : forkliftType, 
                                SupLead : chkSupervisorState,
                                SULExperience : chkExpType ? Math.round(supervisorValue*12) : supervisorValue,
                                Security: chkSecurityState, 
                                SecExperience : chkExpType ? Math.round(securityValue*12) : securityValue, 
                                SecType : securityType,
                                MainMech : chkMaintenanceState,
                                MaMeExperience : chkExpType ? Math.round(maintenanceValue*12) : maintenanceValue,
                                Electrician : chkElectricianState,
                                ElecExperience : chkExpType ? Math.round(electricianValue*12) : electricianValue,
                                Sanitation : chkSanitationState,
                                SaniExperience : chkExpType ? Math.round(sanitationValue*12) : sanitationValue,
                                QAQC : chkQualityState,
                                QAQCExperience : chkExpType ? Math.round(qualityValue*12) : qualityValue,
                                CompKnow : chkComputerState,
                                CompKnowExperience : chkExpType ? Math.round(computerValue*12) : computerValue,
                                CompKnowType : computerType, 
                                LastWorkPlace : LastWorkPlaceValue,
                                KnowAboutUs : KnowAboutUsValue,
                                HOMI : chkHOMIState,
                                MedicalInsuranceType : MedicalInsuranceTypeValue,
                                ASAPO : chkASAPOState,
                                ASAPOAvailableDate : ASAPOAvailableDateValue,
                                AFOT : chkAFOTState,
                                WCOC : chkWCOCState,
                                FPENV : chkFPENVState,
                                SLPO : chkSLPOState,
                                CWIH : chkCWIHState,
                                REPM : chkREPMState,
                                CWCT : chkCWCTState,
                                HVLF : chkHVLFState,
                                UNWI : chkUNWIState,
                                CAMinimmum : chkCAMinimmumState,
                                TemptoHire : chkTemptoHireState,
                                AlcoholProhibition : chkAlcoholProhibitionState,
                                HarassmentBullying : chkHarassmentBullyingState,
                                DirectDeposit : chkDirectDepositState,
                                ClarifiedEmployer : chkClarifiedEmployerState,
                                WONC : chkWONCState,
                                WOTCU : chkWOTCUState,
                                WPSD : chkWPSDState,
                                OSTB : chkOSTBState,
                                OSRB : chkOSRBState,
                                WOUP : chkWOUPState,
                                MLPN : chkMLPNState,
                                ODAS : chkODASState,
                                CYRE : chkCYREState,
                                CYWE : chkCYWEState,
                                CYWS : chkCYWSState,
                                CYRS : chkCYRSState,
                                BSAS : chkBSASState,
                                AYCV : chkAYCVState,
                                CWRW : chkCWRWState,
                                IfNotWorkWeekend : IfNotWorkWeekendValue


                            })
                        }
                    })
                );
                setShowSubmitToast(true);
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
        }

    };


    // useEffect(() => {
    //     if(applicant)
    //     {
    //         if (applicantModificationRequestStatus == fetchStatus.SUCCEEDED) {
    //             //history.push(`/concentAcceptance/${id}`);
    //             dispatch(updateModificationStatus());
    //             setShowSubmitToast(true);
                
    //         }
    //         if (applicantModificationRequestStatus == fetchStatus.FAILED) {
    //             alert('Data upload failed, Please try again')
             
    //         }
    //     }   

    // }, [applicantModificationRequestStatus]);

    const onBackClicked = (e) => {
        // history.push(`/uploadDetails/${id}`);
        history.push(`/vaccineAccomodationDetails/${id}/${language}`);
    };



    const onToastDismissed = () => {
        setShowSubmitToast(false);
        history.push(`/login`);
       
    };


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {applicantRequestStatus === fetchStatus.SUCCEEDED ? `${firstName} ${lastName}` : <EllipsisLoader />}
                                </IonCol>

                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? email : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? phoneNumber : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? locationVal : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? status : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>


                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    Work Experience
                                </IonCol>
                                <IonCol>
                                    <IonItem lines="none">
                                        {!chkExpType && <IonLabel> {lang === Language.ENGLISH || lang === Language.EN ? "In Months" : "En meses" } </IonLabel> }
                                        {chkExpType && <IonLabel> {lang === Language.ENGLISH || lang === Language.EN ? "In Years" : "En años" } </IonLabel> } 
                                        <IonToggle  name="expType" checked={chkExpType}   onIonChange={e => setChkExpType(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                           
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel> {lang === Language.ENGLISH || lang === Language.EN ? "GENERAL LABOR: LOADING/UNLOADING" : "CARGA Y DESCARGA" } </IonLabel>
                                        <IonToggle  name="chkGen" checked={chkGenState}   onIonChange={e => setChkGenState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                  
                                    <IonInput name="txtGenExp" readonly={!chkGenState} value={genValue} onIonInput = {e => setGenValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{lang === Language.ENGLISH || lang === Language.EN ? "SHIPPING & RECEIVING" : "RECEPCIÓN DEL ENVÍO" }</IonLabel>
                                        <IonToggle  name="chkShipping" checked={chkShippingState}   onIonChange={e => setChkShippingState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>

                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtShippingExp" readonly={!chkShippingState} value={shippingValue} onIonInput = {e => setShippingValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>

                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{lang === Language.ENGLISH || lang === Language.EN ? "PACKAGING/LABELING" : "EMPACANDO Y ETIQUETANDO" }</IonLabel>
                                        <IonToggle name="chkPackaging" checked={chkPackagingState}   onIonChange={e => setChkPackagingState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>

                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtPackagingExp" readonly={!chkPackagingState} value={packagingValue} onIonInput = {e => setPackagingValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{lang === Language.ENGLISH || lang === Language.EN ? "INDUSTRIAL SEWING" : "COSTUREIRA INDUSTRIAL" } </IonLabel>
                                        <IonToggle name="chkIndustrial" checked={chkIndustrialState}  onIonChange={e => setChkIndustrialState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtIndustrialExp" readonly={!chkIndustrialState} value={industrialValue}  onIonInput = {e => setIndustrialValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{lang === Language.ENGLISH || lang === Language.EN ? "RF SCANNING" : "ESCANEADOR" }</IonLabel>
                                        <IonToggle name="chkRF" checked={chkRFState}  onIonChange={e => setChkRFState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtRFExp" readonly={!chkRFState} value={RFValue}  onIonInput = {e => setRFValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{lang === Language.ENGLISH || lang === Language.EN? "ASSEMBLY LINE"  : "LÍNEA DE ENSAMBLAJE" }</IonLabel>
                                        <IonToggle name="chkAssembly" checked={chkAssemblyState}   onIonChange={e => setChkAssemblyState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtAssemblyExp" readonly={!chkAssemblyState} value={assemblyValue} onIonInput = {e => setAssemblyValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{lang === Language.ENGLISH || lang === Language.EN ? "FOOD PRODUCTION" : "PRODUCCIÓN DE COMIDA"}</IonLabel>
                                        <IonToggle name="chkFood" checked={chkFoodState}   onIonChange={e => setChkFoodState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtFoodExp" readonly={!chkFoodState}  value={foodValue} onIonInput = {e => setFoodValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH  || lang === Language.EN? "MACHINE OPERATOR" : "OPERADOR DE MAQUINA" }</IonLabel>
                                        <IonToggle name="chkMachine" checked={chkMachineState}   onIonChange={e => setChkMachineState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtMachineExp" readonly={!chkMachineState} value={machineValue} onIonInput = {e => setMachineValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH  || lang === Language.EN? "MACHINES OPERATED" : "MAQUINAS QUE SEPA OPERAR" }</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                <IonItem lines="none">
                                <IonInput name="txtMachineOperated" readonly={!chkMachineState} valeu={machineOperated} onIonInput = {e => setMachineOperatedValue(e.target.value)} placeholder=""  color="primary"  clearInput></IonInput>
                                </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "FORKLIFT OPERATOR" : "OPERADOR DE FORKLIFT" }</IonLabel>
                                        <IonToggle name="chkSupervisor" checked={chkForkliftState}   onIonChange={e => setChkForkliftState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtSupervisorExp" readonly={!chkForkliftState} value={forkliftValue} onIonInput = {e => setForkliftValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                    <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "FORKLIFT OPERATOR TYPE" : "TIPO DE OPERADOR DE FORKLIFT" }</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                     <IonSelect slot="end"  placeholder="--------Select--------" disabled={!chkForkliftState} multiple={true} onIonChange={e => setForkliftType(e.detail.value)} cancelText="Cancel" okText="OK" > 
                                                <IonSelectOption value="1">CHERRY PICKER</IonSelectOption>
                                                <IonSelectOption value="2">STAND UP REACH</IonSelectOption>
                                                <IonSelectOption value="3">SIT DOWN</IonSelectOption>
                                                <IonSelectOption value="4">CLAMP</IonSelectOption>
                                                <IonSelectOption value="5">SLIP SHEET</IonSelectOption>
                                                <IonSelectOption value="6">ELECTRIC PALLET JACK</IonSelectOption>
                                    </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "SUPERVISOR/LEAD" : "Supervisor / Líder" }</IonLabel>
                                        <IonToggle name="chkSupervisor" checked={chkSupervisorState}   onIonChange={e => setChkSupervisorState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtSupervisorExp" readonly={!chkSupervisorState} value={supervisorValue} onIonInput = {e => setSupervisorValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "SECURITY" : "SEGURIDAD" }</IonLabel>
                                        <IonToggle name="chkSecurity" checked={chkSecurityState}   onIonChange={e => setChkSecurityState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtSecurityExp" readonly={!chkSecurityState} value={securityValue} onIonInput = {e => setSecurityValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                    <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "SECURITY TYPE" : "TIPO DE SEGURIDAD" }</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                     <IonSelect slot="end"  placeholder="--------Select--------" disabled={!chkSecurityState} multiple={true} cancelText="Cancel" okText="OK"  onIonChange={e => setSecurityType(e.detail.value)} > {/*//onIonChange={e => setToppings(e.detail.value)} */}
                                                <IonSelectOption value="1">{ lang === Language.ENGLISH || lang === Language.EN ? "ARMED" : "ARMADO" }</IonSelectOption>
                                                <IonSelectOption value="2">{ lang === Language.ENGLISH || lang === Language.EN ? "UNARMED" : "DESARMADO" }</IonSelectOption>
                                    </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "MAINTENANCE MECHANIC" : "MECANICO DE MANTENIMIENTO" }</IonLabel>
                                        <IonToggle name="chkMaintenance" checked={chkMaintenanceState}   onIonChange={e => setChkMaintenanceState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtMaintenanceExp" readonly={!chkMaintenanceState} value={maintenanceValue} onIonInput = {e => setMaintenanceValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "ELECTRICIAN" : "ELECTRICISTA"}</IonLabel>
                                        <IonToggle name="chkElectrician" checked={chkElectricianState}   onIonChange={e => setChkElectricianState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtElectricianExp" readonly={!chkElectricianState} value={electricianValue} onIonInput = {e => setElectricianValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "SANITATION" : "SANITACION" }</IonLabel>
                                        <IonToggle name="chkSanitation" checked={chkSanitationState}  onIonChange={e => setChkSanitationState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtSanitationExp" readonly={!chkSanitationState} value={sanitationValue}  onIonInput = {e => setSanitationValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "QUALITY ASSURANCE / QUALITY CONTROL" : "CONTROL DE CALIDAD"}</IonLabel>
                                        <IonToggle name="chkQuality" checked={chkQualityState}   onIonChange={e => setChkQualityState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtQualityExp" readonly={!chkQualityState} value={qualityValue} onIonInput = {e => setQualityValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="9">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "CLERICAL: COMPUTER KNOWLEDGE" : "COMPUTACION" }</IonLabel>
                                        <IonToggle name="chkComputer" checked={chkComputerState}   onIonChange={e => setChkComputerState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">
                                    <IonInput name="txtComputerExp" readonly={!chkComputerState} value={computerValue} onIonInput = {e => setComputerValue(e.target.value)} placeholder="0" min="0" max="100" step="1" color="primary" type="number" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                    <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "COMPUTER KNOWLEDGE TYPE" : "TIPO DE COMPUTACION" }</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                     <IonSelect slot="end" placeholder="--------Select--------" disabled={!chkComputerState} multiple={true} cancelText="Cancel" okText="OK" onIonChange={e => setComputerType(e.detail.value)}  > {/*//onIonChange={e => setToppings(e.detail.value)} */}
                                                <IonSelectOption value="1">{ lang === Language.ENGLISH || lang === Language.EN ? "BASIC KNOWLEDGE" : "BASICO" }</IonSelectOption>
                                                <IonSelectOption value="2">{ lang === Language.ENGLISH || lang === Language.EN ? "INTERMEDIATE" : "INTERMEDIO" }</IonSelectOption>
                                                <IonSelectOption value="3">{ lang === Language.ENGLISH || lang === Language.EN ? "ADVANCED" : "AVANCADO" }</IonSelectOption>
                                                <IonSelectOption value="4">{ lang === Language.ENGLISH || lang === Language.EN ? "MICROSOFT WORD" : "MICROSOFT WORD" }</IonSelectOption>
                                                <IonSelectOption value="5">{ lang === Language.ENGLISH || lang === Language.EN ? "EXCEL" : "EXCEL" }</IonSelectOption>
                                                <IonSelectOption value="6">{ lang === Language.ENGLISH || lang === Language.EN ? "POWER POINT" : "POWER POINT" }</IonSelectOption>

                                    </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                   General Questions 
                                </IonCol>

                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                    <IonLabel position="floating">{ lang === Language.ENGLISH || lang === Language.EN ? "Last Work Place Job / Duties" : "Last Work Place Job / Duties" }</IonLabel>
                                    <IonInput name="txtLastWorkPlace" value={LastWorkPlaceValue} onIonInput = {e => setLastWorkPlaceValue(e.target.value)} placeholder="Last Work Place Job / Duties"  color="primary" type="text" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                    <IonLabel position="floating">{ lang === Language.ENGLISH || lang === Language.EN ? "How Did You Learn About Us?" : "How Did You Learn About Us?" }</IonLabel>
                                    <IonInput name="txtKnowAboutUs" value={KnowAboutUsValue} onIonInput = {e => setKnowAboutUsValue(e.target.value)} placeholder="How Did You Learn About Us?"  color="primary" type="text" clearInput></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Has Own Medical Insurance?" : "Has Own Medical Insurance?"}</IonLabel>
                                        <IonToggle name="rdbtnHOMI" checked={chkHOMIState}   onIonChange={e => setchkHOMIState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                    <IonLabel position="floating">{ lang === Language.ENGLISH || lang === Language.EN ? "Medical Insurance Type" : "Medical Insurance Type" }</IonLabel>
                                    <IonInput name="txtMedicalInsuranceType" value={MedicalInsuranceTypeValue} onIonInput = {e => setMedicalInsuranceTypeValue(e.target.value)} placeholder="Medical Insurance Type"  color="primary" type="text" clearInput></IonInput>                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Available For ASAP Orders?" : "Available For ASAP Orders?"}</IonLabel>
                                        <IonToggle name="chkASAPO" checked={chkASAPOState}   onIonChange={e => setchkASAPOState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonLabel position="floating">{ lang === Language.ENGLISH || lang === Language.EN ? "Available Start Date" : "Available Start Date" }</IonLabel>
                                        <IonDatetime displayFormat="MM/DD/YYYY" name="txtASAPOAvailableDate" placeholder="Select Date" value={ASAPOAvailableDateValue} onIonChange={e => setASAPOAvailableDateValue(e.detail.value)}  ></IonDatetime>
                                    </IonItem>
                                </IonCol>
                                
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                   Policy  Questions 
                                </IonCol>

                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                           
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Available For OT?" : "Available For OT?"}</IonLabel>
                                        <IonToggle name="rdbtnAFOT" checked={chkAFOTState}   onIonChange={e => setchkAFOTState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Willing to commute for other cities?" : "Willing to commute for other cities?"}</IonLabel>
                                        <IonToggle name="rdbtnWCOC" checked={chkWCOCState}   onIonChange={e => setchkWCOCState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Fast Paced Environments?" : "Fast Paced Environments?"}</IonLabel>
                                        <IonToggle name="rdbtnFPENV" checked={chkFPENVState}   onIonChange={e => setchkFPENVState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Standing Long Period Of Time (8 Hours)?" : "Standing Long Period Of Time (8 Hours)?"}</IonLabel>
                                        <IonToggle name="rdbtnSLPO" checked={chkSLPOState}   onIonChange={e => setchkSLPOState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can Work In Hot Tempearture?" : "Can Work In Hot Tempearture?"}</IonLabel>
                                        <IonToggle name="rdbtnCWIH" checked={chkCWIHState}   onIonChange={e => setchkCWIHState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can Work In Cold Tempearture?" : "Can Work In Cold Tempearture?"}</IonLabel>
                                        <IonToggle name="rdbtnCWCT" checked={chkCWCTState}   onIonChange={e => setchkCWCTState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Heavy Lifting (50LBS)?" : "Heavy Lifting (50LBS)?"}</IonLabel>
                                        <IonToggle name="rdbtnHVLF" checked={chkHVLFState}   onIonChange={e => setchkHVLFState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Understand / Went Over Safety / Injury Protocol?" : "Understand / Went Over Safety / Injury Protocol?"}</IonLabel>
                                        <IonToggle name="rdbtnUNWI" checked={chkUNWIState}   onIonChange={e => setchkUNWIState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over CA Minimum Wage?" : "Went Over CA Minimum Wage?"}</IonLabel>
                                        <IonToggle name="rdbtnCAMinimmum" checked={chkCAMinimmumState}   onIonChange={e => setchkCAMinimmumState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over all Job Assignments being Temporary (Temp to Hire)?" : "Went Over all Job Assignments being Temporary (Temp to Hire)?"}</IonLabel>
                                        <IonToggle name="rdbtnTemptoHire" checked={chkTemptoHireState}   onIonChange={e => setchkTemptoHireState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over Alcohol and Drug Prohibition?" : "Went Over Alcohol and Drug Prohibition?"}</IonLabel>
                                        <IonToggle name="rdbtnAlcoholProhibition" checked={chkAlcoholProhibitionState}   onIonChange={e => setchkAlcoholProhibitionState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over Harassment and Bullying Reports?" : "Went Over Harassment and Bullying Reports?"}</IonLabel>
                                        <IonToggle name="rdbtnHarassmentBullying" checked={chkHarassmentBullyingState}   onIonChange={e => setchkHarassmentBullyingState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over Check Pick Up and Direct Deposit Qualification?" :  "Went Over Check Pick Up and Direct Deposit Qualification?"}</IonLabel>
                                        <IonToggle name="rdbtnDirectDeposit" checked={chkDirectDepositState}   onIonChange={e => setchkDirectDepositState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Clarified to Associate that SBS is the Employer not the Client?" : "Clarified to Associate that SBS is the Employer not the Client?"}</IonLabel>
                                        <IonToggle name="rdbtnClarifiedEmployer" checked={chkClarifiedEmployerState}   onIonChange={e => setchkClarifiedEmployerState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Understand / Went Over No Call No Show Policy?" : "Understand / Went Over No Call No Show Policy?" }</IonLabel>
                                        <IonToggle name="rdbtnWONC" checked={chkWONCState}   onIonChange={e => setchkWONCState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Understand / Went Over Time Clock Use / Policy?" : "Understand / Went Over Time Clock Use / Policy?"}</IonLabel>
                                        <IonToggle name="rdbtnWOTCU" checked={chkWOTCUState}   onIonChange={e => setchkWOTCUState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over Weekly Pay And 3 Sick Days?" : "Went Over Weekly Pay And 3 Sick Days?"}</IonLabel>
                                        <IonToggle name="rdbtnWPSD" checked={chkWPSDState}   onIonChange={e => setchkWPSDState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Own Still Toe Boots?" :  "Own Still Toe Boots?"}</IonLabel>
                                        <IonToggle name="rdbtnOSTB" checked={chkOSTBState}   onIonChange={e => setchkOSTBState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Own Slip Resistant Boots?" :  "Own Slip Resistant Boots?"}</IonLabel>
                                        <IonToggle name="rdbtnOSRB" checked={chkOSRBState}   onIonChange={e => setchkOSRBState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Understand / Went Over Uniform Policy?" : "Understand / Went Over Uniform Policy?"}</IonLabel>
                                        <IonToggle name="rdbtnWOUP" checked={chkWOUPState}   onIonChange={e => setchkWOUPState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Went Over 30 Mandatory Lunch / Punctuality?" :  "Went Over 30 Mandatory Lunch / Punctuality?"}</IonLabel>
                                        <IonToggle name="rdbtnMLPN" checked={chkMLPNState}   onIonChange={e => setchkMLPNState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Available for 1 Day assignment?" : "Available for 1 Day assignment?"}</IonLabel>
                                        <IonToggle name="rdbtnODAS" checked={chkODASState}   onIonChange={e => setchkODASState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can You Read In English?" : "Can You Read In English?"}</IonLabel>
                                        <IonToggle name="rdbtnCYRE" checked={chkCYREState}   onIonChange={e => setchkCYREState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can You Write In English?" : "Can You Write In English?"}</IonLabel>
                                        <IonToggle name="rdbtnCYWE" checked={chkCYWEState}   onIonChange={e => setchkCYWEState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can You Write In Spanish?" : "Can You Write In Spanish?"}</IonLabel>
                                        <IonToggle name="rdbtnCYWS" checked={chkCYWSState}   onIonChange={e => setchkCYWSState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can You Read In Spanish?" : "Can You Read In Spanish?"}</IonLabel>
                                        <IonToggle name="rdbtnCYRS" checked={chkCYRSState}   onIonChange={e => setchkCYRSState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Do You Have Basic Math Skills(Adding and Substracting)?" : "Do You Have Basic Math Skills(Adding and Substracting)?"}</IonLabel>
                                        <IonToggle name="rdbtnBSAS" checked={chkBSASState}   onIonChange={e => setchkBSASState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Are You COVID Vaccinated?" : "Are You COVID Vaccinated?"}</IonLabel>
                                        <IonToggle name="rdbtnAYCV" checked={chkAYCVState}   onIonChange={e => setchkAYCVState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="12">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Repetitive Movements?" :  "Repetitive Movements?"}</IonLabel>
                                        <IonToggle name="rdbtnREPM" checked={chkREPMState}   onIonChange={e => setchkREPMState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                
                              
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonLabel>{ lang === Language.ENGLISH || lang === Language.EN ? "Can Work Weekends?" : "Can Work Weekends?"}</IonLabel>
                                        <IonToggle name="rdbtnCWRW" checked={chkCWRWState}   onIonChange={e => setchkCWRWState(e.detail.checked)} slot="start" color="primary"   ></IonToggle>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                    <IonLabel position="floating">{ lang === Language.ENGLISH || lang === Language.EN ? "If Not Why" : "If Not Why"}</IonLabel>
                                    <IonInput name="txtIfNotWorkWeekend" value={IfNotWorkWeekendValue} onIonInput = {e => setIfNotWorkWeekendValue(e.target.value)} placeholder="Medical Insurance Type"  color="primary" type="text" clearInput></IonInput>                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>


                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {applicantRequestStatus !== fetchStatus.LOADING && applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    disabled={!isModelValid}
                                    onClick={(e) => onNextClicked(e)}>
                                    Submit
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {applicantRequestStatus !== fetchStatus.LOADING && applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    // disabled={!isModelValid}
                                    onClick={(e) => setShowSubmitToast(true)}>
                                    Skip
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {applicantRequestStatus !== fetchStatus.LOADING && applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                         
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonToast
                    position="middle"
                    header="Submitted"
                    color="dark"
                    isOpen={showSubmitToast}
                    onDidDismiss={() => onToastDismissed()}
                    message="Thank you for submitting the details with us."
                    duration={2000}
                />
            </IonContent>
        </IonPage>
    );
};

export default WorkExperience ;
