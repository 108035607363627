// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import axios from 'axios';

export const BajoAPI = axios.create({
  //baseURL: `https://landerr-testapi.azurewebsites.net/api/` //Test  enviroment 
  baseURL: `https://hirebaseapi.azurewebsites.net/api/` //Hirebase Demo Enviroment 
  //baseURL: `https://landerapi.azurewebsites.net/api/` //Live  enviroment 
  //baseURL: ` https://ce57-2401-4900-1c16-491a-dde6-5927-70d1-d00.ngrok.io/api/`, // local Enviroment 
  //baseURL: `/fakeApi/`
  // baseURL: `https://localhost:44377/api/`  //local Enviroment
 
  
});

export const fetchStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
}

export const masterTenantId = "HireBase";

const subDomainName = window.location.host.split('.')[0];
// export const tenantId = subDomainName.charAt(0).toUpperCase() + subDomainName.slice(1);  // Live Enviroment

// export const tenantId = "HireBase";   // Test Enviroment 
export const tenantId = "Worca";         // Test Enviroment