import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import Options from './options';

function Question(props) {
    return (
        <div>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        <IonRow>
                            <IonCol size="12">
                                {props.question.Text.Data[props.forLanguage]}
                            </IonCol>
                        </IonRow>
                    </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <Options
                                    question={props.question}
                                    options={props.question.Options}
                                    response={props.response}
                                    forLanguage={props.forLanguage} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            {/* <span>{props.question.Text.Data}</span>
            <Options
                question={props.question}
                options={props.question.Options} /> */}
        </div>
    );
}

export default Question;