export const classActionWaiverEN = {
    title: "Class and Collective Action Waiver for Employment-Related Claims",
    content: [
        {
            tag: "p",
            class: "",
            data: "Employee and Hirebase “Employer” expressly intend and agree that:"
        },
        {
            tag: "p",
            class: "",
            data: "class action and collective action procedures shall not be asserted, and will not apply, in any arbitration under this Agreement;"
        },
        {
            tag: "p",
            class: "",
            data: "each will not assert class or collective action claims against the other in arbitration, court, or any other forum;"
        },
        {
            tag: "p",
            class: "",
            data: "each shall only submit their own individual claims in arbitration and shall not bring claims against the other in any representative capacity on behalf of any other individual; and"
        },
        {
            tag: "p",
            class: "",
            data: "any claims by the Employee will not be joined, consolidated, or heard together with claims of any other current or former employee of Employer."
        },
        {
            tag: "p",
            class: "",
            data: "Notwithstanding anything to the contrary in the Federal Arbitration Act, and the general grant of authority to the arbitrator of the power to determine issues of arbitrability, the arbitrator shall have no jurisdiction or authority to compel any class or collective claim, to consolidate different arbitration proceedings, or to join any other party to an arbitration between Employer and Employee."
        },
        {
            tag: "p",
            class: "",
            data: "Notwithstanding anything to the contrary in the Federal Arbitration Act’s rules and guidelines for employment arbitration, the arbitrator shall have the authority to determine the enforceability of this class and collective action and arbitration waiver. If the class action waiver or prohibition on class arbitration is deemed invalid or unenforceable, then the remaining portions of the Arbitration Agreement will remain in force."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "By initialing here, Employee acknowledges [he/she] has read this [section/provision] and agrees with the class action waiver in it."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I hereby acknowledge that I have read, understand, and agree to the above Class Action Waiver. I understand and acknowledge that I enter this waiver willingly and voluntarily. In entering into this Agreement, I represent that I have relied upon the advice of my attorneys or have chosen to enter into this Agreement without the assistance of counsel based upon my understanding of the terms hereof. The terms of this Agreement have been completely read and explained to me by my attorneys and/or I have reviewed the terms hereof in complete detail and that I fully understood and voluntarily accept the terms."
        },
        {
            tag: "p",
            class: "",
            data: "Name"
        },
        {
            tag: "p",
            class: "",
            data: "Signature"
        },
        {
            tag: "p",
            class: "",
            data: "Date"
        }
    ]
}


export const classActionWaiverSP = {
    title: "Exención de Demanda Colectiva para Reclamaciones Relacionadas con el Empleo",
    content: [
        {
            tag: "p",
            class: "",
            data: "Los empleados y Hirebase \"Empleador\" expresamente tienen la intención y están de acuerdo en que:"
        },
        {
            tag: "p",
            class: "",
            data: "la demanda colectiva y los procedimientos de acción colectiva no serán afirmados, y no se aplicarán, en ningún arbitraje bajo este Acuerdo;"
        },
        {
            tag: "p",
            class: "",
            data: "cada uno no hará valer demandas colectivas o colectivas contra el otro en arbitraje, tribunal o cualquier otro foro;"
        },
        {
            tag: "p",
            class: "",
            data: "cada uno sólo presentará sus propias reclamaciones individuales en arbitraje y no presentará reclamaciones contra el otro en ninguna capacidad representativa en nombre de ninguna otra persona; Y"
        },
        {
            tag: "p",
            class: "",
            data: "cualquier reclamo del Empleado no se unirá, consolidará ni escuchará junto con las reclamaciones de ningún otro empleado actual o anterior del Empleador."
        },
        {
            tag: "p",
            class: "",
            data: "No obstante todo lo contrario en la Ley Federal de Arbitraje, y la concesión general de autoridad al árbitro del poder para determinar cuestiones de arbitraje, el árbitro no tendrá jurisdicción o autoridad para obligar a cualquier clase o reclamo colectivo, para consolidar diferentes procedimientos de arbitraje, o para unirse a cualquier otra parte a un arbitraje entre empleador y empleado."
        },
        {
            tag: "p",
            class: "",
            data: "No obstante todo lo contrario en las reglas y directrices de la Ley Federal de Arbitraje para el arbitraje laboral, el árbitro tendrá la autoridad para determinar la aplicabilidad de esta clase y la demanda colectiva y la exención de arbitraje. Si la renuncia o prohibición de la demanda colectiva sobre el arbitraje colectivo se considera inválida o inaplicable, las partes restantes del Acuerdo de Arbitraje permanecerán en vigor."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Al inicializar aquí, el Empleado reconoce que [él/ella] ha leído esta [sección/disposición] y está de acuerdo con la renuncia a la demanda colectiva en ella."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Por el presente reconozco que he leído, entiendo y acepto la exención de demanda colectiva anterior. Entiendo y reconozco que entro en esta exención voluntaria y voluntariamente. Al aceptar este Acuerdo, represento que he confiado en el consejo de mis abogados o he elegido aceptar este Acuerdo sin la ayuda de un abogado basado en mi comprensión de los términos del presente documento. Los términos de este Acuerdo han sido completamente leídos y explicados a mí por mis abogados y /o he revisado los términos de esto con todo detalle y que comprendí completamente y acepto voluntariamente los términos."
        },
        {
            tag: "p",
            class: "",
            data: "Nombre Completo"
        },
        {
            tag: "p",
            class: "",
            data: "Firma"
        },
        {
            tag: "p",
            class: "",
            data: "Fecha"
        }
    ]
}