import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { vaccinationPolicyEN, vaccinationPolicySP } from './services/vaccinationPolicy';
import './css/concent.css';

function VaccinationPolicy(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(vaccinationPolicyEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(vaccinationPolicySP);
        }
        else {
            setConcent(vaccinationPolicyEN);
        }
    }, [language]);

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{concent.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[0].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[1].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[2].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[3].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[4].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[5].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[6].data}
                            <span className="danger">{concent.content[7].data}</span>
                            {concent.content[8].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[9].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[10].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[11].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[12].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[13].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            <span className="font-weight-bold">{concent.content[14].data}</span>
                            {concent.content[15].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[16].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[17].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[18].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[19].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[20].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <a>
                            {concent.content[21].data}
                        </a>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[22].data}
                        </p>
                    </IonCol>
                </IonRow>
                

                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[23].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[24].data}
                        </p>
                        <ApplySign
                        buttonText = {language && language == "Spanish" ? "Firma" :  "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[25].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
                
            </IonCardContent>
        </IonCard>
    );
}

export default VaccinationPolicy;