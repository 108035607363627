import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { classActionWaiverEN, classActionWaiverSP } from './services/classActionWaiver';
import './css/concent.css';

function ClassActionWaiver(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(classActionWaiverEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(classActionWaiverSP);
        }
        else {
            setConcent(classActionWaiverEN);
        }
    }, [language]);

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    const onInitialsSigned = (value) => {
        props.onInitialsSigned && props.onInitialsSigned(value);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{concent.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>

                <IonRow>
                    <IonCol>
                        <ol>
                            <li>
                                <div>
                                    <p>{concent.content[0].data}</p>
                                    <ul className="list-alpha-style">
                                        <li>{concent.content[1].data}</li>
                                        <li>{concent.content[2].data}</li>
                                        <li>{concent.content[3].data}</li>
                                        <li>{concent.content[4].data}</li>
                                    </ul>
                                </div>
                            </li>
                            <li> <p>{concent.content[5].data}</p></li>
                            <li> <p>{concent.content[6].data}</p></li>
                            <li className="font-bold">  <ApplySign
                                buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                SignClasses="d-inline-block"
                                signed={props.initials}
                                signature={props.initial}
                                onSigned={(value) => onInitialsSigned(value)} /><p className="d-inline">{concent.content[7].data}</p></li>
                        </ol>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[8].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[9].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[10].data}
                        </p>
                        <ApplySign
                        buttonText = {language && language == "Spanish" ? "Firma" :  "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[11].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    );
}

export default ClassActionWaiver;