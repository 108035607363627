import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonRow } from '@ionic/react';
import { call, mail, location, informationCircle } from 'ionicons/icons';
import React from 'react';

import noPhoto from '../../assets/nophoto.png';
import './css/applicantCard.css';

function ApplicantCard(props) {
    return (
        <IonCard class="applicant-card" onClick={() => props.onSelectClicked(props.id)}>
            <IonImg class="header-img" src={props.photoURL || noPhoto} />
            <IonCardHeader className="applicant-card-header">
                <IonCardTitle className="applicant-card-header-title">{props.name}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="applicant-card-content">
                <div className="applicant-card-detail" >
                    <IonIcon icon={mail} slot="start" color="warning" />
                    <IonLabel className="applicant-card-detail-label">{props.email}</IonLabel>
                </div>
                <div className="applicant-card-detail">
                    <IonIcon icon={call} slot="start" color="primary" />
                    <IonLabel className="applicant-card-detail-label">{props.phone}</IonLabel>
                </div>
                <div className="applicant-card-detail">
                    <IonIcon icon={location} slot="start" color="dark" />
                    <IonLabel className="applicant-card-detail-label">{props.location}</IonLabel>
                </div>
                <div className="applicant-card-detail">
                    <IonIcon icon={informationCircle} slot="start" color="secondary" />
                    <IonLabel className="applicant-card-detail-label">{props.status}</IonLabel>
                </div>
                {/* <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton size="small" onClick={() => props.onSelectClicked(props.id)}>Select</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid> */}
            </IonCardContent>
        </IonCard>
    );
}

export default ApplicantCard;
