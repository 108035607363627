export const ErrorCode = {
    EMAIL_IN_USE: 100,
    UNKNOWN_SERVER_ERROR: 101,
    INVALID_TOKEN: 102,
    INVALID_LOGIN_ATTEMPT: 103,
    UNAUTHORIZED: 104,
    INVALID_RESET_TOKEN: 105
}

export const USER_KEY = "THSBGDYDPKDH1552NC";

export const Role = {
    ADMIN: "Admin",
    USER: "User"
}

export const Language = {
    ENGLISH: "English",
    SPANISH: "Spanish",
    EN : "EN", 
    SP : "SP"
}

export const TrainingStatus = {
    PENDING: "Pending",
    INPROGRESS: "In-Progress",
    COMPLETED: "Completed"
}

export const TrainingResult = {
    UNKNOWN: "Unknown",
    FAILED: "Failed",
    PASSED: "Passed"
}