import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonBadge, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { pin, wifi, wine, warning, walk, call, mail, cloudUpload, camera, location, informationCircle, trashSharp, trash } from 'ionicons/icons';
import './uploadDetails.css';
import nophoto from '../assets/nophoto.png';
import { usePhoto } from '../app/hooks/usePhoto';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
    getApplicantById,
    selectSingleStatus as applicantStatus,
    selectApplicantById,
    updateApplicant,
    updateSingleStatus,
    updateModificationStatus,
    selectModificationStatus,
    selectSignature,
    selectIntial,
    selectLanguage
} from './applicant/reducerSlices/applicantSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../app/api/client';
import '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { useCamera } from '@ionic/react-hooks/camera';
import { selectLoggedInUser } from './accounts/reducerSlices/accountsSlice';
import EllipsisLoader from '../components/loaders/ellipsisLoader';
import SignatureCanvas from 'react-signature-canvas';
import SignatureContainer from '../components/containers/signatureContainer';
import SignatureInput from '../components/controls/signatureInput';
import { _getLocationText } from '../data/masterData';
import GridLoader from '../components/loaders/gridLoader';
import { Offline, Online, Detector } from "react-detect-offline";



const UploadDetails = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = props.match.params;

    const user = useSelector(selectLoggedInUser);

    const applicant = useSelector((state) => selectApplicantById(state, id));
    const applicantRequestStatus = useSelector(applicantStatus);
    const applicantModificationRequestStatus = useSelector(selectModificationStatus);
    const signatureData = useSelector(selectSignature);
    const initialData = useSelector(selectIntial);

    const { getPhoto } = useCamera();
    const [language, setLanguage] = useState("");
   
    const [initial, setInitial] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [locationVal, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const [photoData, setPhotoData] = useState("");
    const [photoURL, setPhotoURL] = useState("");
    const [signature, setSignature] = useState("");
    const [isSavePressed, setIsSavePressed] =  useState("");
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    useEffect(() => {
        if (applicantRequestStatus === fetchStatus.IDLE) {
            dispatch(getApplicantById({
                client: tenantId,
                serviceType: "TENANTUSER",
                operation: "GETAPPLICANTFORMOBILE",
                payload: {
                    id: id,
                    data: ""
                }
            }));
        }
    }, [applicantRequestStatus, dispatch, id]);

    
    useEffect(() => {

        if (applicant) {
            console.log(applicant);
            let loc = _getLocationText(applicant.LocationId);
            setFirstName(applicant.FirstName);
            setLastName(applicant.LastName);

            setEmail(applicant.Email);
            setPhoneNumber(applicant.PhoneNumber);
            setLocation(loc);
            setStatus(applicant.Status);
            //setLanguage(applicant.Language);
            if (applicant.PhotoPath && (typeof applicant.PhotoPath === 'string' || applicant.PhotoPath instanceof String) && applicant.PhotoPath !== "" && applicant.PhotoPath.startsWith("https://bajoapplicantstorage.blob.core.windows.net/")) {
                setPhotoURL(applicant.PhotoPath);
            }
            else {
                setPhotoURL("True");
            }
            setInitial(initialData);
            setSignature(signatureData);
        }

    }, [id, applicant, dispatch, initialData, signatureData]);

    useEffect(() => {
        dispatch(updateSingleStatus());
    }, [id, dispatch]);

    let isModelValid = [photoURL, initial, language, signature].every(Boolean);

    const onNextClicked = async (e) => {
        if (isModelValid) {
            try {
                setIsSavePressed("True");
                await dispatch( 
                    updateApplicant({
                        client: tenantId,
                        serviceType: "TENANTUSER",
                        operation: "POST",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                photo: {
                                    contentType: 'image/jpeg',
                                    photoData: photoData,
                                    length: 0
                                },
                                language: language,
                                initial: {
                                    contentType: 'image/jpeg',
                                    initialData: initial,
                                    length: 0
                                },
                                signature: {
                                    contentType: 'image/jpeg',
                                    signatureData: signature,
                                    length: 0
                                }
                            })
                        }
                    })
                );
               
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
        }
        else 
        {
            if(photoURL === "")
            {
                alert('Please click Photo');
            }
            else if (language === "")
            {
                alert('Please select language');
            }
            else if(initial === "")
            {
                alert('Please sign initial');
            }
            else if(signature === "")
            {
                alert('Please do signature');
            }
        }

    };

    useEffect(() => {
        if(applicant && isSavePressed === "True")
        {
            if (applicantModificationRequestStatus == fetchStatus.SUCCEEDED) {
                //history.push(`/workExperience/${id}/${language}`);
                dispatch(updateModificationStatus());
                setIsSavePressed("");
                history.push(`/concentAcceptance/${id}/${language}`);
               
            }
            if (applicantModificationRequestStatus == fetchStatus.FAILED) {
                alert('Data upload failed, Please try again')
             
            }
        }   

    }, [applicantModificationRequestStatus]);
    

    const onBackClicked = (e) => {
        history.push(`/scanApplicant`);
    };

    const onStartTestClicked = (e) => {
        history.push(`/trainings/${id}`);
    };

    const onPhotoSelected = (e) => {
        takePhoto();
    };

    const onsetLanguage = (strLang) => {
        setLanguage(strLang);
        
    }

    const takePhoto = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;
        setPhotoData(newPhoto.data);
        setPhotoURL(newPhoto.webviewPath);
    };

    const savePicture = async (photo, fileName) => {
        let base64Data = await base64FromPath(photo.webPath);
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: base64Data
        };
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {applicantRequestStatus === fetchStatus.SUCCEEDED ? `${firstName} ${lastName}` : <EllipsisLoader />}
                                </IonCol>

                                {/* <IonCol className="ion-text-center">
                                  
                                    {applicantRequestStatus !== fetchStatus.LOADING && applicantModificationRequestStatus !== fetchStatus.LOADING ? <> <IonButton
                                        fill="solid"
                                        color=""
                                        onClick={(e) => onStartTestClicked(e)}>
                                        Start Training
                                    </IonButton> : 
                                        <IonButton
                                            fill="solid"
                                            color="medium"
                                            onClick={(e) => onBackClicked(e)}>
                                            Back
                                        </IonButton></> : <EllipsisLoader />}
                                </IonCol> */}
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? email : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? phoneNumber : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? locationVal : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{applicantRequestStatus === fetchStatus.SUCCEEDED ? status : <EllipsisLoader />}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <div className="photo-container">
                        {applicantRequestStatus !== fetchStatus.LOADING ? <IonImg className="photo" src={photoURL || nophoto} />
                            : <></>}
                    </div>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow class="ion-text-center">
                                <IonCol>
                                    {applicantRequestStatus !== fetchStatus.LOADING ? <IonButton className="photo-button" fill="solid" color="primary" onClick={(e) => onPhotoSelected(e)}>
                                        <IonIcon className="button-icon" slot="icon-only" icon={camera} />

                                    </IonButton> : <EllipsisLoader />}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonItem lines="none" className="label-margin-bottom">
                        <IonLabel>Select Preferred Language</IonLabel>
                    </IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                {applicantRequestStatus !== fetchStatus.LOADING ? <IonSelect className="language-select" value={language} placeholder="--- Select Preferred Language ---"
                                    interface="action-sheet" onIonChange={(e) => onsetLanguage(e.target.value)}>
                                    <IonSelectOption value="English">English</IonSelectOption>
                                    <IonSelectOption value="Spanish">Spanish</IonSelectOption>
                                </IonSelect> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>

                <SignatureContainer>
                    <IonGrid>
                        {applicantRequestStatus !== fetchStatus.LOADING ? <IonRow>
                            <IonCol size="4">
                                <IonItem lines="none">
                                    <IonLabel>{language && language == "Spanish" ? "Inicial" : "Initial"}</IonLabel>
                                </IonItem>
                                {applicantRequestStatus !== fetchStatus.LOADING ? <SignatureInput
                                    placeholder="Color"
                                    signature={initial}
                                    onChange={(e, v) => setInitial(v)}
                                    onClear={(v) => setInitial(v)} /> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol size="8">
                                <IonItem lines="none">
                                    <IonLabel>{language && language == "Spanish" ? "Firma" :  "Signature"}</IonLabel>
                                </IonItem>
                                {applicantRequestStatus !== fetchStatus.LOADING ? <SignatureInput
                                    signature={signature}
                                    onChange={(e, v) => setSignature(v)}
                                    onClear={(v) => setSignature(v)} /> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow> : <div className="process-grid-loader"><GridLoader /></div>}
                    </IonGrid>
                </SignatureContainer>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {applicantRequestStatus !== fetchStatus.LOADING && applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    // disabled={!isModelValid}
                                    onClick={(e) => onNextClicked(e)}>
                                    Next
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {applicantRequestStatus !== fetchStatus.LOADING && applicantModificationRequestStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default UploadDetails;
