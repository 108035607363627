import React from 'react';

import './css/videoPlayer.css';

function VideoPlayer(props) {
    return (
        <video
            className="video-player"
            controls
            //src={props.url}
            
            playsInline
            looped="false"
            preload="none">
            <source type="video/mp4" src={props.url} />
        </video>
    );
}

export default VideoPlayer;