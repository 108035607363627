import React, { useEffect } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,IonBadge, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { selectLoggedInUser } from '../../accounts/reducerSlices/accountsSlice';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import { fetchStatus } from '../../../app/api/client';
import { getAllQuestions, selectAllQuestions, selectQuestionsStatus } from '../reducerSlices/safetyTestSlice';
import { useHistory } from 'react-router';
import { isObjectArray } from '../../../app/utilities/utilityFunctions';
import Question from './question';
import { nanoid } from '@reduxjs/toolkit';
import VideoPlayer from '../../../components/controls/players/videoPlayer';
import { call, mail, location, informationCircle } from 'ionicons/icons';
import { selectApplicantById } from '../../applicant/reducerSlices/applicantSlice';
import { _getLocationText } from '../../../data/masterData';
import { Language } from '../../../app/common/constants';
import { Offline, Online, Detector } from "react-detect-offline";


function SafetyTraining(props) {
    const { id, lang } = props.match.params;
    const history = useHistory();
    const user = useSelector(selectLoggedInUser);
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    const applicant = useSelector((state) => selectApplicantById(state, id));

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {applicant.FirstName + " " + applicant.LastName}
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{applicant.Email}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{applicant.PhoneNumber}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{_getLocationText(applicant.LocationId)}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{applicant.Status}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                            Fork Lift Training
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <VideoPlayer
                                        url={applicant && (lang === Language.SPANISH ||lang === Language.SP) ? "http://bajonextgen.tktechnico.com/video/ForklLiftTraining (SPANISH).mp4" : "http://bajonextgen.tktechnico.com/video/ForklLiftTraining.mp4" }/>
                                        
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                            Workplace Safety Basics
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <VideoPlayer
                                        url={applicant && (lang === Language.SPANISH || lang === Language.SP) ? "http://bajonextgen.tktechnico.com/video/Workplace Safety Basics (SPANISH).mp4" : "http://bajonextgen.tktechnico.com/video/Workplace Safety Basics.mp4" }/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>

                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {fetchStatus.SUCCEEDED !== fetchStatus.LOADING && fetchStatus.SUCCEEDED !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    onClick={(e) => history.push(`/safetyTest/${id}/${lang}`)}>
                                    Start Test
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {fetchStatus.SUCCEEDED !== fetchStatus.LOADING && fetchStatus.SUCCEEDED !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => history.push(`/trainingHome/${id}`)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>

    );
}

export default SafetyTraining;