import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { companyPoliciesEN, companyPoliciesSP } from './services/companyPolicies';
import './css/concent.css';

function CompanyPolicies(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(companyPoliciesEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(companyPoliciesSP);
        }
        else {
            setConcent(companyPoliciesEN);
        }
    }, [language]);

    let items;
    items = concent.content[0].data.map((item, index) => (
        <li key={nanoid()}>{item}</li>
    ));

    let docs;
    docs = concent.content[2].data.map((doc, index) => (
        <li key={nanoid()}>{doc}</li>
    ));

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{concent.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>

                <IonRow>
                    <IonCol>
                        <ol>
                            <li>{concent.content[0].data[0]}</li>
                            <li>{concent.content[0].data[1]}</li>
                            <li>{concent.content[0].data[2]}</li>
                            <li>{concent.content[0].data[3]}</li>
                            <li>
                                <div>
                                    <span>{concent.content[0].data[4]}</span>
                                    <span class="danger">{concent.content[1].data}</span>
                                    <ol>
                                        <li>{concent.content[2].data[0]}</li>
                                        <li>{concent.content[2].data[1]}</li>
                                        <li>{concent.content[2].data[2]}</li>

                                    </ol>
                                </div>
                            </li>
                        </ol>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[3].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[4].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="text-center">
                            {concent.content[5].data}<i class="fa fa-smile-o" aria-hidden="true"></i>
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[6].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[7].data}
                        </p>
                        <ApplySign
                        buttonText = {language && language == "Spanish" ? "Firma" :  "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} />

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <p className="concent-label">
                            {concent.content[8].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    );
}

export default CompanyPolicies;