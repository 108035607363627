export const acaWaiverEN = {
    title: "OFFER OF MINIMUM ESSENTIAL COVERAGE(ACA) AND WAIVER OF COVERAGE",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Having met the eligibility requirements , you are being offered the opportunity to enroll in health coverage offered by Hirebase. You have the right to elect, decline or waive coverage."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Note that if you waive coverage considered affordable and minimum essential under the Patient Protection and Affordable Care Act (ACA), you will not qualify for government credits and subsidies to purchase individual health insurance on the Marketplace."
        },
        {
            tag: "p",
            class: "",
            data: "The decision to waive coverage has consequences for you. For example:"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "If you waive this coverage and do not obtain coverage on your own, you will be subject to a penalty under the individual responsibility requirement of the ACA.",
                "If you waive coverage, you cannot enroll until the next open enrollment, unless you experience a qualified change in status. Examples include if you are covered under another plan, but that coverage is lost, or if you gain a new dependent through birth, adoption, or marriage. However, you must request to enroll in your plan within 30 days of the qualified change in status. If you miss the 30-day enrollment deadline, you must wait until open enrollment."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I acknowledge that the Employer has offered me affordable minimum essential coverage, as defined under the ACA, on (add date)  ###currentDate###. I also elect not to have medical coverage, regardless of the employer medical benefit offer. This is a waiver of coverage. I have read the above and I understand the consequences of my waiver of coverage."
        },
        {
            tag: "span",
            class: "",
            data: "Name of Employee"
        },
        {
            tag: "span",
            class: "",
            data: "Signature of Employee"
        },
        {
            tag: "span",
            class: "",
            data: "Date"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "As a representative of the Employer, I received this Waiver of Coverage from the above employee on ###currentDate### (Date)"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Signature of the Employer Representative"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "*If you decide to participate in the company’s group insurance benefits, please see your Supervisor or Office Branch for more information."
        }
    ]
}

export const acaWaiverSP = {
    title: "OFERTA DE COBERTURA ESENCIAL MINIMA (ACA) Y RENUNCIA A LA COBERTURA",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Habiendo cumplido con los requisitos de elegibilidad¹, se le ofrece la oportunidad de inscribirse en la cobertura de salud ofrecida por Hirebase. Usted tiene el derecho de elegir, rechazar o renunciar a la cobertura de Médica de ACA (La ley de cuidado de salud)."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Tenga en cuenta que si usted renuncia a la cobertura considerada asequible y mínima esencial bajo la ley de protección al paciente y cuidado asequible (ACA), usted no calificará para los créditos del gobierno y subsidios para comprar un seguro de salud individual en el mercado."
        },
        {
            tag: "p",
            class: "",
            data: "La decisión de renunciar a la cobertura tiene consecuencias para usted. Por ejemplo:"
        },
        {
            tag: "ul",
            class: "text-justify",
            data: [
                "Si usted renuncia a esta cobertura y no obtiene cobertura por su cuenta, estará sujeto a una penalidad bajo el requerimiento de responsabilidad individual de la ACA.",
                "Si usted renuncia a la cobertura, no puede inscribirse hasta la próxima inscripción abierta, a menos que experimente un cambio calificado en el estado. Los ejemplos incluyen si usted está cubierto bajo otro plan, pero esa cobertura se pierde, o si obtiene un nuevo dependiente a través del nacimiento, la adopción o el matrimonio. Sin embargo, debe solicitar inscribirse en su plan dentro de los 30 días posteriores al cambio de estado calificado. Si no cumplir con la fecha límite de inscripción de 30 días, debe esperar hasta la inscripción abierta."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Reconozco que el Empleador me ha ofrecido una cobertura esencial mínima asequible, tal como se define en la ACA, en (añadir fecha) ###currentDate###. Tambien elijo no tener cobertura, independedientemente de la oferta de beneficios medicos del empleador. Esta es una exencion de cobertura. He leído lo anterior y entiendo las consecuencias de mi renuncia a la cobertura."
        },
        {
            tag: "span",
            class: "",
            data: "Nombre de Empleado"
        },
        {
            tag: "span",
            class: "",
            data: "Firma de Empleado"
        },
        {
            tag: "span",
            class: "",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Como representante de el empleador, recibí esta Exención de Cobertura del empleado arriba el ###currentDate### (Fecha)."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Firma de el Representante de el Empleador"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "* Si decide participar en los beneficios de seguro de grupo de la compañía, consulte a su Supervisor o Sucursal de La Oficina para obtener más información."
        }
    ]
}