import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../app/api/client';
import { getAxiosRequestConfig } from '../../../app/common/common';
import { USER_KEY } from '../../../app/common/constants';
import { isJSON } from '../../../app/utilities/utilityFunctions';
import { accountService } from '../../accounts/services/accountService';

const initialState = {
    reviewerSignature: {
        status: fetchStatus.IDLE,
        error: null,
        signature: undefined//,
        //initial: undefined
    }
}

export const saveReviewerSignature = createAsyncThunk('reviewer/saveReviewerSignature', async (reviewerModel) => {
    const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let signature = undefined;
    if (data && isJSON(data)) {
        signature = JSON.parse(data);
    }
    return signature;
});

export const accountsSlice = createSlice({
    name: 'reviewer',
    initialState,
    reducers: {
        updateLoginStatus: (state) => {
            state.login.status = fetchStatus.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(saveReviewerSignature.pending, (state, action) => {
            state.reviewerSignature.status = fetchStatus.LOADING;
        }).addCase(saveReviewerSignature.fulfilled, (state, action) => {
            state.reviewerSignature.signature = action.payload.signature;
            //state.reviewerSignature.initial = action.payload.initial;
            let user = accountService.getAuthenticatedUser();
            user.signature = "data:image/png;base64," + action.payload.signature;
            //user.initial = "data:image/png;base64," + action.payload.initial;
            localStorage.setItem(USER_KEY, JSON.stringify(user));
            state.reviewerSignature.status = fetchStatus.SUCCEEDED;
        }).addCase(saveReviewerSignature.rejected, (state, action) => {
            state.reviewerSignature.status = fetchStatus.FAILED;
            state.reviewerSignature.error = action.error.message;
        });
    }
});

export const { updateLoginStatus } = accountsSlice.actions;

export default accountsSlice.reducer;

export const selectReviewerSignatureStatus = state => state.reviewer.reviewerSignature.status;

export const selectReviewerSignatureError = state => state.reviewer.reviewerSignature.error;