import { IonButton, IonButtons, IonSearchbar , IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonBadge, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { pin, wifi, wine, warning, walk, call, mail, cloudUpload, camera, qrCodeOutline, search } from 'ionicons/icons';
import '../css/scanApplicant.css';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { fetchStatus, tenantId } from '../../../app/api/client';
import { getAllApplicants, selectAllApplicants, selectStatus } from '../reducerSlices/applicantSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../accounts/reducerSlices/accountsSlice';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import ApplicantCard from '../../../components/cards/applicantCard';
import GridLoader from '../../../components/loaders/gridLoader';

import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { isJSON } from '../../../app/utilities/utilityFunctions';
import { _getLocationText } from '../../../data/masterData';
import { isPlatform } from '@ionic/react';
import { Offline, Online, Detector } from "react-detect-offline";


const ScanApplicant = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(selectLoggedInUser);
    const [applicantId, setApplicantId] = useState("");
    const [searchText, setSearchText] = useState("");

    
    const applicants = useSelector(selectAllApplicants);
    const applicantsRequestStatus = useSelector(selectStatus);
    const [showNotFoundToast, setShowNotFoundToast] = useState(false);
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    
    
    useEffect(() => {

        if (applicantsRequestStatus === fetchStatus.IDLE) {
            dispatch(getAllApplicants({
                client: tenantId,
                serviceType: "TENANTUSER",
                operation: "GETAPPLICANTSFORMOBILEAPP",
                payload: {
                    id: user.locationId,
                    data: ""
                }
            }));
            setSearchText("");
        }
    }, [applicantsRequestStatus, dispatch, user.locationId]);

    let isModelValid = [applicantId].every(Boolean);

    const onSelectClicked = (id) => {
        if(isPlatform('ios')) {
            //history.push(`/trainingHome/${id}`);
            history.push(`/uploadDetails/${id}`);
        }
        else 
        {
            history.push(`/trainingHome/${id}`);
            //history.push(`/uploadDetails/${id}`);
        }
    };

    let applicantCards;

    if (applicantsRequestStatus === fetchStatus.SUCCEEDED) {
        if (applicants && applicants.length > 0) {
            applicantCards = applicants.map((applicant, index) => (
                <ApplicantCard
                    key={applicant.id}
                    id={applicant.id}
                    name={applicant.FirstName + " " + applicant.LastName}
                    email={applicant.Email}
                    phone={applicant.PhoneNumber}
                    location={_getLocationText(applicant.Location)}
                    status={applicant.Status}
                    photoURL={applicant.ImageData}
                    onSelectClicked={(Id) => onSelectClicked(Id)} />
            ));

        }
    }

    const onSearchClick = (e) => {
      
        dispatch(getAllApplicants({
            client: tenantId,
            serviceType: "TENANTUSER",
            operation: "GETAPPLICANTSFORMOBILEAPP",
            payload: {
                id: user.locationId,
                data: JSON.stringify({
                    searchText: searchText})
            }
        }));
        };

        
    const openScanner = async () => {
        const data = await BarcodeScanner.scan();
        if (data && data.text && isJSON(data.text)) {
            let dataObj = JSON.parse(data.text);
            if(isPlatform('ios')) {
                //history.push(`/trainingHome/${id}`);
                history.push(`/uploadDetails/${dataObj.userId}`);
            }
            else 
            {
                history.push(`/trainingHome/${dataObj.userId}`);
                //history.push(`/uploadDetails/${id}`);
            }
          
           
        }
        else {
            setShowNotFoundToast(true);
        }
    };

    const onToastNotFoundDismissed = (e) => {
        setShowNotFoundToast(false);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow class="ion-text-center">
                        <IonCol size="10">
                            <IonItem lines="none">
                                <IonInput name="txtSearch" value={searchText} onIonChange = {e => setSearchText(e.target.value)} placeholder="Applicant Search (Name, SSN last 4 digit, DOB)"  color="yellow" type="text" clearInput></IonInput>
                            </IonItem>       
                        </IonCol>
                        <IonCol size="2">
                            <IonItem lines="none">
                                <IonButton className="search-button" fill="clear" color="warning"onClick={(e) => onSearchClick(e)}>
                                    <IonIcon className="button-icon" slot="icon-only" icon={search}  />
                                </IonButton>
                            </IonItem>    
                        </IonCol>
                    </IonRow>
                    <IonRow class="ion-text-center">
 
                        <IonCol>
           
                            <IonButton className="photo-button" fill="solid" color="warning" onClick={(e) => openScanner()}>
                                <IonIcon className="button-icon" slot="icon-only" icon={qrCodeOutline} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol>
                        <div className="applicants-container">
                            {applicantsRequestStatus === fetchStatus.SUCCEEDED ? applicantCards : <GridLoader />}</div>
                    </IonCol>
                </IonRow>
                <IonToast
                    position="bottom"
                    header="Error"
                    color="danger"
                    isOpen={showNotFoundToast}
                    onDidDismiss={(e) => onToastNotFoundDismissed(e)}
                    message={"Applicant not found"}
                    duration={2000}
                />
            </IonContent>
        </IonPage>
    );
};

export default ScanApplicant;
