import { IonCard, IonCardContent, IonItem, IonLabel } from '@ionic/react';
import React from 'react';

function SignatureContainer(props) {
    return (
        <IonCard>
            <IonItem lines="none">
                <IonLabel>Please sign below</IonLabel>
            </IonItem>
            <IonCardContent>
                {props.children}
            </IonCardContent>
        </IonCard>
    );
}

export default SignatureContainer;