export const preWorkDisclaimerEN = {
    title: "Hirebase PRE-WORK ASSIGNMENT DISCLAIMER",
    content: [
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "All positions are temporary work assignments that may end at any time per the client’s request.",
                "As your employer you must notify us same day if there’s any personal or work-related problems affecting your work assignment or schedule so we may assist you.",
                "The client’s lead supervisor will train and manage you on your work assignment and safety protocol guidelines. If for whatever reason you cannot perform the assigned work you must notify the lead supervisor and us same day .",
                "Not following safety COVID-19 guidelines and deliberately working in an unsafe manner will result in disciplinary action and or termination.",
                "Displaying bad behavior, Discriminating, Harassing, Bullying or Teasing anyone during or after work hours will not be tolerated and will result in disciplinary action and or termination.",
                "If your assigned lead supervisor ends your assignment at any time for any reason you must report back to us the same day . DO NOT report back to the client site, failure to comply will affect any future work assignment eligibility."
            ]
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "ACKNOWLEDGMENT"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I ###name###, acknowledge and understand failure to comply with the above “Pre-Work Assignment Disclaimer” can and will result in immediate termination and removal of any future work assignments. I further understand employment with Hirebase is “AT WILL” and will always be on a temporary assignment."
        },
        {
            tag: "p",
            class: "",
            data: "Employee Signature"
        },
        {
            tag: "p",
            class: "",
            data: "Date"
        },
        {
            tag: "p",
            class: "",
            data: "SBS Representative Signature"
        },
        {
            tag: "p",
            class: "",
            data: "Date"
        }
    ]
}


export const preWorkDisclaimerSP = {
    title: "ANUNCIO DE EMPLEO PREVIO A LA ASIGNACIÓN DE TRABAJO EN Hirebase",
    content: [
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Todos los puestos de trabajo son temporales que pueden terminar en cualquier momento según la solicitud del cliente.",
                "Como su empleador, debe notificarnos el mismo día si hay algún problema personal o relacionado con el trabajo que afecte su puesto o horario de trabajo para que podamos ayudarlo.",
                "El supervisor principal del cliente lo(a) entrenará y administra sobre su asignación de trabajo y las pólizas de seguridad. Si por alguna razón no puede realizar el trabajo asignado, debe notificar al supervisor principal y a nosotros (la agencia) el mismo día.",
                "No seguir los reglamentos de seguridad de COVID-19 y trabajar deliberadamente de una manera insegura resultará en una acción disciplinaria o terminación de empleo.",
                "Mostrar mal comportamiento, discriminar, acosar, intimidar o burlarse de alguien durante o después del horario laboral no será tolerado y resultará en una acción disciplinaria o terminación de empleo.",
                "Si su supervisor principal asignado finaliza su asignación en cualquier momento por cualquier motivo, debe informarnos el mismo día. Y NO regrese al sitio del cliente , el incumplimiento afectará la posibilidad de cualquier asignación de trabajo en el futuro."
            ]
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "RECONOCIMIENTO"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I ###name###, reconozco y entiendo que el incumplimiento de la “ANUNCIO DE EMPLEO PREVIO A LA ASIGNACIÓN DE TRABAJO EN Hirebase.” puede resultar en la terminación inmediata y la eliminación de cualquier asignación laboral futura. Además, entiendo que el empleo con Hirebase es “VOLUNTARIO” y siempre será una asignación de trabajo temporal."
        },
        {
            tag: "p",
            class: "",
            data: "Firma del empleado(a)"
        },
        {
            tag: "p",
            class: "",
            data: "Fecha"
        },
        {
            tag: "p",
            class: "",
            data: "Firma del representante de SBS"
        },
        {
            tag: "p",
            class: "",
            data: "Fecha"
        }
    ]
}