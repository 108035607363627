import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow } from '@ionic/react';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { employeeHandBookEN, employeeHandBookSP } from './services/employeeHandbook';
import './css/concent.css';

function EmployeeHandbook(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(employeeHandBookEN);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(employeeHandBookSP);
        }
        else {
            setConcent(employeeHandBookEN);
        }
    }, [language]);

    let policies;
    policies = concent.content[11].data.map((item, index) => (
        <IonRow key={nanoid()}>
            <IonCol>
                <span className="font-weight-bold">{item}</span>
            </IonCol>
            <IonCol>
                <ApplySign
                
                buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}

                    signed={props.initialsP.includes(index)}
                    signature={props.initial}
                    onSigned={(value) => onPoliciesSigned(value, index)} />
            </IonCol>
        </IonRow>

    ));

    let safetyPolicies;
    safetyPolicies = concent.content[13].data.map((item, index) => (
        <IonRow key={nanoid()}>
            <IonCol>
                <span className="font-weight-bold">{item}</span>
            </IonCol>
            <IonCol>
                <ApplySign
                                 buttonText={language && language == "Spanish" ? "Inicial" :  "Initial"}
                                 signed={props.initialsSP.includes(index)}
                    signature={props.initial}
                    onSigned={(value) => onSafetyPoliciesSigned(value, index)} />
            </IonCol>
        </IonRow>

    ));

    const onPoliciesSigned = (value, index) => {
        props.onPoliciesSigned && props.onPoliciesSigned(value, index);
    };

    const onSafetyPoliciesSigned = (value, index) => {
        props.onSafetyPoliciesSigned && props.onSafetyPoliciesSigned(value, index);
    };

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{concent.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[0].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[1].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[2].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[3].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[4].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[5].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[6].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[7].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[8].data}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[9].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[10].data}
                        </p>
                    </IonCol>
                    <IonCol>
                        <p className="font-weight-bold">
                            ({concent.content[19].data})
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>

                        {policies}

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[12].data}
                        </p>
                    </IonCol>
                    <IonCol>
                        <p className="font-weight-bold">
                            ({concent.content[19].data})
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>

                        {safetyPolicies}

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[14].data}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="concent-label">
                            {concent.content[15].data}
                        </p>
                        <p className="concent-label-value">
                            {props.name}
                        </p>
                    </IonCol>
                    <IonCol>
                        <p className="concent-label">
                            {concent.content[16].data}
                        </p>
                        <p className="concent-label-value">
                            {props.ssn}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="concent-label">
                            {concent.content[17].data}
                        </p>
                        <p>
                            <ApplySign
                            buttonText = {language && language == "Spanish" ? "Firma" :  "Sign"}
                                signed={props.signed}
                                signature={props.signature}
                                onSigned={(value) => onSigned(value)} />
                        </p>
                    </IonCol>
                    <IonCol>
                        <p className="concent-label">
                            {concent.content[18].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="concent-label">
                            {concent.content[20].data}
                        </p>
                        <IonImg src={props.reviewerSignature} className="person-signature" />
                    </IonCol>
                    <IonCol>
                        <p className="concent-label">
                            {concent.content[18].data}
                        </p>
                        <p className="concent-label-value">
                            {(new Date()).toDateString()}
                        </p>
                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    );
}

export default EmployeeHandbook;