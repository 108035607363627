export const vaccinationPolicyEN = {
    title: "MANDATORY COVID-19 VACCINATION POLICY",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Effective: November 12, 2021"
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Purpose"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "The purpose of this policy is to facilitate protection of our workplace and safeguard the health of our employees, their families, our customers, and visitors from infectious diseases, such as COVID-19, that may be reduced by vaccinations. This policy will comply with all applicable laws and is based on guidance from the Centers for Disease Control and Prevention (CDC) and local health authorities, as applicable."
        },
        {
            tag: "p",
            class: "text-justify font-weight-bold",
            data: "Scope"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "All employees are required to receive a U.S. Food and Drug Administration-approved (whether full approval or subject to an Emergency Use Authorization) vaccination against COVID-19 infection as determined by CDC guidelines, unless a reasonable alternative accommodation (such as weekly Covid-19 routine testing) and/or exemption is approved. Hirebase, employees not in compliance by deadline with this policy will be placed as soon as there is availability at another temporary job assignment that may not require vaccination(s). In addition, employees will be required obtaining a NEGATIVE Covid-19 test result in a weekly basis. Proof of test will be required submitting weekly to Hirebase."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Procedures"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employees can request additional information from any Hirebase office as to the type of vaccinations covered by this policy and the time frames for having the vaccines administered. Hirebase, can provide a list of locations to assist employees in receiving the vaccine on their own. Employees can also visit "
        },
        {
            tag: "a",
            class: "text-justify",
            data: " covid19.ca.gov/vaccines "
        },
        {
            tag: "p",
            class: "text-justify",
            data: " for additional information."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "COVID-19 vaccinations are free, whether the employee has health insurance or not."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "All employees will be paid for time taken to receive vaccinations. For off-site vaccinations, employees are to coordinate with their managers to schedule appropriate time to comply with this policy."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Deadline"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "All employees must be fully vaccinated by November 12, 2021."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "An individual is considered “fully” vaccinated when two (2) weeks have passed since they completed a COVID-19 vaccine series (for example, one (1) dose of the Janssen/J&J vaccine, or two (2) doses within no more than 12 weeks of the Moderna or Pfizer vaccine); as well as any boosters consistent with manufacturer instructions and applicable agency approval, authorization, or listing."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Prior "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "to expiration of the stated compliance deadline, employees will be required to provide either proof of full vaccination (i.e., CDC vaccination card, official documentation issued by a state vaccine registry or official medical record) or an approved reasonable accommodation to be exempt from this policy. Proof of vaccination may be subject to audit. Please redact any private or confidential medical information prior to submitting your proof of vaccination."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Reasonable Accommodation"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employees seeking an exemption from this policy due to a medical reason, or because of a sincerely held religious belief or practice must complete the attached Covid-19 Accommodation Form along with a supporting document to a Hirebase office to begin the interactive accommodation process as soon as possible. Requests for Accommodation will be individually reviewed and, if appropriate, granted when such requests do not otherwise cause Hirebase undue hardship or pose a direct threat to the health and safety of others. Please direct any questions regarding this policy to anyone at your local Hirebase Office."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Thank you in advance for your understanding,"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Staffing & Human Resources Department"
        },
        {
            tag: "a",
            class: "text-justify",
            data: "hr@applyonboard.com"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "1-800-868-JOBS (5627)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Signature"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Date"
        }
    ]
}


export const vaccinationPolicySP = {
    title: "POLÍTICA OBLIGATORIA DE VACUNACIÓN CONTRA EL COVID-19",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Vigencia: 12 de noviembre de 2021"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Propósito"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "El propósito de esta política es facilitar la protección de nuestro lugar de trabajo y salvaguardar la salud de nuestros empleados, sus familias, nuestros clientes y visitantes de enfermedades infecciosas, como COVID-19, que pueden reducirse con las vacunas. Esta política cumplirá  con todas las leyes aplicables y se basa en  la orientación de los Centros para el Control y la Prevención de Enfermedades (CDC) y las autoridades de salud locales, según corresponda."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Alcance"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Se requiere que todos los empleados reciban una vacuna aprobada por la Administración de Alimentos y Medicamentos de los Estados Unidos (si la aprobación completa  o  está sujeta  a  una Autorización de Uso  de Emergencia)  contra la infección  por  COVID-19  según  lo determinado por las pautas de  los CDC,   a menos que  sea  razonable  se aprueba la adaptación alternativa (como   las pruebas de rutina semanales  de Covid-19) y / o la exención. Hirebase, los empleados que no  cumplan   con la fecha límite de  esta política se    colocarán  tan  pronto  como  haya    disponibilidad  en  otra  asignación de trabajo  temporal  que  puede  no  requerir  vacunación (s).  Además,los empleados  deberán  obtener un resultado NEGATIVO de la prueba de  Covid-19  semanalmente.   Se requerirá prueba de  prueba que se  envíe semanalmente a Hirebase."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Procedimientos"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Los  empleados pueden solicitar información  adicional a cualquier oficina de Hirebase en cuanto al    tipo  de  vacunas cubiertas    por  esta  póliza  y  los plazos para que se administren las  vacunas.     Hirebase,  Inc.,  puede  proporcionar  una  lista  de ubicaciones para ayudar a los empleados    a   recibir la  vacuna    por  su  cuenta. Los empleados  también  pueden visitar "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "covid19.ca.gov/vaccines "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "para obtener información adicional."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Las vacunas contra el COVID-19 son  gratuitas,    ya sea que  el  empleado  tenga  seguro de  salud  o  no."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "A todos los empleados  se  les pagará  por  el  tiempo necesario para recibir  las  vacunas. Para las vacunas fuera del sitio,   los  empleados   deben  coordinarse  con  sus  gerentes  para  programar el tiempo  apropiado  para  cumplir  con  esta  política."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha tope"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Todos los empleados  deben  estar  completamente  vacunados antes del 12 de noviembre  de  2021."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Una persona se considera “completamente” vacunada cuando han pasado dos  (2) semanas desde que completó una serie  de vacunas COVID-19 (por  ejemplo, una  (1)  dosis  de  la vacuna Janssen/J&J,    o  dos (2)  dosis  dentro de  no  más   de 12  semanas  de  la vacuna de Moderna  o  Pfizer);   así  como  cualquier amplificador consistente  con las instrucciones  del  fabricante y la aprobación,  autorización  o listado de la agencia aplicable."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Antes "
        },
        {
            tag: "p",
            class: "text-justify",
            data: "de que expire la fecha límite de cumplimiento establecida,  los  empleados deberán proporcionar cualquiera de las pruebas de vacunación completa  (es decir, tarjeta de vacunación   de los CDC,  documentación    oficial emitida por una vacuna estatal).  registro o registro médico oficial)  o  una adaptación razonable aprobada para estar exento de esta política.   La prueba  de  vacunación  puede  estar  sujeta  a  auditoría. Por favor,  redacte  cualquier  información  médica  privada  o  confidencial  antes de enviar su prueba  de  vacunación."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Adaptaciones razonables"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Los empleados  que buscan una exención    de  esta  política  debido a una  razón médica,  o debido a  una  creencia  o  práctica  religiosa  sincera  deben completar  el  Formulario  de  Acomodación  Covid-19  adjunto  junto    con  un  documento  de respaldo  a  una  oficina de Hirebase  para  comenzar  el  proceso   de acomodación  interactiva  lo  antes  posible. Las  solicitudes    de alojamiento se revisarán  individualmente  y, si  corresponde,  se otorgarán  cuando  dichas  solicitudes no   causen  de otro modo    Hirebase,  Inc.   dificultades excesivas o representan una amenaza directa para la salud y la seguridad de los demás. Dirija cualquier pregunta relacionada con esta política a cualquier persona en  su oficina local de seguridad de  Hirebase"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Gracias de antemano por su comprensión,"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Departamento de Dotación de Personal y Recursos Humanos"
        },
        {
            tag: "a",
            class: "text-justify",
            data: "hr@applyonboard.com"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "1-800-868-JOBS (5627)"
        },

        {
            tag: "p",
            class: "text-justify",
            data: "Nombre de Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Firma del Empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha"
        }
    ]
}