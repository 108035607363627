import React from 'react';

import { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonBadge,IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { selectLoggedInUser } from '../../accounts/reducerSlices/accountsSlice';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import { fetchStatus, tenantId } from '../../../app/api/client';
import { getAllQuestions, selectAllQuestions, selectQuestionsStatus, saveDocument } from '../reducerSlices/safetyTestSlice';
import { useHistory } from 'react-router';
import { isObjectArray } from '../../../app/utilities/utilityFunctions';
import Question from './question';
import { nanoid } from '@reduxjs/toolkit';
import VideoPlayer from '../../../components/controls/players/videoPlayer';
import { call, mail, location, informationCircle, layersOutline } from 'ionicons/icons';
import { selectApplicantById } from '../../applicant/reducerSlices/applicantSlice';
import { _getLocationText } from '../../../data/masterData';
import '../css/sexualHarassTraining.css';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Language } from '../../../app/common/constants';

import { Screenshot } from '@ionic-native/screenshot';
import { Offline, Online, Detector } from "react-detect-offline";


function SexualHarassTraining(props) {
    const { id, lang } = props.match.params;
    const history = useHistory();
    const user = useSelector(selectLoggedInUser);
    const [photoData, setPhotoData] = useState("");
    const dispatch = useDispatch();
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };


    const onUploadClicked = async (e) => {
       
        const imgURI = await Screenshot.URI(50);
        alert(typeof(imgURI));
        console.log(imgURI);
   
        let documentId = nanoid();
        const fileDetails = {
        length: 4,
        fileContent: imgURI.URI,
            documentData: {
                id: documentId,
                UploadedOn: (new Date()).toUTCString(),
                DocumentName: documentId + "_" + 'Sexual Harassment Certificate Non Supervisor.jpg',
                DocumentType: 5,
                DocumentTypeText: 'Other',
                Note: 'Sexual Harassment Certificate Non Supervisor',
                UploadedBy: (user ? user.firstName + " " + user.lastName : ""),
                UploadedFileInfo: ""
            }
        };
        
        await dispatch(saveDocument({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEDOCUMENT",
                payload: {
                    id: id,
                    data: JSON.stringify(fileDetails)
                }
        }));
    };
        //alert(data);
      
    

    const savePicture = async (photo, fileName) => {
        let base64Data = await base64FromPath(photo.webPath);
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: base64Data
        };
    };

    const applicant = useSelector((state) => selectApplicantById(state, id));
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                   <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
            
        
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                    
                        <iframe className="myframe" src= {applicant && (applicant.Language === Language.SPANISH || applicant.Language === Language.SP) ? "https://sexual-harassment-prevention-training.dfeh.ca.gov/NonSupervisorySpanish/story.html" : "https://sexual-harassment-prevention-training.dfeh.ca.gov/NonSupervisoryEnglish/story.html" }></iframe>

                        
                    </IonCol>
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {fetchStatus.SUCCEEDED !== fetchStatus.LOADING && fetchStatus.SUCCEEDED !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    onClick={(e) => onUploadClicked(e)}  >
                                   Upload Certificate
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {fetchStatus.SUCCEEDED !== fetchStatus.LOADING && fetchStatus.SUCCEEDED !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => history.push(`/trainingHome/${id}`)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                      
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
}

export default SexualHarassTraining;