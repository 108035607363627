import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isObjectArray } from '../../../app/utilities/utilityFunctions';
import Radio from '../../../components/controls/checkers/radio';
import { selectSafetyTestDetail, updateSafetyTestDetail } from '../reducerSlices/safetyTestSlice';
import { onValueChanged, sections } from '../services/testResponseSaver';

function Options(props) {

    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);

    const safetyTestDetail = useSelector(selectSafetyTestDetail);

    let _options = [];
    useEffect(() => {
        if (isObjectArray(props.options)) {
            for (let i = 0; i < props.options.length; i++) {
                let _option = props.options[i];
                const _id = nanoid();
                if (_option && _option.Text) {
                    _options.push(<Radio
                        key={_id}
                        id={`rdbtn-${_id}`}
                        name={`options$$$${props.question.id}`}
                        label={_option.Text[props.forLanguage]}
                        value={_option.Value}
                        checked={props.question.Response === _option.Value}
                        onChange={(e, v) => onValueUpdated(e, sections.TEST_RESPONSE_DETAILS, v)}
                    //disabled={props.response}
                    />);
                }
            }
            setOptions(_options);
        }
    }, [props.options]);

    const onValueUpdated = (e, section, value) => {
        let _safetyTestDetailObj = Object.assign({}, safetyTestDetail);
        let _newSafetyTestDetailObj = onValueChanged(_safetyTestDetailObj, e, section, value);
        dispatch(updateSafetyTestDetail(_newSafetyTestDetailObj));
    };

    return (
        <div>
            {options}
        </div>
    );
}

export default Options;