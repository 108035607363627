import {
    IonItem, IonLabel, IonList, 
    IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
    IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput,
    IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonBadge
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectLoggedInUser } from '../../accounts/reducerSlices/accountsSlice';
import { selectApplicantById } from '../../applicant/reducerSlices/applicantSlice';
import { call, mail, location, informationCircle } from 'ionicons/icons';
import { fetchStatus } from '../../../app/api/client';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import { _getLocationText } from '../../../data/masterData';

import '../css/trainings.css';
import { TrainingResult, TrainingStatus } from '../../../app/common/constants';
import { Offline, Online, Detector } from "react-detect-offline";



function Trainings(props) {
    const { id } = props.match.params;
    const history = useHistory();
    const user = useSelector(selectLoggedInUser);
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };


    const _getBadgeColor = (training) => {
        if (training) {
            if (training.Result === TrainingResult.UNKNOWN) {
                return "medium";
            }
            else if (training.Result === TrainingResult.FAILED) {
                return "danger";
            }
            else if (training.Result === TrainingResult.PASSED) {
                return "success";
            }
            else {
                return "medium";
            }
        }
        return "medium";
    };

    const _getButtonText = (training) => {
        if (training) {
            if (training.Result === TrainingResult.UNKNOWN) {
                return "Attempt";
            }
            else if (training.Result === TrainingResult.FAILED) {
                return "Re-attempt";
            }
            else if (training.Result === TrainingResult.PASSED) {
                return "";
            }
            else {
                return "Attempt";
            }
        }
        return "Attempt";
    };

    const _getButtonColor = (training) => {
        if (training) {
            if (training.Result === TrainingResult.UNKNOWN) {
                return "primary";
            }
            else if (training.Result === TrainingResult.FAILED) {
                return "danger";
            }
            else if (training.Result === TrainingResult.PASSED) {
                return "";
            }
            else {
                return "primary";
            }
        }
        return "primary";
    };

    const onBackClicked = (e) => {
        history.push(`/uploadDetails/${id}`)
    }
    const applicant = useSelector((state) => selectApplicantById(state, id));

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                   <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {applicant.FirstName + " " + applicant.LastName}
                                </IonCol>
                                <IonCol className="ion-text-center">
                                    <IonButton
                                        fill="solid"
                                        color="medium"
                                        onClick={(e) => onBackClicked(e)}>
                                        Back
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{applicant.Email}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{applicant.PhoneNumber}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{_getLocationText(applicant.LocationId)}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{applicant.Status}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                List Of Trainings
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonList>
                                        <IonItem>
                                            <IonLabel>
                                                <h2><strong>Safety Training</strong></h2>
                                                <IonBadge
                                                    className="mt-1"
                                                    color={applicant && applicant.Trainings ? _getBadgeColor(applicant.Trainings.Safety) : "medium"}>
                                                    {applicant && applicant.Trainings && applicant.Trainings.Safety ? applicant.Trainings.Safety.Result : "Not Attempted"}
                                                    {applicant && applicant.Trainings && applicant.Trainings.Safety ? " : " + applicant.Trainings.Safety.Score + " %" : ""}
                                                </IonBadge>
                                            </IonLabel>
                                            <IonButton
                                                fill="solid"
                                                color={applicant && applicant.Trainings ? _getButtonColor(applicant.Trainings.Safety) : "primary"}
                                                onClick={(e) => history.push(`/safetyTraining/${id}`)}>
                                                {applicant && applicant.Trainings ? _getButtonText(applicant.Trainings.Safety) : "Attempt"}
                                            </IonButton>
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel>
                                                <h2><strong>Sexual Harassment Training</strong></h2>
                                                <IonBadge
                                                    className="mt-1"
                                                    color={applicant && applicant.Trainings ? _getBadgeColor(applicant.Trainings.SexualHarassment) : "medium"}>
                                                    {applicant && applicant.Trainings && applicant.Trainings.SexualHarassment ? applicant.Trainings.SexualHarassment.Result : "Not Attempted"}
                                                    {applicant && applicant.Trainings && applicant.Trainings.SexualHarassment ? " : " + applicant.Trainings.SexualHarassment.Score + " %" : ""}
                                                </IonBadge>
                                            </IonLabel>
                                            <IonButton
                                                fill="solid"
                                                color={applicant && applicant.Trainings ? _getButtonColor(applicant.Trainings.SexualHarassment) : "primary"}
                                                onClick={(e) => history.push(`/sexualHarassTraining/${id}`)}>
                                                {applicant && applicant.Trainings ? _getButtonText(applicant.Trainings.SexualHarassment) : "Attempt"}
                                            </IonButton>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage >

    );
}

export default Trainings;