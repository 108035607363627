import * as React from 'react';

import './css/radio.css';

function Radio(props) {
    return (
        <div className="radio">
            <input
                type="radio"
                name={props.name}
                id={props.id}
                value={props.value}
                onChange={(e) => props.onChange && props.onChange(e, props.value)}
                checked={props.checked}
                disabled={props.disabled} />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}

export default Radio;