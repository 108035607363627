import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonImg, IonRow, IonItem, IonLabel, IonDatetime, IonCheckbox, IonGrid, IonButton, IonIcon, IonToggle } from '@ionic/react';
import { camera } from 'ionicons/icons';

import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Language } from '../../app/common/constants';
import ApplySign from '../controls/applySign';
import { vaccineAccomodationEN, vaccineAccomodationSP } from './services/vaccineAccomodation';
import './css/concent.css';

import { useDispatch, useSelector } from 'react-redux';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { selectSingleStatus as applicantStatus } from '../../pages/applicant/reducerSlices/applicantSlice';
import nophoto from '../../assets/nophoto.png';
import vaccinationcard from '../../assets/vaccination-card.png';
import { fetchStatus } from '../../app/api/client';
import { useCamera } from '@ionic/react-hooks/camera';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { base64FromPath } from '@ionic/react-hooks/filesystem';

function VaccineAccomodation(props) {
    const [language, setLanguage] = useState("English");
    const [concent, setConcent] = useState(vaccineAccomodationEN);

    // const [checkedIAmSeeking, setCheckedIAmSeeking] = useState(false);
    // const [receivedDateValue, setReceivedDateValue] = useState("");

    //
    const applicantRequestStatus = useSelector(applicantStatus);
    const [photoData, setPhotoData] = useState("");
    const [photoURL, setPhotoURL] = useState("");
    const { getPhoto } = useCamera();

    const [photoDataDoctorNote, setPhotoDataDoctorNote] = useState("");
    const [photoURLDoctorNote, setPhotoURLDoctorNote] = useState("");

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language);
        }
    }, [props.language]);

    useEffect(() => {
        if (language === Language.SPANISH) {
            setConcent(vaccineAccomodationSP);
        }
        else {
            setConcent(vaccineAccomodationEN);
        }
    }, [language]);

    let items;
    items = concent.content[3].data.map((item, index) => (
        <li key={nanoid()}>{item}</li>
    ));

    const onSigned = (value) => {
        props.onSigned && props.onSigned(value);
    };

    const onVASigned = (value, index) => {
        props.onVASigned && props.onVASigned(value, index);
    };

    const _iWantToBeVaccinated = (e) => {
        props.onCheckedIWantToBeVaccinated && props.onCheckedIWantToBeVaccinated(e.detail.checked);
        props.onVaccinatedDateValue && props.onVaccinatedDateValue('');
        props.onCheckedPractice && props.onCheckedPractice(false);
        if (e.detail.checked === true) {
            props.onCheckedIAmInTheProcess && props.onCheckedIAmInTheProcess(false);
            props.onCheckedIAmFullyVaccinated && props.onCheckedIAmFullyVaccinated(false);
            props.onCheckedIDeclineToBeVaccinated && props.onCheckedIDeclineToBeVaccinated(false);
        }
    }

    const _iAmInTheProcess = (e) => {
        props.onCheckedIAmInTheProcess && props.onCheckedIAmInTheProcess(e.detail.checked);
        props.onCheckedPractice && props.onCheckedPractice(false);
        if (e.detail.checked === true) {
            props.onCheckedIWantToBeVaccinated && props.onCheckedIWantToBeVaccinated(false);
            props.onCheckedIAmFullyVaccinated && props.onCheckedIAmFullyVaccinated(false);
            props.onCheckedIDeclineToBeVaccinated && props.onCheckedIDeclineToBeVaccinated(false);
        }
    }

    const _iAmFullyVaccinated = (e) => {
        props.onCheckedIAmFullyVaccinated && props.onCheckedIAmFullyVaccinated(e.detail.checked);
        props.onVaccinatedDateValue && props.onVaccinatedDateValue('');
        if (e.detail.checked === true) {
            props.onCheckedIWantToBeVaccinated && props.onCheckedIWantToBeVaccinated(false);
            props.onCheckedIAmInTheProcess && props.onCheckedIAmInTheProcess(false);
            props.onCheckedIDeclineToBeVaccinated && props.onCheckedIDeclineToBeVaccinated(false);
        }
    }

    const _iDeclineToBeVaccinated = (e) => {
        props.onCheckedIDeclineToBeVaccinated && props.onCheckedIDeclineToBeVaccinated(e.detail.checked);
        props.onCheckedOption1 && props.onCheckedOption1(false);
        props.onCheckedOption2 && props.onCheckedOption2(false);
        props.onCheckedOption3 && props.onCheckedOption3(false);
        props.onCheckedIAmSeeking && props.onCheckedIAmSeeking(false);
        props.onReceivedDateValue && props.onReceivedDateValue('');
        props.onVaccinatedDateValue && props.onVaccinatedDateValue('');
        props.onCheckedPractice && props.onCheckedPractice(false);
        if (e.detail.checked === true) {
            props.onCheckedIWantToBeVaccinated && props.onCheckedIWantToBeVaccinated(false);
            props.onCheckedIAmInTheProcess && props.onCheckedIAmInTheProcess(false);
            props.onCheckedIAmFullyVaccinated && props.onCheckedIAmFullyVaccinated(false);
        }
    }

    const handleCheckedOption1 = (e) => {
        props.onCheckedOption1 && props.onCheckedOption1(e.detail.checked);
        props.onCheckedPractice && props.onCheckedPractice(false);
        if (e.detail.checked === true) {
            props.onCheckedOption2 && props.onCheckedOption2(false);
            props.onCheckedOption3 && props.onCheckedOption3(false);
            props.onCheckedIAmSeeking && props.onCheckedIAmSeeking(false);
        }
    };

    const handleCheckedOption2 = (e) => {
        props.onCheckedOption2 && props.onCheckedOption2(e.detail.checked);
        if (e.detail.checked === true) {
            props.onCheckedOption1 && props.onCheckedOption1(false);
            props.onCheckedOption3 && props.onCheckedOption3(false);
            props.onCheckedIAmSeeking && props.onCheckedIAmSeeking(false);
            props.onReceivedDateValue && props.onReceivedDateValue('');
        }
    };

    const handleCheckedOption3 = (e) => {
        props.onCheckedOption3 && props.onCheckedOption3(e.detail.checked);
        props.onCheckedPractice && props.onCheckedPractice(false);
        if (e.detail.checked === true) {
            props.onCheckedOption1 && props.onCheckedOption1(false);
            props.onCheckedOption2 && props.onCheckedOption2(false);
            props.onReceivedDateValue && props.onReceivedDateValue('');
        }
    };

    const handleonPractice = (e) => {
        props.onCheckedPractice && props.onCheckedPractice(e.detail.checked);
    }

    const onPhotoSelectedBegingVaccinated = (e) => {
        takePhotoBegingVaccinated();
    };

    const takePhotoBegingVaccinated = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;
        setPhotoData(newPhoto.data);
        setPhotoURL(newPhoto.webviewPath);
        props.onPhotoDataBeingVaccinated(newPhoto.data);
        props.onPhotoURLBeingVaccinated(newPhoto.webviewPath);
    };

    const savePicture = async (photo, fileName) => {
        let base64Data = await base64FromPath(photo.webPath);
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            data: base64Data
        };
    };

    const onPhotoSelectedDoctorNote = (e) => {
        takePhotoDoctorNote();
    }

    const takePhotoDoctorNote = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;
        setPhotoData(newPhoto.data);
        setPhotoURL(newPhoto.webviewPath);
        props.onPhotoDataDoctorNote(newPhoto.data);
        props.onPhotoURLDoctorNote(newPhoto.webviewPath);
    };

    const onPhotoSelectedFullyVaccinated = (e) => {
        takePhotoFullyVaccinated();
    }

    const takePhotoFullyVaccinated = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 20
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhoto = savedFileImage;
        setPhotoData(newPhoto.data);
        setPhotoURL(newPhoto.webviewPath);
        props.onPhotoDataFullyVaccinated(newPhoto.data);
        props.onPhotoURLFullyVaccinated(newPhoto.webviewPath);
    };


    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{concent.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[0].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold text-decoration-underline">
                            {concent.content[1].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[2].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <ul>
                            {items}
                        </ul>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p className="font-weight-bold">
                            {concent.content[4].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[5].data.replace("###employeeName###", props.name)}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p >
                            {concent.content[6].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonCheckbox class='floating' checked={props.checkedIWantToBeVaccinated} onIonChange={e => _iWantToBeVaccinated(e)} />
                            <span className="font-weight-bold ion-margin-start">{concent.content[7].data}</span>
                        </IonItem>
                        <p>
                            {concent.content[8].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <a>
                            {concent.content[9].data}
                        </a>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonCheckbox class='floating' checked={props.checkedIAmInTheProcess} onIonChange={e => _iAmInTheProcess(e)} />
                            <span className="font-weight-bold ion-margin-start">{concent.content[10].data}</span>
                        </IonItem>
                        <p>
                            {concent.content[11].data}
                        </p>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <p>
                            {concent.content[12].data}
                        </p>
                    </IonCol>
                </IonRow>

                {
                    props.checkedIAmInTheProcess ?

                        <IonRow>
                            

                            <IonCol size="6">
                            <span className="font-weight-bold ion-margin-start">Select Date</span>
                                <IonItem lines="none">
                                    <IonDatetime displayFormat="MM/DD/YYYY" name="txtReceivedDate" placeholder="Select Date" onIonChange={e => props.onVaccinatedDateValue && props.onVaccinatedDateValue(e.detail.value)} ></IonDatetime>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        : ''
                }

                {
                    props.checkedIAmInTheProcess ?

                        <IonCard>
                            <div className="photo-container">
                                {applicantRequestStatus !== fetchStatus.LOADING ? <IonImg className="photo" src={photoURL || vaccinationcard} />
                                    : <></>}
                            </div>

                            <IonCardContent>
                                <IonGrid>
                                    <IonRow class="ion-text-center">
                                        <IonCol>
                                            {applicantRequestStatus !== fetchStatus.LOADING ? <IonButton className="photo-button" fill="solid" color="primary" onClick={(e) => onPhotoSelectedBegingVaccinated(e)}>
                                                <IonIcon className="button-icon" slot="icon-only" icon={camera} />

                                            </IonButton> : <EllipsisLoader />}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>




                        : ''
                }



                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonCheckbox class='floating' checked={props.checkedIAmFullyVaccinated} onIonChange={e => _iAmFullyVaccinated(e)} />
                            <span className="font-weight-bold ion-margin-start">{concent.content[13].data}</span>
                        </IonItem>
                        <p>
                            {concent.content[14].data}
                        </p>
                    </IonCol>
                </IonRow>

                {
                    props.checkedIAmFullyVaccinated ?

                        <IonCard>
                            <div className="photo-container">
                                {applicantRequestStatus !== fetchStatus.LOADING ? <IonImg className="photo" src={photoURL || vaccinationcard} />
                                    : <></>}
                            </div>

                            <IonCardContent>
                                <IonGrid>
                                    <IonRow class="ion-text-center">
                                        <IonCol>
                                            {applicantRequestStatus !== fetchStatus.LOADING ? <IonButton className="photo-button" fill="solid" color="primary" onClick={(e) => onPhotoSelectedFullyVaccinated(e)}>
                                                <IonIcon className="button-icon" slot="icon-only" icon={camera} />

                                            </IonButton> : <EllipsisLoader />}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>

                        : ''
                }

                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonCheckbox class='floating' checked={props.checkedIDeclineToBeVaccinated} onIonChange={e => _iDeclineToBeVaccinated(e)} />
                            <span className="font-weight-bold ion-margin-start">{concent.content[15].data}</span>
                        </IonItem>
                        <p>
                            {concent.content[16].data}
                        </p>
                    </IonCol>
                </IonRow>

                {
                    props.checkedIDeclineToBeVaccinated ?

                        <IonRow>
                            <IonCol>
                                <IonItem lines="none">
                                    <IonCheckbox class='floating' checked={props.checkedOption1} onIonChange={e => handleCheckedOption1(e)} />
                                    <span className="ion-margin-start">{concent.content[17].data.slice(9)}</span>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonCheckbox class='floating' checked={props.checkedOption2} onIonChange={e => handleCheckedOption2(e)} />
                                    <span className="ion-margin-start">{concent.content[49].data.slice(9)}</span>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonCheckbox class='floating' checked={props.checkedOption3} onIonChange={e => handleCheckedOption3(e)} />
                                    <span className="ion-margin-start">{concent.content[67].data.slice(9)}</span>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        : ''
                }

                {
                    props.checkedIDeclineToBeVaccinated && props.checkedOption1 ?
                        <>
                            <IonRow>
                                <IonCol>
                                    <p>
                                        {/* ……………………………………………………… Part 2 HTML ………………………………………………………………………… */}
                                    </p>
                                </IonCol>
                            </IonRow>




                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold" >
                                        {concent.content[17].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[18].data.replace("###name###", props.name)}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[19].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[20].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[21].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[22].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[23].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[24].data}
                                    </p>
                                    <ApplySign
                                        buttonText={language && language == "Spanish" ? "Firma" : "Sign"}
                                        SignClasses="d-inline-block"
                                        signed={props.signed.includes(0)}
                                        signature={props.signature}
                                        onSigned={(value) => onVASigned(value, 0)} />

                                </IonCol>

                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[25].data}
                                    </p>
                                    <p className="concent-label-value">
                                        {(new Date()).toDateString()}
                                    </p>
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol>
                                    <p>
                                        {/* ……………………………………………………… Part 3 HTML ………………………………………………………………………… */}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        <span className="font-weight-bold">{concent.content[26].data}</span>
                                        {concent.content[27].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[28].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        <u><i>{concent.content[29].data}</i></u>
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[30].data.replace("###nameOfPatient###", props.name)}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[31].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[32].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {/* Healthcare Provider’s Name (print): */}
                                        {concent.content[33].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[34].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[35].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[36].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[37].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[38].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[39].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        …………………………………………………………………………………………………………
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[40].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[41].data}
                                    </p>
                                    <IonCol size="6">

                                        <IonItem lines="none">
                                            <IonDatetime displayFormat="MM/DD/YYYY" name="txtReceivedDate" placeholder="Select Date" onIonChange={e => props.onReceivedDateValue && props.onReceivedDateValue(e.detail.value)} ></IonDatetime>
                                        </IonItem>
                                    </IonCol>

                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[42].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[43].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[44].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[45].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[46].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[47].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[48].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            {/* doctor's note  */}
                            <IonCard>
                                <div className="photo-container">
                                    {applicantRequestStatus !== fetchStatus.LOADING ? <IonImg className="photo" src={photoURL || vaccinationcard} />
                                        : <></>}
                                </div>

                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow class="ion-text-center">
                                            <IonCol>
                                                {applicantRequestStatus !== fetchStatus.LOADING ? <IonButton className="photo-button" fill="solid" color="primary" onClick={(e) => onPhotoSelectedDoctorNote(e)}>
                                                    <IonIcon className="button-icon" slot="icon-only" icon={camera} />

                                                </IonButton> : <EllipsisLoader />}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </>
                        : ''
                }

                {
                    props.checkedIDeclineToBeVaccinated && props.checkedOption2 ?
                        <>
                            <IonRow>
                                <IonCol>
                                    <p>
                                        {/* ……………………………………………………… Part 4 HTML ………………………………………………………………………… */}
                                    </p>
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[49].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[50].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[51].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[52].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[53].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[54].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[55].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[56].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem lines="none">
                                        {props.checkedPractice && <IonLabel>{concent.content[57].data} </IonLabel>}
                                        {!props.checkedPractice && <IonLabel>No</IonLabel>}
                                        <IonToggle name="expType" checked={props.checkedPractice} onIonChange={e => handleonPractice(e)} slot="start" color="primary" ></IonToggle>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[58].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[59].data}
                                    </p>
                                    {/* <ApplySign
                            buttonText={language && language == "Spanish" ? "Firma" : "Sign"}
                            signed={props.signed}
                            signature={props.signature}
                            onSigned={(value) => onSigned(value)} /> */}
                                    <ApplySign
                                        buttonText={language && language == "Spanish" ? "Firma" : "Sign"}
                                        SignClasses="d-inline-block"
                                        signed={props.signed.includes(1)}
                                        signature={props.signature}
                                        onSigned={(value) => onVASigned(value, 1)} />

                                </IonCol>

                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[60].data}
                                    </p>
                                    <p className="concent-label-value">
                                        {(new Date()).toDateString()}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[61].data}
                                    </p>
                                    <IonImg src={props.reviewerSignature} className="person-signature" />
                                    {/* <ApplySign
                            buttonText={language && language == "Spanish" ? "Firma" : "Sign"}
                            // signed={props.signed}
                            // signature={props.signature}
                            onSigned={(value) => onSigned(value)} /> */}

                                </IonCol>

                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[60].data}
                                    </p>
                                    <p className="concent-label-value">
                                        {(new Date()).toDateString()}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[62].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[63].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[64].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[65].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[66].data}
                                    </p>
                                </IonCol>
                            </IonRow>
                        </>
                        : ''
                }

                {
                    props.checkedIDeclineToBeVaccinated && props.checkedOption3 ?
                        <>
                            <IonRow>
                                <IonCol>
                                    <p>
                                        {/* ……………………………………………………… Part 5 HTML ………………………………………………………………………… */}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[67].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        {concent.content[68].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p>
                                        <IonCheckbox checked={props.checkedIAmSeeking} onIonChange={e => props.onCheckedIAmSeeking && props.onCheckedIAmSeeking(e.detail.checked)} />
                                        <span className="ion-margin-start">{concent.content[69].data}</span>
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[70].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[71].data}
                                    </p>
                                    <ApplySign
                                        buttonText={language && language == "Spanish" ? "Firma" : "Sign"}
                                        SignClasses="d-inline-block"
                                        signed={props.signed.includes(2)}
                                        signature={props.signature}
                                        onSigned={(value) => onVASigned(value, 2)} />
                                </IonCol>

                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[72].data}
                                    </p>
                                    <p className="concent-label-value">
                                        {(new Date()).toDateString()}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[73].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            {/* <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[74].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[75].data}
                                    </p>
                                </IonCol>
                            </IonRow> */}

                            <IonRow>
                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[75].data}
                                    </p>
                                    <IonImg src={props.reviewerSignature} className="person-signature" />
                                </IonCol>

                                <IonCol size="6">
                                    <p className="concent-label">
                                        {concent.content[74].data}
                                    </p>
                                    <p className="concent-label-value">
                                        {(new Date()).toDateString()}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[76].data}
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p className="font-weight-bold">
                                        {concent.content[77].data}
                                    </p>
                                </IonCol>
                            </IonRow>
                        </>
                        : ''
                }

            </IonCardContent>


        </IonCard >
    );
}

export default VaccineAccomodation;