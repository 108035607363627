import { unwrapResult } from "@reduxjs/toolkit";
import { USER_KEY } from "../../../app/common/constants";
import store from '../../../app/store';
import { login, refreshToken } from '../reducerSlices/accountsSlice';

export const accountService = {
    isAuthenticated: () => {
        return localStorage.getItem(USER_KEY) ? true : false;
        //return true;
    },
    getAuthenticatedUser: () => {
        let user;
        if (accountService.isAuthenticated()) {
            user = JSON.parse(localStorage.getItem(USER_KEY));
        }
        return user || { id: "" };
    },
    login: async (loginModel) => {
        const result = await store.dispatch(login(loginModel));
        const data = unwrapResult(result);
        if (data && data.id) {
            // localStorage.setItem(USER_KEY, JSON.stringify(data));
            // const user = accountService.getAuthenticatedUser();
            //startRefreshTokenTimer(user);
        }
    },
    logout: () => {
        if (accountService.isAuthenticated()) {
            //stopRefreshTokenTimer();
            localStorage.removeItem(USER_KEY);
        }
    },
    getToken: () => {
        const user = accountService.getAuthenticatedUser();
        return user ? `Bearer fake-jwt-token ${user.jwtToken}` : "";
    }//,
    // refreshTokenAsync: async () => {
    //     if (accountService.isAuthenticated()) {
    //         const user = accountService.getAuthenticatedUser();
    //         if (user) {
    //             const result = await store.dispatch(refreshToken({ id: user.id }));
    //             const data = unwrapResult(result);
    //             if (data && data.id) {
    //                 startRefreshTokenTimer(user);
    //             }
    //         }
    //     }
    // }
}

let refreshTokenTimeout;

const startRefreshTokenTimer = (user) => {
    if (user) {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(user.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(accountService.refreshTokenAsync, timeout);
    }
}

const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
}
