import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../app/api/client';
import { getAxiosRequestConfig } from '../../../app/common/common';
import { isJSON } from '../../../app/utilities/utilityFunctions';

const initialState = {
    data: [],
    status: fetchStatus.IDLE,
    error: null,
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        signature: "",
        initial: ""
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    }, 
    Language:"English"
};

export const getAllApplicants = createAsyncThunk('applicants/getAllApplicants', async (applicantModel) => {
    const response = await BajoAPI.post('Gateway', applicantModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let applicants;
    if (isJSON(data)) {
        applicants = JSON.parse(data);
    }
    return applicants;
});

export const getApplicantById = createAsyncThunk('applicants/getApplicantById', async (applicantModel) => {
    const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let applicant = undefined;
    if (data && isJSON(data)) {
        applicant = JSON.parse(data);
    }
    return applicant;
});

export const createApplicant = createAsyncThunk('applicants/createApplicant', async (applicantModel) => {
    const response = await BajoAPI.post('Gateway', applicantModel, getAxiosRequestConfig());
    return response.data.applicant;
});

export const updateApplicant = createAsyncThunk('applicants/updateApplicant', async (applicantModel) => {
    const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let applicant = undefined;
    if (data && isJSON(data)) {
        applicant = JSON.parse(data);
    }
    return applicant;
});

export const removeApplicant = createAsyncThunk('applicants/removeApplicant', async (applicantId) => {
    await BajoAPI.delete(`Applicants/${applicantId}`, getAxiosRequestConfig());
    return { id: applicantId };
});

export const applicantSlice = createSlice({
    name: 'applicants',
    initialState,
    reducers: {
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateLanguage : (state, lang) => {
            state.Language = lang;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllApplicants.pending, (state, action) => {
            state.status = fetchStatus.LOADING;
        }).addCase(getAllApplicants.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = fetchStatus.SUCCEEDED;
        }).addCase(getAllApplicants.rejected, (state, action) => {
            state.status = fetchStatus.FAILED;
            state.error = action.error.message;
        }).addCase(getApplicantById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getApplicantById.fulfilled, (state, action) => {
            state.single.data = action.payload.applicant;
            state.single.signature = action.payload.signature;
            state.single.initial = action.payload.initial;
            state.single.status = fetchStatus.SUCCEEDED;
        }).addCase(getApplicantById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
        }).addCase(createApplicant.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createApplicant.fulfilled, (state, action) => {
            state.data.push(action.payload);
            state.creation.status = fetchStatus.SUCCEEDED;
        }).addCase(createApplicant.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
        }).addCase(updateApplicant.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateApplicant.fulfilled, (state, action) => {
            if (action.payload) {
                state.single.data = action.payload.applicant;
                state.single.signature = action.payload.signature;
                state.single.initial = action.payload.initial;
            }
            state.modification.status = fetchStatus.SUCCEEDED;
        }).addCase(updateApplicant.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
        }).addCase(removeApplicant.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeApplicant.fulfilled, (state, action) => {
            const index = state.data.findIndex(applicant => applicant.id === action.payload.id);
            state.data.splice(index, 1);
            state.removal.status = fetchStatus.SUCCEEDED;
        }).addCase(removeApplicant.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
        });
    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus, updateSingleStatus } = applicantSlice.actions;

export default applicantSlice.reducer

export const selectAllApplicants = state => {
    return state.applicants.data;
}

export const selectStatus = state => state.applicants.status;

export const selectError = state => state.applicants.error;

export const selectCreationStatus = state => state.applicants.creation.status;

export const selectModificationStatus = state => state.applicants.modification.status;

export const selectRemovalStatus = state => state.applicants.removal.status;

export const selectCreationError = state => state.applicants.creation.error;

export const selectModificationError = state => state.applicants.modification.error;

export const selectRemovalError = state => state.applicants.removal.error;

export const selectApplicantById = (state, applicantId) => {
    return state.applicants.single ? state.applicants.single.data : undefined;
}

export const selectSignature = state => state.applicants.single.signature;

export const selectIntial = state => state.applicants.single.initial;

export const selectSingleStatus = state => state.applicants.single.status;

export const selectSingleError = state => state.applicants.single.error;

export const selectLanguage = state => state.applicants.Language;