export const disclaimerEN = {
    title: "CANDIDATE DISCLAIMER AND ACKNOWLEDGEMENT",
    content: [
        {
            tag: "p",
            class: "font-weight-bold",
            data: "CANDIDATE DISCLAIMER AND ACKNOWLEDGEMENT"
        },
        {
            tag: "p",
            data: "I ###name### acknowledge that in the event that I am offered employment with Hirebase. the said employment shall be on an at-will and temporary assignment basis. At will within the meaning of the law refers to an employment arrangement that may be terminated with or without cause, and temporary assignment basis means that the duration of my employment is dependent upon an assignment and the duration of that assignment, when an assignment ends, so does the employment."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "I ###name### under penalty of perjury declare that the information provided herein are true to the best of my recollection, and my signature below confirms the same."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Candidate’s Name"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Candidate’s Signature"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Date"
        }
    ]
}

export const disclaimerSP = {
    title: "DESCARGO DE RESPONSABILIDAD Y RECONOCIMIENTO DEL CANDIDATO",
    content: [
        {
            tag: "p",
            class: "font-weight-bold",
            data: "DESCARGO DE RESPONSABILIDAD Y RECONOCIMIENTO DEL CANDIDATO:"
        },
        {
            tag: "p",
            data: "Yo ###name### reconosco que en el caso de que se me ofrezca empleo en Hirebase. dicho empleo será de manera temporal y a voluntad. A voluntad en el sentido de la ley se refiere a un acuerdo de empleo que puede ser terminado con o sin causa, y la base de asignación temporal significa que la duración de mi empleo depende de una asignación y la duración de esa asignación, cuando una asignación termina, también lo hace el empleo."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Yo ###name### bajo pena de perjurio declaro que la información proporcionada en este documento es fiel a lo mejor de mi recuerdo, y mi firma a continuación confirma la misma."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Nombre del candidato"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Firma del candidato"
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "Fecha"
        }
    ]
}