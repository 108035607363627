export const noticeToEmployeeEN = {
    title: "NOTICE TO EMPLOYEE",
    content: [

        {
            tag: "p",
            class: "text-justify",
            data: "Labor Code section 2810.5"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "EMPLOYEE"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Employee Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Start Date"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "EMPLOYER"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Legal Name of Hiring Employer"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Is hiring employer a staffing agency/business (e.g., Temporary Services Agency; Employee Leasing Company; or Professional Employer Organization [PEO])?"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Yes"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Other Names Hiring Employer is \"doing business as\" (if applicable):"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Physical Address of Hiring Employer’s Main Office"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hiring Employer’s Mailing Address (if different than above)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hiring Employer’s Telephone Number"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "If the hiring employer is a staffing agency/business (above box checked \"Yes\"), the following is the other entity for whom this employee will perform work"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Physical Address of Main Office"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Mailing Address"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Telephone Number"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "WAGE INFORMATION"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Rate(s) of Pay"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Overtime Rate(s) of Pay"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Rate by (check box)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hour"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Shift"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Day"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Week"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Salary"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Piece rate"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Commission"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Other (provide specifics)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Does a written agreement exist providing the rate(s) of pay? (check box)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Yes"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "If yes, are all rate(s) of pay and bases thereof contained in that written agreement?"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Yes"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Allowances, if any, claimed as part of minimum wage (including meal or lodging allowances)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(If the employee has signed the acknowledgment of receipt below, it does not constitute a “voluntary written agreement” as required under the law between the employer and employee in order to credit any meals or lodging against the minimum wage. Any such voluntary written agreement must be evidenced by a separate document.)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Regular Payday"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "WORKERS’ COMPENSATION"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Insurance Carrier’s Name"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Address"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Telephone Number"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Policy No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Self-Insured (Labor Code 3700) and Certificate Number for Consent to Self-Insure"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "PAID SICK LEAVE"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Unless exempt, the employee identified on this notice is entitled to minimum requirements for paid sick leave under state law which provides that an employee:"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "May accrue paid sick leave and may request and use up to 3 days or 24 hours of accrued paid sick leave per year;",
                "May not be terminated or retaliated against for using or requesting the use of accrued paid sick leave; and",
                "Has the right to file a complaint against an employer who retaliates or discriminates against an employee for",
            ]
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "requesting or using accrued sick days;",
                "attempting to exercise the right to use accrued paid sick days;",
                "filing a complaint or alleging a violation of Article 1.5 section 245 et seq. of the California Labor Code;",
                "cooperating in an investigation or prosecution of an alleged violation of this Article or opposing any policy or practice or act that is prohibited by Article 1.5 section 245 et seq. of the California Labor Code."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "The following applies to the employee identified on this notice: (Check one box) □ 1. Accrues paid sick leave only pursuant to the minimum requirements stated in"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Accrues paid sick leave only pursuant to the minimum requirements stated in Labor Code §245 et seq. with no other employer policy providing additional or different terms for accrual and use of paid sick leave.",
                "Accrues paid sick leave pursuant to the employer’s policy which satisfies or exceeds the accrual, carryover, and use requirements of Labor Code §246.",
                "Employer provides no less than 24 hours (or 3 days) of paid sick leave at the beginning of each 12-month period.",
                "The employee is exempt from paid sick leave protection by Labor Code §245.5. (State exemption and specific subsection for exemption):"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "ACKNOWLEDGEMENT OF RECEIPT"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(PRINT NAME of Employer representative)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(PRINT NAME of Employee)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(SIGNATURE of Employer Representative)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(SIGNATURE of Employee)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Date)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Date)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "The employee’s signature on this notice merely constitutes acknowledgement of receipt."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Labor Code section 2810.5(b) requires that the employer notify you in writing of any changes to the information set forth in this Notice within seven calendar days after the time of the changes, unless one of the following applies: (a) All changes are reflected on a timely wage statement furnished in accordance with Labor Code section 226; (b) Notice of all changes is provided in another writing required by law within seven days of the changes."
        }
    ]
}


export const noticeToEmployeeSP = {
    title: "AVISO AL EMPLEADO",
    content: [

        {
            tag: "p",
            class: "text-justify",
            data: "Sección 2810.5 del Código de Trabajo"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "EMPLEADO"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre del empleado"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Fecha de inicio"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "EMPLEADOR"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre legal del empleador contratante"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "¿El empleador contratante es una agencia/compañía de empleo (por ejemplo, agencia de colocación de trabajadores temporales, agencia de empleados temporales, u organización de empleadores profesionales [PEO, por sus siglas en inglés])?"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Sí"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Otros nombres con los cuales el empleador contratante \"realiza negocios\" (si aplica):"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dirección física de la oficina principal del empleador contratante"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dirección postal del empleador contratante (si es diferente a la de arriba)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Número telefónico del empleador contratante"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Si el empleador contratante es una agencia/compañía de colocación de trabajadores (la casilla de arriba está marcada \"Sí\"), a continuación se encuentra la otra entidad para la cual el empleado trabajará"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dirección física de la oficina principal"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dirección postal"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Número telefónico"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "INFORMACIÓN SOBRE EL SALARIO"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Tasa(s) de pago"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Tasa(s) de pago de horas extra"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Tasa por (marque una casilla)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Hora"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Turno"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Día"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Semana"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Salario"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "A destajo"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Comisión"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Otra (proporcione detalles)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "¿Existe un acuerdo por escrito que proporcione la(s) tasa(s) de pago? (marque una casilla)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Sí"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Si la respuesta es Sí, ¿todas las tasas de pago y sus bases están contenidas en ese acuerdo por escrito?"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Sí"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Asignaciones, si las hubiere, reclamadas como parte del salario mínimo (incluyendo asignaciones por concepto de comidas o alojamiento)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Si el empleado ha firmado el acuse de recibo a continuación, el mismo no constituye un \"acuerdo escrito voluntario\" requerido por la ley entre el empleador y el empleado para poder considerarlo como un crédito por concepto de comidas o alojamiento contra el salario mínimo. Cualquiera de tales acuerdos escritos voluntarios deberán documentarse por separado.)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Día regular de pago"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "INDEMNIZACIÓN POR ACCIDENTES DE TRABAJO"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Nombre de la aseguradora"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Dirección"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Número telefónico"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "No. de la póliza"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Auto asegurado (Código de Trabajo 3700) y número de certificado del consentimiento para auto asegurarse"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "LICENCIA POR ENFERMEDAD CON GOCE DE SUELDO"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Salvo que esté exento, el empleado identificado en este aviso tiene derecho a los requisitos mínimos para licencias por enfermedad con goce de sueldo bajo la ley del estado que estipula que un empleado:"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Puede acumular licencia por enfermedad con goce de sueldo y puede solicitar y utilizar hasta 3 días o 24 horas por concepto de licencia por enfermedad con goce de sueldo por año;",
                "No podrá ser despedido o no se podrán tomar represalias contra él por utilizar o por solicitar el uso de licencias por enfermedad con goce de sueldo; y",
                "Tiene derecho de presentar una demanda contra el empleador que tome represalias o discrimine contra un empleado por",
            ]
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "solicitar o utilizar días por concepto de licencias por enfermedad con goce de sueldo;",
                "intentar ejercer el derecho de utilizar días acumulados por concepto de licencias por enfermedad con goce de sueldo;",
                "presentar una demanda o alegar una violación de la sección 245 y siguientes del Artículo 1.5 del Código de Trabajo de California;",
                "cooperar en una investigación o enjuiciamiento debido a una presunta violación de este Artículo u oponerse a cualquier política o práctica o actuación que sea prohibida por la sección 245 y siguientes del Artículo 1.5 del Código de Trabajo de California."
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Lo siguiente aplica al empleado identificado en este aviso. (Marque una casilla)"
        },
        {
            tag: "ol",
            class: "text-justify",
            data: [
                "Acumula licencia por enfermedad con goce de sueldo únicamente según los requisitos mínimos previstos en el Código de Trabajo §245 y siguientes con ninguna otra póliza del empleador que proporcione términos adicionales o diferentes para acumular y utilizar la licencia por enfermedad con goce de sueldo.",
                "Acumula licencia por enfermedad con goce de sueldo según la póliza del empleador que satisface o excede los requisitos de acumulación, traslado, y uso del Código de Trabajo §246.",
                "El empleador provee no menos de 24 horas (o 3 días) de licencia por enfermedad con goce de sueldo al principio de cada período de 12 meses.",
                "El empleado está exento de la protección de ser pagado por concepto de licencias por enfermedad con goce de sueldo según el Código de Trabajo §245.5. (Proporcione la exención y la sub-sección específica para la exención):"
            ]
        },
        {
            tag: "p",
            class: "text-justify",
            data: "ACUSE DE RECIBO"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Opcional)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(ESCRIBA CON LETRA DE MOLDE EL NOMBRE del representante del empleador)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(ESCRIBA CON LETRA DE MOLDE el nombre del empleado)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(FIRMA del representante del empleador)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(FIRMA DEL empleado)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Fecha)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "(Fecha)"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "La firma del empleado en este aviso únicamente constituye un acuse de recibo."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "La sección 2810.5(b) del Código de Trabajo requiere que el empleador le notifique a usted por escrito de cualquier cambio a la información establecida en este Aviso dentro de los siete días continuos después del momento de los cambios, a menos que uno de los siguientes aplique: (a) Todos los cambios son reflejados en un recibo puntual de sueldo provisto según la sección 226 del Código de Trabajo; (b) El aviso de todos los cambios se provee en otro escrito requerido por la ley dentro de siete días a partir de los cambios."
        }
    ]
}