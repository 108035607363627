export const procurementDisclosureEN = {
    title: "CALIFORNIA CANDIDATE DISCLOSURE FOR THE PROCUREMENT OF INVESTIGATIVE CONSUMER REPORTS",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase. (the “Company”) may request background information about you from an investigative consumer reporting agency in connection with your employment application and the information may be used for employment, promotion, retention, or reassignment for employment purposes. The report ordered is defined by the Investigative Consumer Reporting Agencies Act (ICRAA) as an “investigative consumer report.” These reports may contain information about your character, general reputation, personal characteristics and mode of living. They may involve personal information with sources such as your neighbors, friends or associates. The reports may also contain information about you relating to your criminal history, credit history, driving and/or motor vehicle records, education or employment history, or other background checks."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "You have the right to view your file that a Consumer Reporting Agency holds. By providing proper identification, you may obtain a copy of this information in person at the Consumer Reporting Agency’s regular business hours and after providing reasonable notice for your request. Additionally, you can make the same request via mail or request a summary of the file over the phone. The Consumer Reporting Agency can assist you in understanding your file, including coded information. You can have one additional person accompany you so long as they provide proper identification."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "AUTHORIZATION"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "I ###name### have carefully read and understand the FCRA Candidate Disclosure for the Procurement of Consumer Reports form, and if applicable, the California Candidate Disclosure for the Procurement of Investigative Consumer Reports form. I have also read and understand the attached Summary of Rights under the Fair Credit Reporting Act and State Law Disclosures. By my signature below, I authorize the Company to share the contents of this consumer report or investigative consumer report with its partners and clients in an effort to place me into an employment relationship with those partners. The Company will only share the background report as necessary, and as authorized, in order to assign me to a client, partner company, or organization. I understand that if the Company hires or engages me, my consent will apply, and the Company may obtain reports throughout my employment/where state law allows. I also understand that the information contained in my job application or otherwise disclosed by me before or during my employment if any, may be used for the purpose of obtaining consumer reports and/or investigative consumer reports."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "By my signature below, I authorize law enforcement agencies, learning institutions (including public and private schools and universities), information service bureaus, credit bureaus, record/data repositories, courts (federal, state and local), motor vehicle records agencies, my past or present employers, the military, and other individuals and sources to furnish any and all information on me that is requested by the consumer reporting agency."
        },
        {
            tag: "p",
            class: "",
            data: "Employee’s Name"
        },
        {
            tag: "p",
            class: "",
            data: "Employee’s Date of Birth"
        },
        {
            tag: "p",
            class: "",
            data: "Cell Phone or E-mail"
        },
        {
            tag: "p",
            class: "",
            data: "Employee’s Social Security Number"
        },
        {
            tag: "p",
            class: "",
            data: "Employee’s Home Address"
        },
        {
            tag: "p",
            class: "",
            data: "Employee’s Signature"
        }
    ]
}


export const procurementDisclosureSP = {
    title: "DIVULGACIÓN DE CANDIDATO DE CALIFORNIA PARA ADQUIRIR  INFORMES DE INVESTIGACIÓN  DE INFORMES DEL CONSUMIDOR",
    content: [
        {
            tag: "p",
            class: "text-justify",
            data: "Hirebase. (la 'Empresa') puede solicitar información de antecedentes sobre usted a una agencia de informes de consumidores de investigación en relación con su solicitud de empleo y la información puede ser utilizada para empleo, promoción, retención o reasignación con fines de empleo. El informe ordenado es definido por la Ley de Agencias de Información al Consumidor de Investigación (ICRAA) como un 'informe de investigación del consumidor'. Estos informes pueden contener información sobre su carácter, reputación general, características personales y modo de vida. Pueden involucrar información personal con fuentes como sus vecinos, amigos o asociados. Los informes también pueden contener información sobre usted relacionada con su historial criminal, historial de crédito, registros de conducción y/o vehículos de motor, historial educativo o laboral, u otras verificaciones de antecedentes."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Usted tiene derecho a ver su archivo que una Agencia de Informes del Consumidor tiene. Al proporcionar una identificación adecuada, puede obtener una copia de esta información en persona en el horario comercial regular de la Agencia de Informes del Consumidor y después de proporcionar un aviso razonable para su solicitud. Además, puede hacer la misma solicitud por correo o solicitar un resumen del archivo por teléfono. La Agencia de Informes del Consumidor puede ayudarle a entender su archivo, incluida la información codificada. Puede hacer que una persona adicional lo acompañe siempre y cuando proporcionen una identificación adecuada."
        },
        {
            tag: "p",
            class: "font-weight-bold",
            data: "AUTORIZACIÓN"
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Yo ###name### e leído y comprenden cuidadosamente la Divulgación de Candidatos de la FCRA para el formulario de Adquisición de Informes de Consumidores y, si corresponde, el formulario Divulgación de Candidatos de California para la Adquisición de Informes de Investigación del Consumidor. También he leído y entiendo el Resumen de Derechos adjunto bajo la Ley de Informes de Crédito Justo y Divulgaciones de la Ley estatal.  Con mi firma a continuación, autorizo a la Compañía a compartir el contenido de este informe del consumidor o informe de consumidor de investigación con sus socios y clientes en un esfuerzo por colocarme en una relación laboral con esos socios. La Compañía solo compartirá el informe de antecedentes según sea necesario, y según lo autorizado, con el fin de asignarme a un cliente, empresa asociada u organización. Entiendo que si la Compañía me contrata o contrata, se aplicará mi consentimiento, y la Compañía puede obtener informes a lo largo de mi empleo/donde la ley estatal lo permita. También entiendo que la información contenida en mi solicitud de empleo o divulgada de otra manera por mí antes o durante mi empleo, si la hubiera, puede ser utilizada con el propósito de obtener informes de los consumidores y / o informes de investigación de los consumidores."
        },
        {
            tag: "p",
            class: "text-justify",
            data: "Con mi firma a continuación, autorizo agencias de aplicación de la ley, instituciones de aprendizaje (incluyendo escuelas y universidades públicas y privadas), oficinas de servicios de información, oficinas de crédito, repositorios de registros/datos, tribunales (federales, estatales y locales), agencias de registros de vehículos automotores, mis empleadores pasados o actuales, las fuerzas armadas y otras personas y fuentes para proporcionar toda la información sobre mí que es solicitada por la agencia de informes de consumidores."
        },
        {
            tag: "p",
            class: "",
            data: "Nombre del Empleado"
        },
        {
            tag: "p",
            class: "",
            data: "Fecha de Nacimiento"
        },
        {
            tag: "p",
            class: "",
            data: "Numero de Cellula o Correo Electronico"
        },
        {
            tag: "p",
            class: "",
            data: "Numero de Seguro Social del Empleado"
        },
        {
            tag: "p",
            class: "",
            data: "Direcion de Casa"
        },
        {
            tag: "p",
            class: "",
            data: "Firma del Empleado"
        }
    ]
}