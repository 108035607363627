import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent,IonBadge, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { selectLoggedInUser } from '../../accounts/reducerSlices/accountsSlice';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisLoader from '../../../components/loaders/ellipsisLoader';
import { fetchStatus, tenantId } from '../../../app/api/client';
import { getAllQuestions, saveSafetyTestResponses, selectSafetyTestDetail, selectSafetyTestDetailStatus, selectSafetyTestResult, selectSafetyTestResultStatus, updateSafetyTestResultStatus } from '../reducerSlices/safetyTestSlice';
import { useHistory } from 'react-router';
import { isObjectArray } from '../../../app/utilities/utilityFunctions';
import Question from './question';
import { nanoid } from '@reduxjs/toolkit';
import { call, mail, location, informationCircle } from 'ionicons/icons';
import { selectApplicantById } from '../../applicant/reducerSlices/applicantSlice';
import { _getLocationText } from '../../../data/masterData';
import { Language } from '../../../app/common/constants';
import { Offline, Online, Detector } from "react-detect-offline";


function SafetyTest(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const { id, lang } = props.match.params;
    const user = useSelector(selectLoggedInUser);

    const [questionsList, setQuestionsList] = useState([]);

    const safetyTestDetail = useSelector(selectSafetyTestDetail);
    const safetyTestDetailStatus = useSelector(selectSafetyTestDetailStatus);

    const safetyTestResult = useSelector(selectSafetyTestResult);
    const safetyTestResultStatus = useSelector(selectSafetyTestResultStatus);

    const applicant = useSelector((state) => selectApplicantById(state, id));
    const polling = {
        enabled: true,
        interval: 2000,
        timeout: 1000
      };

    useEffect(() => {
        if (safetyTestDetailStatus === fetchStatus.IDLE) {
            dispatch(getAllQuestions({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSAFETYTESTDETAILSBYAPPLICANTIDFORMOBILE",
                payload: {
                    id: id,
                    data: user.id
                }
            }));
        }
    }, [safetyTestDetailStatus, dispatch]);

    let _questions = [];
    useEffect(() => {
        if (safetyTestDetail && isObjectArray(safetyTestDetail.Questions)) {
            for (let i = 0; i < safetyTestDetail.Questions.length; i++) {
                let question = safetyTestDetail.Questions[i];
                if (question && question.Text) {
                    _questions.push(<Question
                        key={nanoid()}
                        question={question}
                        response={safetyTestDetail.response}
                        forLanguage={applicant && lang === Language.SPANISH || lang === Language.SP ? 1 : 0}
                    />);
                }
            }
            setQuestionsList(_questions);
        }
    }, [safetyTestDetail]);

    let isModelValid = [true].every(Boolean);

    const onSaveClicked = async (e) => {
        if (isModelValid) {
            try {
                await dispatch(
                    saveSafetyTestResponses({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESAFETYTESTDETAILSFORMOBILE",
                        payload: {
                            id: (safetyTestDetail && safetyTestDetail.response) ? safetyTestDetail.id : "",
                            data: JSON.stringify({
                                testResponse: safetyTestDetail,
                                ApplicantId: id
                            })
                        }
                    })
                );
                
            }
            catch (err) {
                console.error('Failed to save the post: ', err)
            }
            finally {
            }
        }
    };

    useEffect(() => {
        if (safetyTestResultStatus === fetchStatus.SUCCEEDED) {
        if (safetyTestResult == "Passed") {
            alert('Congratulation! your test is cleared');
            dispatch(updateSafetyTestResultStatus());
            history.push(`/trainingHome/${id}`);
        }
        else 
        {
            alert('Sorry! you have failed in test, Please retry');
            dispatch(updateSafetyTestResultStatus());
            history.push(`/trainingHome/${id}`);
        }

    }
    },[safetyTestResultStatus,safetyTestResult]);


    const onBackClicked = (e) => {
        history.push(`/safetyTraining/${id}/${lang}`);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                   <IonTitle className="toolbar-title">{`${user ? user.firstName : "NA"} ${user ? user.lastName : "NA"}`}  <Online polling={polling}><IonBadge color="success">Online</IonBadge></Online>
                    <Offline polling={polling}><IonBadge color="danger">Offline</IonBadge></Offline></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {applicant.FirstName + " " + applicant.LastName}
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={mail} slot="start" color="warning" />
                                        <IonLabel>{applicant.Email}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={call} slot="start" color="primary" />
                                        <IonLabel>{applicant.PhoneNumber}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={location} slot="start" color="dark" />
                                        <IonLabel>{_getLocationText(applicant.LocationId)}</IonLabel>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonItem lines="none">
                                        <IonIcon icon={informationCircle} slot="start" color="secondary" />
                                        <IonLabel>{applicant.Status}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                {/* <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            <IonRow>

                            </IonRow>
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12"> */}
                {questionsList}
                {/* </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard> */}

                <IonRow className="ion-justify-content-center">
                    <IonCol size="10">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {safetyTestDetailStatus !== fetchStatus.LOADING && safetyTestDetailStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="warning"
                                    // disabled={safetyTestDetail && safetyTestDetail.response}
                                    onClick={(e) => onSaveClicked(e)}>
                                    Save
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                            <IonCol className="ion-text-center">
                                {safetyTestDetailStatus !== fetchStatus.LOADING && safetyTestDetailStatus !== fetchStatus.LOADING ? <IonButton
                                    expand="block"
                                    fill="solid"
                                    color="medium"
                                    onClick={(e) => onBackClicked(e)}>
                                    Back
                                </IonButton> : <EllipsisLoader />}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
}

export default SafetyTest;