import React from 'react';

import './css/ellipsisLoader.css';

function EllipsisLoader(props) {
    return (
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default EllipsisLoader;