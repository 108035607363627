import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/accounts/screens/login';
import UploadDetails from './pages/uploadDetails';
import ConcentAcceptance from './pages/concentAcceptance';

import PrivateRoute from './app/routes/privateRoute';
import ScanApplicant from './pages/applicant/screens/scanApplicant';

import './App.css'
import SignatureDetails from './pages/reviewer/screens/signatureDetails';
import SafetyTest from './pages/tests/screens/safetyTest';
import SafetyTraining from './pages/tests/screens/safetyTraining';
import Trainings from './pages/tests/screens/trainings';
import SexualHarassTraining from './pages/tests/screens/sexualHarassTraining';
import SexualHarassTrainingSupervisor from './pages/tests/screens/sexualHarassTrainingSupervisor';
import TrainingHome from './pages/trainingHome';
import WorkExperience from './pages/workExperience';
import VaccinationDetails from './pages/vaccinationDetails';
import VaccineAccomodationDetails from './pages/vaccineAccomodationDetails';


const App = (props) => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/" exact={true} component={Login} />
              <Route path="/login" exact={true} component={Login} />
              <PrivateRoute path="/reviewerSignature" exact={true} component={SignatureDetails} />
              <PrivateRoute path="/scanApplicant" exact={true} component={ScanApplicant} />
              <PrivateRoute path="/safetyTest/:id/:lang" exact={true} component={SafetyTest} />
              <PrivateRoute path="/safetyTraining/:id/:lang" exact={true} component={SafetyTraining} />
              <PrivateRoute path="/sexualHarassTraining/:id/:lang" exact={true} component={SexualHarassTraining} />
              <PrivateRoute path="/SexualHarassTrainingSupervisor/:id/:lang" exact={true} component={SexualHarassTrainingSupervisor} />
              <PrivateRoute path="/trainings/:id" exact={true} component={Trainings} />
              <PrivateRoute path="/uploadDetails/:id" exact={true} component={UploadDetails} />
              <PrivateRoute path="/workExperience/:id/:lang" exact={true} component={WorkExperience} />
              <PrivateRoute path="/TrainingHome/:id" exact={true} component={TrainingHome} />
              <PrivateRoute path="/concentAcceptance/:id/:lang" exact={true} component={ConcentAcceptance} />
              <PrivateRoute path="/vaccinationDetails/:id/:lang" exact={true} component={VaccinationDetails} />
              <PrivateRoute path="/vaccineAccomodationDetails/:id/:lang" exact={true} component={VaccineAccomodationDetails} />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
